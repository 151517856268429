<div id="main_wrapper" class="row no-gutter">
    <div class="container-fluid header-navbar">
        <div>
            <div class="col-sm-3 col-md-4 no-padding">
                <a href="/">
                    <img src="../assets/img/AssociatedPress_logo.png" alt="Welcome to AP Nexus" />
                </a>
          <span *ngIf="systemWideNotification" class="systemNote animated pulse">&nbsp;{{systemWideNotification}}</span>
            </div>

            <app-clock></app-clock>
            <app-login></app-login>
        </div>
    </div>
    <div id="wrapper" class="container-fluid">
        <app-content-tabset></app-content-tabset>
    </div>
</div>

