export class FoxEventStep {
    constructor(
        public name: string,
        public start: string,
        public end: string,
        public status: string,
        public message: string,
        public isLatestStep?: boolean,
        public displayResubmit?: boolean,
       ) {
    }
}
