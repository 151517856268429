import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { PopupOptions } from './popup-options';
import {HttpClient} from '@angular/common/http';
import {IntervalObservable} from "rxjs/observable/IntervalObservable";
import {Observable,  of ,  throwError as throwError } from "rxjs";
import {delay, mergeMap, retryWhen, catchError} from "rxjs/operators";
import {RestrictionItem} from "../restrictionsobj/restriction.model";
import {RestrictionFields} from "../restrictionsobj/restriction-fields";

const getErrorMessage = (msxRetry: number) => 'Tried ${maxRetries} times without success';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {
  @Output() confirmClick = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<any>();
  public options: PopupOptions;
  public fieldResults: object[];
  width: string;
  height: string;
  marginLeft: string;
  marginTop: string;
  visible: boolean;
  header: string;
  confirmBtnContent: string;
  cancelBtnContent: string;
  showButtons: boolean;
  color: string;
  confirmBtnStyle: string;
  cancelBtnStyle: string;
  wellStyle: any;
  mainStyle: any;
  mainClass: string;


  constructor(private http: HttpClient) {
    this.confirmBtnStyle = 'ng2-opd-popup-Button';
    this.cancelBtnStyle = 'ng2-opd-popup-Button';
    this.mainClass = 'container ng2-opd-popup-content-main';
    this.options = new PopupOptions();
    this.setOptions(this.options);
  }
    private delayedRetry(delayMs: number, maxRetry = 3){
      let retries = maxRetry;
      console.log('retrying')
      return (src: Observable<any>) => src.pipe(
        retryWhen((errors: Observable<any>) => errors.pipe(
          delay(delayMs),
          mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry))
          ))
        )
      )
    }

    public ipLookUp () {
      console.log('test');
/*
    const response = this.http.get<any>('http://ip-api.com/json', {headers: null}).pipe(
      this.delayedRetry(250, 3),
      catchError(error => {
        console.log(error);
        return '';
      })
    );
    console.log('User\'s Location Data is ', response);
    console.log('User\'s Country', response['country']);
    console.log('User\'s City', response['city']);
    var useCode = response['countryCode'];
    if (useCode === 'GB') {useCode = 'UK'; }
    if (useCode === 'US') {
      this.SelectElement("popup.Country Code", response['region'].toUpperCase());

    } else {
      this.SelectElement("popup.Country Code", useCode.toUpperCase());
    }*/
    this.http.get('http://ip-api.com/json', {headers: null}).pipe(
      this.delayedRetry(250, 3),
      catchError(error => {
        console.log(error);
        return '';
      })).subscribe(response => {
        console.log('User\'s Location Data is ', response);
        console.log('User\'s Country', response['country']);
        console.log('User\'s City', response['city']);
        var useCode = response['countryCode'];
        if (useCode === 'GB') {useCode = 'UK'; }
        if (useCode === 'US') {
          this.SelectElement("popup.Country Code", response['region'].toUpperCase());

        } else {
          this.SelectElement("popup.Country Code", useCode.toUpperCase());
        }
      });
  }

  SelectElement(id, valueToSelect)
  {
    console.log(id);
    console.log(valueToSelect);
      var element = (<HTMLInputElement>document.getElementById(id));
    element.value = valueToSelect;
  };

  onResize(event: any): void {
    if (event.target.innerWidth < 736) {
      this.mainStyle = { 'border-color': this.options.color,
      'border-style': 'solid',
      'border-width': '1px',
      'width': '96%',
      'margin-top': '2%',
      'margin-left': '2%' };
    } else if (this.options !== undefined) {
      this.mainStyle = { 'border-color': this.options.color,
      'border-style': 'solid',
      'border-width': '1px',
      'width': this.options.widthPercentage + '%',
      'margin-top': '15%',
      'margin-left': (100 - this.options.widthPercentage) / 2 + '%'};
    }
  }

  public show(options?: PopupOptions) {
    if (options !== undefined) {
      this.setOptions(options);
    }
    if (this.options !== undefined) {
      this.confirmBtnStyle = this.options.confirmBtnClass;
      this.cancelBtnStyle = this.options.cancelBtnClass;
    }
    this.setWidth();
    this.wellStyle = { };
    this.mainStyle = { 'border-color': this.options.color,
      'border-style': 'solid',
      'border-width': '1px',
      'width': this.width,
      'margin-top': this.marginTop,
      'margin-left': this.marginLeft,
      'animation-duration': this.options.animationDuration + 's',
      'animation-name': this.options.animation
    };

    document.getElementById('overlay').style.display = 'block';

    this.visible = true;
  }

  public hide(): void {
    document.getElementById('overlay').style.display = 'none';
    this.visible = false;
  }

  private setWidth() {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (w < 736) {
        this.marginTop = '2%';
        this.marginLeft = '2%';
        this.width = '96%';
    } else if (this.options.widthPercentage === undefined) {
        this.marginTop = '10%';
        this.width = '40%';
        this.marginLeft = '30%';
    } else {
        this.width = this.options.widthPercentage + '%';
        this.marginTop = '15%';
        this.marginLeft = (100 - this.options.widthPercentage) / 2 + '%';
    }
  }

  setOptions(options: PopupOptions): void {
     if (options.addnew !== undefined) {
      this.options.addnew = options.addnew;
    } else {
      this.options.addnew = false;
    }
     if (options.restrictionItem !== undefined) {
      this.options.restrictionItem = options.restrictionItem;
    } else {
      this.options.restrictionItem = undefined;
    }
    if (options.widthPercentage !== undefined) {
      this.options.widthPercentage = options.widthPercentage;
    } else {
      this.options.widthPercentage = 40;
    }


    if (options.cancelBtnClass !== undefined) {
      this.options.cancelBtnClass = options.cancelBtnClass;
    } else {
      this.options.cancelBtnClass = 'ng2-opd-popup-Button';
    }
    if (options.confirmBtnClass !== undefined) {
      this.options.confirmBtnClass = options.confirmBtnClass;
    } else {
      this.options.confirmBtnClass = 'ng2-opd-popup-Button';
    }
    if (options.color !== undefined) {
      this.options.color = options.color;
    } else {
      this.options.color = '#777';
    }
    if (options.header !== undefined) {
      this.options.header = options.header;
    } else {
      this.options.header = '...';
    }
    if (options.showButtons !== undefined) {
      this.options.showButtons = options.showButtons;
    }
    if (options.showForm !== undefined) {
      this.options.showForm = options.showForm;
      if(this.options.showForm == false && options.restrictionItem == undefined) {
        this.ipLookUp();

      }
    }
    if (options.confirmBtnContent !== undefined) {
      this.options.confirmBtnContent = options.confirmBtnContent;
    } else {
      this.options.confirmBtnContent = 'OK';
    }
    if (options.cancelBtnContent !== undefined) {
      this.options.cancelBtnContent = options.cancelBtnContent;
    } else {
      this.options.cancelBtnContent = 'Cancel';
    }
    if (options.animationDuration !== undefined) {
      this.options.animationDuration = options.animationDuration;
    } else {
      this.options.animationDuration = 1;
    }
    if (options.animation !== undefined) {
      this.options.animation = options.animation;
    } else {
      this.options.animation = 'fadeInDown';
    }
  }


  confirmNo(): void {
    this.cancelClick.emit();
    this.hide();
  }

  confirmYes(): void {
    this.confirmClick.emit();
  }

  validateFields(fieldName, fieldValue): boolean {
    console.log('logging:');
    console.log(fieldName);
    console.log(fieldValue);
    console.log(this.options.fields);
      for (var index = 0; index < this.options.fields.length; ++index) {
      console.log('check fields:');
      console.log(this.options.fields[index]['validate']);
      if (this.options.fields[index]['validate'] && 'popup.' + this.options.fields[index]['label'] ===  fieldName) {
        if (fieldValue === '')
          return false;
      }
    }
    return true;
  }

  confirmRestrictionFields(): void {
    var emmit = true;
    const fieldValues = [];
    const inputs = document.getElementsByTagName('input');
    //restriction fields
    if (this.options.restrictionItem != undefined) {

      const inputs = document.getElementsByTagName('input');
      // return restrictions object
      var restrictItem: RestrictionItem = new RestrictionItem();
      restrictItem.Fields = new Array<RestrictionFields>();
      for (var index = 0; index < inputs.length; ++index) {
        if (inputs[index].name === 'retriction.FieldKeyName') {
          if (inputs[index].value === '') {
            emmit = false;
            alert("Please provide a value for FieldKeyName");
          }

          restrictItem.FieldKeyName = inputs[index].value.trim();
        }

        if (inputs[index].name === 'retriction.FieldKeyValue') {
          if (inputs[index].value === '') {
            emmit = false;
            alert("Please provide a value for FieldKeyValue");
          }
          restrictItem.FieldKeyValue = inputs[index].value.trim();
        }
      }
      const textareas = document.getElementsByTagName('textarea');
    //restriction fields
      for (var index = 0; index < textareas.length; ++index) {
        if (textareas[index].name === 'retriction.InternationalRestrictions') {
          if (textareas[index].value !== '') {
            var restrictfld_int: RestrictionFields = new RestrictionFields();
            restrictfld_int.field_name = 'InternationalRestrictions';
            restrictfld_int.field_value = textareas[index].value;
            restrictItem.Fields.push(restrictfld_int);
          }
        }
        if (textareas[index].name === 'retriction.DomesticRestrictions') {
          if (textareas[index].value !== '') {
            var restrictfld_dom: RestrictionFields = new RestrictionFields();
            restrictfld_dom.field_name = 'DomesticRestrictions';
            restrictfld_dom.field_value = textareas[index].value;
            restrictItem.Fields.push(restrictfld_dom);
          }
        }
        if (textareas[index].name === 'retriction.DMA') {
          if (textareas[index].value !== '') {
            var restrictfld_dma: RestrictionFields = new RestrictionFields();
            restrictfld_dma.field_name = 'DMA';
            restrictfld_dma.field_value = textareas[index].value;
            restrictItem.Fields.push(restrictfld_dma);
          }
        }
        if (textareas[index].name === 'retriction.Source') {
          if (textareas[index].value !== '') {
            var restrictfld_source: RestrictionFields = new RestrictionFields();
            restrictfld_source.field_name = 'Source';
            restrictfld_source.field_value =textareas[index].value;
            restrictItem.Fields.push(restrictfld_source);
          }
        }

      }


      if (restrictItem.Fields.length === 0) {
        emmit = false;
        alert("Please provide a value for Fields To Assign!");
      }

      if (emmit === true) {

        this.fieldResults = JSON.parse(JSON.stringify(restrictItem));

        this.confirmClick.emit(fieldValues);
      }
    }
  }

  confirmFields(): void {
    var emmit = true;
    const fieldValues = [];
    const inputs = document.getElementsByTagName('input');

      for (var index = 0; index < inputs.length; ++index) {
        if (inputs[index].name.startsWith('popup.')) {
          if (this.validateFields(inputs[index].name, inputs[index].value) !== true) {
            emmit = false;
            alert("Please provide a value for " + inputs[index].name.replace('popup.', ''));
          }
          let json;
          if (inputs[index].type === "checkbox") {
            json = '{"' + inputs[index].name + '" : "' + inputs[index].checked + '"}';
          } else {
            json = '{"' + inputs[index].name + '" : "' + inputs[index].value + '"}';
          }
          console.log(json);
          fieldValues.push(JSON.parse(json));
        }
      }

      const selects = document.getElementsByTagName('select');
      for (var index = 0; index < selects.length; ++index) {
        if (selects[index].name.startsWith('popup.')) {
          const json = '{"' + selects[index].name + '" : "' + selects[index].value + '"}';
          console.log(json);
          fieldValues.push(JSON.parse(json));
        }
      }

    if(emmit === true) {
      console.log(fieldValues)
      this.fieldResults = fieldValues;
      console.log(this.fieldResults)
      this.confirmClick.emit(fieldValues);
    }
  }
}
