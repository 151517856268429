<div id="overlay" class="overlay" (click)="confirmNo()"></div>
<div id="ng2-opd-popup-main" *ngIf="visible" [ngClass]="mainClass" [ngStyle]="mainStyle">
    <div class="row no-gutter">
        <div class="col-sm-12">
            <div id="ng2-opd-popup-well" [ngStyle]="wellStyle" class="ap-sans-bold ng2-opd-popup-well ng2-opd-popup-well-sm">
                Edit Configuration
                <i class="ico-times" (click)="confirmNo()"></i>
            </div>
        </div>
    </div>
    <div class="row no-gutter">
        <div class="col-sm-12">
            <form>
                <div class="form-group">
                  <label for="partnerName">Partner Name</label>
                  <input readonly type="text" class="form-control" id="partnerName" maxlength="100"
                  [(ngModel)]="configuration.config.provider_name" name="partnerName">
                </div>

                <div class="form-group">
                    <label for="partnerId">Partner Id</label>
                    <input readonly type="text" class="form-control" id="partnerId" maxlength="6"
                    [(ngModel)]="configuration.provider_id" name="partnerId">
                </div>

                <div class="form-group form-check">
                    <input onclick="return false;" type="radio" class="form-check-input" id="active" 
                    name="active"
                    [checked]="configuration.config.active">
                    
                    <label class="form-check-label mg-l-10" for="active"
                    >Active</label>
                </div>   

                <div class="form-group form-check">
                    <input onclick="return false;" type="checkbox" class="form-check-input" id="slate">
                    <label class="form-check-label mg-l-10" for="slate"
                   >Slate</label>
                </div>

                <div class="form-group">
                    <label for="slateJpeg">Slate Jpeg</label>
                    <input readonly disabled type="file" class="form-control-file" id="slateJpeg" accept="image/jpeg">
                </div>

                <div class="form-group">
                    <label for="slateDisclaimer">Slate Disclaimer</label>
                    <input readonly type="text" class="form-control" id="slateDisclaimer" 
                    >
                </div>

                <div class="form-group form-check">
                    <input onclick="return false;" type="checkbox" class="form-check-input" id="teletrax" 
                    [(ngModel)]="configuration.config.teletrax" name="teletrax">
                    <label class="form-check-label mg-l-10" for="teletrax"
                    >Teletrax</label>
                </div>

                <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="origin_format" 
                    [(ngModel)]="configuration.config.origin_format" name="origin_format">
                    <label class="form-check-label mg-l-10" for="origin_format"
                    >Origin Video</label>
                </div>


                <div class="form-group">
                    <label for="maxDuration">Max Duration</label>
                    <input readonly type="number" class="form-control" id="maxDuration" >
                </div>

                <div class="form-group">
                  <label for="expiration">Expiration</label>
                  <input readonly type="number" class="form-control" id="expiration" 
                  [(ngModel)]="configuration.config.content_expiration" name="expiration">
                </div>

                <div class="form-group">
                    <label for="crnr">CR/NR</label>
                    <select disabled class="form-control" id="crnr"
                    [(ngModel)]="configuration.config.default_content_type" name="CrNr">
                      <option [ngValue]="2">CR</option>
                      <option [ngValue]="1">NR</option>
                      
                    </select>
                </div>
                
                <div class="form-group">
                    <label for="searchJpegOffset">Search Jpeg Offset</label>
                    <input min="0" max="20" type="number" class="form-control" id="searchJpegOffset" 
                    [(ngModel)]="configuration.config.thumb_offset" name="searchJpegOffset">
                </div>

                <div class="form-group">
                    <label for="storyPrefix">Story# prefix</label>
                    <input readonly type="text" class="form-control" id="storyPrefix" maxlength="5"
                    [(ngModel)]="configuration.config.prefix" name="prefix">
                </div>

                <div class="form-group">
                    <label for="StoryPadLength">Story# pad length</label>
                    <input readonly type="number" class="form-control" id="StoryPadLength" 
                    [(ngModel)]="configuration.config.pad_length" name="pad_length">
                </div>

                <div class="form-group">
                    <label for="testMode">Test Mode</label>
                    <select disabled class="form-control" id="testMode"
                    [(ngModel)]="configuration.config.test_mode" name="testMode">
                      <option [ngValue]="true">live</option>
                      <option [ngValue]="false">test</option>
                      
                    </select>
                </div>

                <div class="form-group">
                    <label for="testMode">Targets</label>
                    <ng-multiselect-dropdown
                    disabled="true"
                    [placeholder]="'Select Targets'"
                    [settings]="dropdownSettings"
                    [data]="dropdownList"
                    [(ngModel)]="selectedItems"
                    name="target"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    >
                </ng-multiselect-dropdown>
                </div>
                
                                                                               

            </form>
           
            <div>
                Last Modified : {{configuration.last_modified_by}} {{configuration.last_modified_by!=null? "on":""}} {{configuration.last_modified_dt | dateFormate}}
            </div>
         
            <div class="btnBlk">

                <button type="button" class="btn btn-secondary" (click)="confirmNo()">Close</button>
                <button type="button" class="btn btn-primary mg-l-20" (click)="saveChanges()" >Save changes</button>

            </div>
        </div>
        
    </div>
</div>
