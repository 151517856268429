import { FoxEventGroup } from './foxevent-group';
import { FoxEventFields } from './foxevent-fields';

export class FoxEvent {
    public id: string;
    public timestamp: string;
    public groups: FoxEventGroup[];
    public fields: FoxEventFields;
    public source: string;
    public rawEvent: string;
    public resubmitted: boolean;
}
