import {HttpClient} from "@angular/common/http";
import {DomSanitizer} from "@angular/platform-browser";
import {LoginService} from "../login/login.service";
import {GoogleAnalyticsEventsService} from "../app.google-analynics-events.service";
import {RestrictionItem} from "../restrictionsobj/restriction.model";
import {RestrictionFields} from "../restrictionsobj/restriction-fields";

export class PopupOptions {

constructor() {
  }
    public header = '...';
    public confirmBtnContent = 'OK';
    public confirmBtnClass = 'ng2-opd-popup-Button';
    public cancelBtnContent = 'Cancel';
    public cancelBtnClass = 'ng2-opd-popup-Button';
    public showButtons = false;
    public showForm = true;
    public color = '#5cb85c';
    public widthPercentage = 40;
    public animationDuration = 0;
    public animation = '';
    public restrictionItem: RestrictionItem = null;
    public addnew = false;
    public fields = [{label: 'Slug', value: '', type: 'text', validate: true}, {label: 'Country Code', value: '', type: 'select', validate: true}, {label: 'Department', value: '', type: 'select'}, {label: 'Source', value: '', type: 'text', validate: true}];
}

