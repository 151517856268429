import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { GoogleAnalyticsEventsService } from "../app.google-analynics-events.service";
import { FoxEventFields } from "../foxeventobj/foxevent-fields";
import { ActiveTabService } from '../service/active-tab.service';

@Component({
  selector: 'app-contributionslist',
  templateUrl: './contributionslist.component.html',
  styleUrls: ['./contributionslist.component.css']
})
export class ContributionslistComponent implements OnInit, OnDestroy {
  @ViewChild('contributionPopup') popup: PopupComponent;
  @ViewChild('uploadVideoPopup') videouploadpopup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public filterCompleted = 'all';
  public oneUp: string;
  public isAllActive = true;
  public isProcessingActive = false;
  public isCompletedActive = false;
  public isFailedActive = false;
  public allowHeartbeat = false;
  public viewAudio = true;
  public viewVideo = true;
  public allCount = 0;
  public completedCount = 0;
  public inProgressCount = 0;
  public failedCount = 0;
  public query = '';
  public completed = '';
  public processing = '';
  public failed = '';
  public all = '';
  public showDetails = [];
  private d = new Date();
  public startDate = '';
  public endDate = '';
  public expedat_url_win32 = environment.expedat_url_win32;
  public expedat_url_win64 = environment.expedat_url_win64;
  public expedat_url_mac = environment.expedat_url_mac;

  private storedSearchQuery = '';
  private _subscription: Subscription;
  private _status;
  private _status_check_delay = 1500;
  paging = 50;
  public showAPTContentOnly = true;
  public showPRContentOnly = false;
  public showARContentOnly = false;
  public showLCContentOnly = false;
  public errorMTop = "0px";

  constructor(private activeTabService: ActiveTabService, private http: HttpClient, private sanitizer: DomSanitizer, private service: LoginService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {

    let localStoragePaging = localStorage.getItem("IngestPaging");
    if (!localStoragePaging || localStoragePaging == undefined) {
      localStorage.setItem("IngestPaging", this.paging.toString());
    }
    else {
      this.paging = parseInt(localStoragePaging);
    }

    var d = new Date();
    var dStart = new Date(d);
    dStart.setDate(d.getDate() - 3);
    this.startDate = dStart.getFullYear() + '-' + ('0' + (dStart.getMonth() + 1)).slice(-2) + '-' + ('0' + dStart.getDate()).slice(-2);

    var dEnd = new Date();
    dEnd.setDate(dEnd.getDate() + 1);
    this.endDate = dEnd.getFullYear() + '-' + ('0' + (dEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + dEnd.getDate()).slice(-2);

    this.activeTabService.activeTab.subscribe(data => {
      if (data == "ingest") {
        if (this.storedItems.length == 0) {
          this.getEventItems();
        }
      }
    });

    this._subscription = IntervalObservable.create(5000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "ingest") {
        this.getEventItems();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }


  getEventItems(): void {
    //console.log("ingest refresh");
    const url = this.createSearchRequest();
    if (!url || url === '') {
      this.storedItems = [];
      return;
    }

    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }

  createSearchRequest(): string {
    let searchQuery = this.httpGetUri + 'limit=' + this.paging + '&query=';

    if (this.showAPTContentOnly || this.showLCContentOnly || this.showPRContentOnly || (!this.showAPTContentOnly && !this.showLCContentOnly && !this.showPRContentOnly && !this.showARContentOnly)) {
      if (this.showLCContentOnly) {
        searchQuery = searchQuery + '(source:ingest_lcr';
      } else {
        searchQuery = searchQuery + '(source:ingest_mam';
      }
    }

    if (!this.showLCContentOnly) {
      if (this.showAPTContentOnly && !this.showPRContentOnly) {
        searchQuery = searchQuery + " AND (fields.ingest_deliveryType:https OR fields.ingest_deliveryType:expedat)";
      }

      else if (!this.showAPTContentOnly && this.showPRContentOnly) {
        searchQuery = searchQuery + " AND NOT fields.ingest_deliveryType:*";
      }

      else if (this.showAPTContentOnly && this.showPRContentOnly) {
        searchQuery = searchQuery + " AND ((fields.ingest_deliveryType:https OR fields.ingest_deliveryType:expedat) OR (NOT fields.ingest_deliveryType:*))";
      }
    }
    if (this.showAPTContentOnly || this.showLCContentOnly || this.showPRContentOnly || (!this.showAPTContentOnly && !this.showLCContentOnly && !this.showPRContentOnly && !this.showARContentOnly)) {
      searchQuery = searchQuery + ")";
    }

    if (this.showARContentOnly && !this.showLCContentOnly) {
      if (this.showAPTContentOnly || this.showPRContentOnly) {
        searchQuery = searchQuery + " OR ";
      }
      searchQuery = searchQuery + "(fields.fox_content_class:restore_to_newsroom)";
    }


    if (this.isProcessingActive === true) {
      searchQuery = searchQuery + ' AND groups.status:processing';
    } else if (this.isCompletedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:completed';
    } else if (this.isFailedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:failed';
    }

    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery + '*';
    }

    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery;
    }

    // console.log(this.startDate);
    if (this.startDate && this.endDate) {
      searchQuery = searchQuery + '&date_start=' + this.startDate + '&date_end=' + this.endDate;
    }
    //console.log(searchQuery);
    return searchQuery;
  }

  processSearchResults(item: string): void {
    this.storedItems = [];
    this.resetCounts();
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      switch (event.groups[0].status) {
        case 'completed':
          this.completedCount += 1;
          break;
        case 'processing':
          this.inProgressCount += 1;
          break;
        case 'failed':
          this.failedCount += 1;
          break;
      }
      this.allCount += 1;
      if (event.fields.Transfer && event.fields.Transfer !== '') {
        event.fields.Transfer = (Math.round(Number(event.fields.Transfer) / 100000) / 10).toString();
      } else if (event.fields.Size && event.fields.Size !== '') {
        event.fields.Size = (Math.round(Number(event.fields.Size) / 100000) / 10).toString();
      }
      this.storedItems.push(event);
      if (event.fields) {
        const allFields = Object.entries(event.fields);
        const removeFields = [
          'id',
          'mimir_id',
          'title',
          'Slug',
          'LiveChoiceStoryNbr',
          'AutoPublish',
          'AutoPublishTranscript',
          'TranscribeEvent',
          'Language'
        ];

        const curatedFields = allFields.filter(([key, value]) => !removeFields.includes(key));
        event['fieldsJson'] = curatedFields;
      }
    });
  }

  resetCounts() {
    this.allCount = 0;
    this.completedCount = 0;
    this.inProgressCount = 0;
    this.failedCount = 0;
  }

  getGroupErrorMessage(index: number): String[][] {
    const messages = [];
    for (const stage of this.storedItems[index].groups[0].stages) {
      for (const step of stage.steps) {
        if (step.status === 'failed') {
          messages.push({ name: step.name, message: step.message });
        }
      }
    }
    return messages;
  }

  displayError(index: number) {

    this.errorMTop = (window.scrollY + 150) + "px";
    // console.log("ingest_panel_" + index);
    // console.log(this.storedItems[index].id);
    // if (this.showDetails.includes(this.storedItems[index].id)) {
    //   for (var i = 0; i < this.showDetails.length; i++) {
    //     if (this.showDetails[i] === this.storedItems[index].id) {
    //       this.showDetails.splice(i, 1);
    //       i--;
    //     }
    //   }
    // }
    // else {
    //   this.showDetails.push(this.storedItems[index].id);
    // }

    this.displayErrorUp(index);

    this.copyErrorToClipboard(index);

  }


  displayErrorUp(index: number) {
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}] \n`;
          i++;
        }
      }
    }
    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id: ${item_url}
Error:${error_message}`;

    this.displayOneUpWithMessage(copy_text, 'Error Details', 50);
  }

  onFilterChange(filter: string): void {
    switch (filter) {
      case 'all':
        this.isAllActive = true;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'processing':
        this.isAllActive = false;
        this.isProcessingActive = true;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'completed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = true;
        this.isFailedActive = false;
        break;
      case 'failed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = true;
        break;
    }
    this.filterCompleted = filter;
    this.getEventItems();
  }

  copyErrorToClipboard(index: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}]\n`;
          i++;
        }
      }
    }
    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id:${item_url}
Error:${error_message}`;
    selBox.value = copy_text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  displayOneUp(index: number) {
    this.displayOneUpWithMessage(JSON.stringify(this.storedItems[index].rawEvent, null, 2), 'Item Details', 75);
  }

  hideOneUp() {
    this.popup.hide();
    this.videouploadpopup.hide();
  }

  displayOneUpWithMessage(content, header, widthPercent) {
    this.errorMTop = (window.scrollY + 70) + "px";
    this.oneUp = content;
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = header;
    options.widthPercentage = widthPercent;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    //window.scrollTo(0, 0);
  }
  displayUpload() {
    this.errorMTop = (window.scrollY + 150) + "px";
    const optionsVideoUpload: PopupOptions = new PopupOptions();
    optionsVideoUpload.showButtons = true;
    optionsVideoUpload.showForm = false;
    optionsVideoUpload.color = '#777';
    optionsVideoUpload.header = 'Upload Video';
    optionsVideoUpload.confirmBtnContent = 'Upload'
    optionsVideoUpload.widthPercentage = 25;
    optionsVideoUpload.animation = 'fadeInDown';
    this.videouploadpopup.show(optionsVideoUpload);
    // window.scrollTo(0, 0);
  }


  handleVideoVideoReq() {
    console.log(this.videouploadpopup.fieldResults);

    var deptvalue = this.videouploadpopup.fieldResults['popup.Department'];
    for (const f of this.videouploadpopup.fieldResults) {
      if ('popup.Department' in f) {
        deptvalue = f['popup.Department'].toString().split(' ').join('~')
      }
    }
    var location = '';
    var Source = '';
    var Slug = '';
    var Sensitive = false;
    for (const f of this.videouploadpopup.fieldResults) {
      if ('popup.Country Code' in f) {
        location = f['popup.Country Code'].toString().trim().split(' ').join('~');
      }
      if ('popup.Source' in f) {
        Source = f['popup.Source'].toString().trim().split(' ').join('~');
      }
      if ('popup.Slug' in f) {
        Slug = f['popup.Slug'].toString().trim();
      }
      if ('popup.sensitive' in f) {
        console.log(f['popup.sensitive']);
        Sensitive = f['popup.sensitive'] as boolean;
      }
    }
    console.log('loc: ' + location);
    console.log('source: ' + Source);
    console.log('user: ' + this.getCurrentUser());
    console.log('slug: ' + Slug);
    console.log('sense: ' + Sensitive);
    this.hideOneUp();
    var metadata = [];
    metadata.push(location.toString());
    metadata.push(Source.toString());
    metadata.push(this.getCurrentUser());
    metadata.push(Sensitive);

    metadata.push(deptvalue.toLowerCase().trim().split(' ').join('~'));

    //md~man~bites~dog
    var slugToUse = location.concat(" ", Slug).replace('*', '').replace('<', '').replace('>', '').replace('?', '').replace('|', '').replace(':', '').replace('/', '').replace(/\//g, '').replace('"', '');
    metadata.push(slugToUse.toLowerCase().trim().split(' ').join('~'));
    console.log('useApi?' + environment.ExpedatUseApi)
    if (environment.ExpedatUseApi) {
      console.log('use api')
      //This method will validate the version of the client and make every attempt to start the upload
      this.checkVersionAndStartUpload(metadata);
    }
    else {
      const url = this.generateUploadExpedatUrl(metadata, environment.ExpedatHandler, 's', '');
      document.location.href = url;
    }

  }
  /* This method will validate the version of the client
   * and make every attempt to start the upload
   *
   * @param {array} metadata is an array of fields to pass to the Expedat handler
   */
  checkVersionAndStartUpload(metadata) {
    var header = new HttpHeaders();

    header = header.set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json');

    //confirm expedat client is running
    var url_id_check = "http://127.0.0.1:8090/id"
    this.http.get(url_id_check, { headers: header }).subscribe(data => {

      console.log(JSON.stringify(data));
      var version = data["version"];
      console.log("version: ", version)
      //confirm expedat client is running and the version we want
      if (environment.ExepdatVersion.includes(version)) {
        //continue to make request
        this.executeExpedatExecUrl(metadata)
      }
      else
        this.displayOneUpWithMessage("Wrong Expedat Version, please download the latest: " + version.toString(), 'Expedat Client', 45);
    },
      error => {
        console.log('expedat client version check error: ', error)
        //run url_exec to force it to run?
        this.startExpedatExec(metadata);
      }
    );
  }

  /* This method will make every attempt to start the Expedat client
   * Once the client is running it will call the method to validate the version and subsequently start the upload (if valid)
   * @param {array} metadata is an array of fields to pass to the Expedat handler
   */
  startExpedatExec(metadata) {
    let url = 'expedat://' + environment.ExpedatServer;
    if (environment.ExpedatPort && environment.ExpedatPort !== '') {
      url = url + ':' + environment.ExpedatPort;
    }
    document.location.href = url.toString();

    this._status = setTimeout(() => {

      this.checkVersionAndStartUpload(metadata);

    }, this._status_check_delay);
  }

  /* This method will submit the upload and check the status
   *
   * @param {array} metadata is an array of fields to pass to the Expedat handler
   */
  executeExpedatExecUrl(metadata) {
    var controller_id = localStorage.getItem('controller_id');
    var header = new HttpHeaders();
    header = header.set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json')
      .set('X-ExpeDat-Controller-Name', 'The Associated Press Video Upload Tool.');
    if (controller_id !== null) {
      console.log("controller_id not null: ", controller_id)
      header = header.set('X-ExpeDat-Controller-ID', controller_id)
    }
    else {
      console.log('could not find controller_id')
    }

    //once running submit:
    const url = this.generateUploadExpedatUrl(metadata, environment.ExpedatHandler, 's', '');
    // console.log('META:' + url.toString());
    this.http.get("http://localhost:8090/url_exec?" + url.toString(), { headers: header }).subscribe(data => {
      // console.log(JSON.stringify(data));

      //after submit get sessionId and check for status
      if ("session_id" in data) {
        //get controller Id
        var controller_id = data["controller_id"]
        console.log("controller_id ", controller_id)
        localStorage.setItem('controller_id', controller_id)
        //get state
        this.checkExpedatSessionStatus(data["session_id"], controller_id);
      }
      else {
        console.log("Unexpected result: ", JSON.stringify(data))
      }
    },
      error => {
        console.log('exec upload error: ', error)
      }
    );
  }

  getEndTime(index: number): number {
    let endDate = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {

      endDate = new Date(stage.end).getTime();
      break;

    }
    return endDate;
  }

  getStartTime(index: number): number {
    let startDate = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {

      startDate = new Date(stage.start).getTime();
      break;

    }
    return startDate;
  }
  /* This method will check the status of a specific upload
   *
   * @param {string} sessionId is the sessionId returned by the Expedat client on the upload call
   */
  checkExpedatSessionStatus(sessionId, controller_id) {
    var status = 0;
    var maxcheck = 5000;
    var header = new HttpHeaders();
    header = header.set('Access-Control-Allow-Origin', '*').set('Content-Type', 'application/json')
      .set('X-ExpeDat-Controller-ID', controller_id);
    console.log(JSON.stringify(header))
    var url_status = "http://localhost:8090/url_status?"

    console.log("checking for state...")
    this.http.get(url_status + sessionId.toString(), { headers: header }).subscribe(data => {
      console.log(JSON.stringify(data));
      //check for failure
      var state = data["state_int"]
      status = state
      switch (state) {
        case 0:
          console.log("state [0] = ", data["state_string"]);
          break;
        case 1:
          console.log("state [1] = ", data["state_string"]);
          break;
        case 2:
          // console.log("state [2] = ", data["state_string"]);
          // console.log("Queued ", sessionId);
          this._status = setTimeout(() => {
            this.checkExpedatSessionStatus(sessionId, controller_id);
          }, this._status_check_delay);
          break;
        case 3:
          console.log("Transmission is in process", sessionId);
          var targetFile = data["results"][0]["target"];
          console.log("transfering file: ", targetFile);
          this._status = setTimeout(() => {
            this.checkExpedatSessionStatus(sessionId, controller_id);
          }, this._status_check_delay);
          break;
        case 4:
          // console.log("prompting user", sessionId);

          //user needs to select a file
          this._status = setTimeout(() => {
            this.checkExpedatSessionStatus(sessionId, controller_id);
          }, this._status_check_delay)

          break;
        case 5:
          console.log("Transmission is completed, check for success.");
          //check the results array for the result
          if (data.hasOwnProperty("results")) {
            var error_code = data["results"][0]["error_code"];
            var targetFile = data["results"][0]["target"];
            console.log("transfering file: ", targetFile);
            if (error_code !== 0) {
              if (targetFile) {
                console.log("Transmission failed for {0}, please check logs".replace("{0}", targetFile))
                console.log("result: {0}".replace("{0}", JSON.stringify(data)))
                this.displayOneUpWithMessage("Transmission failed for {0}. \r\n[{1}]\r\n[error code: {2}]".replace("{0}", targetFile).replace("{1}", data["results"][0]["error_string"]).replace("{2}", error_code), 'Expedat Client', 45);
              }
              else {
                this.displayOneUpWithMessage("Transmission Canceled. \r\n[{0}]".replace("{0}", data["results"][0]["error_string"]), 'Expedat Client', 45);
              }
            }
            else {
              var expedat_url = decodeURIComponent(data["url"]);
              console.log(expedat_url);
              var parser = document.createElement('a');
              parser.href = expedat_url;
              console.log(parser.pathname);
              var filename = parser.pathname.split("|")[5];
              filename = (filename.replace("/", "")).split("~").join(" ")
              console.log(filename);
              console.log("{0} was successfully uploaded as {1}".replace("{0}", targetFile).replace("{1}", filename))
              this.displayOneUpWithMessage("Transmission was successful!\r\n'{0}' was successfully uploaded as '{1}'".replace("{0}", targetFile).replace("{1}", filename), 'Expedat Client', 45);
            }
          }
          else {
            if (data["error_code"] !== 0) {
              console.log("Transmission failed for {0}, please check logs".replace("{0}", targetFile))
              console.log("reulst: {0}".replace("{0}", JSON.stringify(data)))
              this.displayOneUpWithMessage("Transmission failed for {0}. \r\n[{1}]\r\n[error code: {2}]".replace("{0}", targetFile).replace("{1}", data["error_string"]).replace("{2}", data["error_code"]), 'Expedat Client', 45);
            }
            else {
              console.log("Upload did not take place. Canceled by user.")
              this.displayOneUpWithMessage("Upload did not take place. Canceled by user.", 'Expedat Client', 45);
            }
          }
          break;
      }
    },
      error => {
        console.log('status check error: ', error)
      }
    );

  }

  submit(search: string) {
    this.storedSearchQuery = search;
    this.getEventItems();
  }



  handleVideoDownload(index: number) {
    this.logAnalynics(index);
    var url = this.sanitizeUrlByIndex(index, 'g');
    document.location.href = url;
  }

  handleVideoDownloadNew(index: number) {
    this.logAnalynics(index);
    var url = this.sanitizeUrlByIndexNew(index, 'g');
    document.location.href = url.toString();
  }
  sanitizeUrlByIndex(index: number, action: string) {
    if (!this.storedItems[index].fields.Path || this.storedItems[index].fields.Path === '') {
      console.log('no path');
      return '';
    }

    const item = this.storedItems[index];

    return this.sanitizeUrlByPath(item.fields.Path, item.fields.User, action, item.fields.environment);
  }

  sanitizeUrlByIndexSafe(index: number, action: string): SafeUrl {
    if (!this.storedItems[index].fields.Path || this.storedItems[index].fields.Path === '') {
      console.log('no path');
      return '';
    }

    const item = this.storedItems[index];

    return this.sanitizeUrlByPath(item.fields.Path, item.fields.User, action, item.fields.environment);
  }

  sanitizeUrlByIndexNew(index: number, action: string) {
    if ((!this.storedItems[index].fields.Path || this.storedItems[index].fields.Path === '') && (!this.storedItems[index].fields.FileName || this.storedItems[index].fields.FileName === '')) {
      return '';
    }

    const item = this.storedItems[index];
    var fileExt = item.fields.FileName ? item.fields.FileName.split('.').pop() : '';
    // To support legacy uploads we will need to continue to retreive the videos from the "user" folder ("france", "israel", etc)
    var path = item.fields.IsContributionUpload ? environment.ExpedatHomeFolder + '/' + encodeURIComponent(item.fields.VideoSlug) + '.' + fileExt : '/' + encodeURIComponent(item.fields.Path);
    return this.sanitizeUrlByPathNew(path, action, item.fields.environment, item.fields.User);
  }


  generateUploadExpedatUrl(metadata: string[], handler: string, action: string, server_environment: string) {
    let url = 'expedat://' + environment.ExpedatServer;
    if (environment.ExpedatPort && environment.ExpedatPort !== '') {
      url = url + ':' + environment.ExpedatPort;
    }

    if (metadata && metadata.length !== 0) {
      url = url + '/';
      var i = 0;
      var url_meta = ''
      for (const m in metadata) {
        if (i === 0) {
          url_meta = url_meta + encodeURIComponent(metadata[m]);
        } else {
          url_meta = url_meta + '|' + encodeURIComponent(metadata[m]);
        }
        ++i;
      }

      url = url + encodeURIComponent(url_meta) + '/';

    }
    // if both blank, then it will prompt user for username and password
    url = url + '?u=' + encodeURIComponent(environment.ExpedatUser) + '&p=' + encodeURIComponent(environment.ExpedatPass);
    if (handler && handler !== '') {
      url = url + '&h=' + handler;
    }

    if (action && action !== '') {
      url = url + '&a=' + action;
    }
    // limit UI
    url = url + '&l=1';

    return url;
  }

  sanitizeUrlByPathNew(path: string, action: string, server_environment: string, user: string) {
    const expedat = (server_environment in environment.expedat ? environment.expedat[server_environment] : environment.expedat['default']);

    let url = 'expedat://' + expedat.server;
    if (expedat.port && expedat.port !== '') {
      url = url + ':' + expedat.port;
    }
    console.log(path.includes('/'));
    console.log(path);

    if (path && path !== '') {
      url = url + path;
    }

    // if both blank, then it will prompt user for username and password
    if (expedat.user) {
      url = url + '?a=' + action + '&u=' + encodeURIComponent(expedat.user) + '&p=' + encodeURIComponent(expedat.pass);
    }
    else {
      if (user) {
        url = url + '?a=' + action + '&u=' + user;
      }
      else {
        url = url + '?a=' + action + '&u=';
      }
    }

    if (expedat.handler && expedat.handler !== '') {
      url = url + '&h=' + expedat.handler;
    }
    console.log(url);
    // if (environment.ExpedateturnUrl && environment.ExpedateturnUrl !== '') {
    url = url + '&l=1';
    // }
    return url;
  }

  sanitizeUrlByPath(path: string, user: string, action: string, server_environment: string) {
    const expedat = (server_environment in environment.expedat ? environment.expedat[server_environment] : environment.expedat['default']);

    let url = 'expedat://' + expedat.server;
    if (expedat.port && expedat.port !== '') {
      url = url + ':' + expedat.port;
    }
    if (path && path !== '') {
      url = url + '/' + encodeURIComponent(path);
    }
    // if both blank, then it will prompt user for username and password
    url = url + '?a=' + action + '&u=' + user + '&p=';
    if (expedat.handler && expedat.handler !== '') {
      url = url + '&h=' + expedat.handler;
    }

    return url;
  }
  sanitizeUrlByPathSafe(path: string, user: string, action: string, server_environment: string): SafeUrl {
    const expedat = (server_environment in environment.expedat ? environment.expedat[server_environment] : environment.expedat['default']);

    let url = 'expedat://' + expedat.server;
    if (expedat.port && expedat.port !== '') {
      url = url + ':' + expedat.port;
    }
    if (path && path !== '') {
      url = url + '/' + encodeURIComponent(path);
    }
    // if both blank, then it will prompt user for username and password
    url = url + '?a=' + action + '&u=' + user + '&p=';
    if (expedat.handler && expedat.handler !== '') {
      url = url + '&h=' + expedat.handler;
    }

    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  logAnalynics(index: number) {
    try {
      const item = this.storedItems[index];
      this.googleAnalyticsEventsService.emitEvent("contribution", "download-user-file", (environment.envName.toLowerCase() === 'dev' ? 'dev_' : '') + this.getCurrentUser() + " | " + item.fields.User + " | " + item.fields.Path, 1);
      console.log(item.fields.Path);
    } catch (errGA) {
      console.log("error processing Google Analynics: " + errGA.toString());
    }

  }

  getCurrentUser(): string {
    var localuser = '';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if (currentUser.username) {
        const begin = currentUser.username.toString().indexOf('_');
        const end = currentUser.username.toString().indexOf('@');
        localuser = currentUser.username.toString().slice(begin + 1, end);
      } else
        console.log('user not found');
    }
    return localuser
  }

  getTransferTime(index: number): string {
    let result = '';
    if (this.storedItems[index].groups[0].stages[0].steps[0].end && this.storedItems[index].groups[0].stages[0].steps[0].start) {
      const endDate = new Date(this.storedItems[index].groups[0].stages[0].steps[0].end).getTime();
      const difference = endDate - new Date(this.storedItems[index].groups[0].stages[0].steps[0].start).getTime();
      const diffMinutes = difference / (60000);
      const absoluteMinutes = Math.floor(diffMinutes);
      if (absoluteMinutes > 0) {
        const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
        result = mins + ' mins ';
      }
      const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
      const absoluteSeconds = Math.floor(diffSeconds);
      if (absoluteSeconds > 0) {
        const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
        result = result + secs + ' secs';
      }
      if (result === '') {
        result = '0 mins 0 secs';
      }
    }

    return result;
  }

  getEcrUrl(item_id): String {
    return environment.EcrUrl + item_id;
  }


  getTime(time: string): number {
    return new Date(time).getTime();
  }

  getTimeToMarket(startTime: string, endTime: string): string {
    let result = '';
    const startDate = this.getTime(startTime)
    const endDate = this.getTime(endTime)
    if (endDate === 0 || startDate === 0) {
      return '';
    }
    const difference: number = endDate - startDate;
    const diffHours = difference / 3600000;
    const absoluteHours = Math.floor(diffHours);
    if (absoluteHours > 0) {
      const hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
      result = hours + ' hrs ';
    }
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    if (absoluteMinutes > 0) {
      const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
      result = result + mins + ' mins';
    }
    if (absoluteHours < 1) {
      const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
      const absoluteSeconds = Math.floor(diffSeconds);
      if (absoluteSeconds > 0) {
        const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
        result = result + ' ' + secs + ' secs';
      }
    }
    if (result === '') {
      result = '0 mins 0 secs';
    }
    return result;
  }

  onDateChangeStart(event) {

  }

  onDateChangeEnd(event) {

  }

  onPagingSelectChange() {
    localStorage.setItem("IngestPaging", this.paging.toString());
    this.getEventItems();
  }

  getMb(size) {
    return (size / (1000000)).toFixed(2);
  }

  getOriginalFileLink(mimirId) {
    return environment.ingestOriginalFileName + mimirId;
  }

  handleIngestVideoDownload(fileName, mimirId) {
    var url = environment.downloadIngestFile.split("mimirId_fileName");
    var mimirIdFileName = encodeURIComponent(mimirId + "_" + fileName);
    var newUrl = url[0] + mimirIdFileName + url[1];
    document.location.href = newUrl.toString();
  }

  getDuration(millis) {
    if (millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + " min " + (parseInt(seconds) < 10 ? '0' : '') + seconds + " second";
    }
  }

  APTOnlyChanged() {
    this.showAPTContentOnly = !this.showAPTContentOnly;
    if (this.showAPTContentOnly) {
      this.showAPTContentOnly = true;
      this.showLCContentOnly = false;
      this.showARContentOnly = false;
      this.showPRContentOnly = false;
    }
    this.getEventItems();
  }

  PROnlyChanged() {
    this.showPRContentOnly = !this.showPRContentOnly;
    if (this.showPRContentOnly) {
      this.showAPTContentOnly = false;
      this.showLCContentOnly = false;
      this.showARContentOnly = false;
      this.showPRContentOnly = true;
    }
    this.getEventItems();
  }

  AROnlyChanged() {
    this.showARContentOnly = !this.showARContentOnly;
    if (this.showARContentOnly) {
      this.showAPTContentOnly = false;
      this.showLCContentOnly = false;
      this.showARContentOnly = true;
      this.showPRContentOnly = false;
    }
    this.getEventItems();
  }

  LCOnlyChanged() {
    this.showLCContentOnly = !this.showLCContentOnly;
    if (this.showLCContentOnly) {
      this.showAPTContentOnly = false;
      this.showLCContentOnly = true;
      this.showARContentOnly = false;
      this.showPRContentOnly = false;
    }
    this.getEventItems();
  }

}
