<!--<script src="../../../../../../../../Users/rregan/Downloads/expedat_client-win64-1.18B-P/ExpeDat/Web/EXP.js"></script>-->
<app-popup #contributionPopup (cancelClick)="hideOneUp()" [ngStyle]="{'margin-top': errorMTop}">
  <pre class="preStyle">{{ oneUp }}</pre>
</app-popup>
<app-popup #uploadVideoPopup (cancelClick)="hideOneUp()" (confirmClick)="handleVideoVideoReq()"
  [ngStyle]="{'margin-top': errorMTop}">
  <pre></pre>
</app-popup>
<div id="divDashboard">
  <div class="row no-gutter">
    <div class="col col-12">
      <form class="filter-form">
        <div>
          <div class="media-block">
            <a class="media-filter ico-checkbox-filter ico-apt" [class.active]="showAPTContentOnly" href="#"
              title="Display AP Transfer Items">
              <input type="checkbox" name="aptOnlyFilter" (change)="APTOnlyChanged()" />
            </a>
            <a class="media-filter ico-checkbox-filter ico-ar" [class.active]="showARContentOnly" href="#"
              title="Display Archive Restores.">
              <input type="checkbox" name="arOnlyFilter" (change)="AROnlyChanged()" />
            </a>
            <a class="media-filter ico-checkbox-filter ico-lc" [class.active]="showLCContentOnly" href="#"
              title="Display Live Choice Restores.">
              <input type="checkbox" name="lcOnlyFilter" (change)="LCOnlyChanged()" />
            </a>
            <!--          <a class="media-filter ico-pr" [class.active]="showPRContentOnly" href="#" title="Display do not have delivery type."><input type="checkbox" name="prOnlyFilter" (change)="PROnlyChanged()" /></a>  -->
          </div>

          <div class="media-block">
            <input type="text" class="search-input" #contributionsearchquery [value]="query"
              (keydown.enter)="submit(contributionsearchquery.value)" placeholder="Start searching..."
              style="margin-left: -1.3rem"><button (click)="submit(contributionsearchquery.value)"
              class="search-button"><i class="ico-search"></i></button>
          </div>
        </div>
        <div>
          <!-- <div class="media-block" *ngIf="allowUploads()">
                      <button (click)="displayUpload()" class="upload-button">Upload Video</button>

              </div> -->
          <div class="media-block">
            <div class="d-datefilter-inline">
              <div class="form-group mb-0">
                <label class="control-label pr-5px">Start:</label>
                <input class="form-control" type="date" id="date_start" name="date_start"
                  (input)="startDate = $event.target.value" [value]="startDate"
                  (change)="onDateChangeStart($event.target.value)">

              </div>
              <div class="form-group mb-0 ml-10px">
                <label class="control-label pr-5px">End:</label>
                <input class="form-control" type="date" id="date_end" name="date_end"
                  (input)="endDate = $event.target.value" [value]="endDate"
                  (change)="onDateChangeEnd($event.target.value)">
              </div>

              <div class="form-group mb-0 ml-10px">
                <select name="paging" class="form-control" [(ngModel)]="paging" (change)="onPagingSelectChange()">
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <div class="media-block">
          <div class="file-operations">
            <!--   <a class="" [href]="uploadUrl" target="_new" style="margin-right:1.6rem">Upload File</a> -->
            <div class="dropdown">
              <a href="javascript:void(0)" class="dropbtn">Download Expedat Client <i class="ico-chevron-down"></i></a>
              <div class="dropdown-content">
                <!--                          <a id="btnDownloadWin32" href="{{expedat_url_win32}}">Windows 32</a>-->
                <a id="btnDownloadWin64" href="{{expedat_url_win64}}">Windows 64</a>
                <a id="btnDownloadMac" href="{{expedat_url_mac}}">Mac</a>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-block">
          <label class="radio-inline link" [class.active]="isAllActive">
            <input type="radio" name="filter1" [value]="all" (change)="onFilterChange('all')" checked="checked">All
            Results<span *ngIf="allCount > 0"> ({{ allCount }})</span>
          </label>
          <label class="radio-inline link completed" [class.active]="isCompletedActive">
            <input type="radio" name="filter1" [value]="completed" (change)="onFilterChange('completed')">Completed<span
              *ngIf="completedCount > 0"> ({{ completedCount }})</span>
          </label>
          <label class="radio-inline link processing" [class.active]="isProcessingActive">
            <input type="radio" name="filter1" [value]="processing"
              (change)="onFilterChange('processing')">Processing<span *ngIf="inProgressCount > 0"> ({{ inProgressCount
              }})</span>
          </label>
          <label class="radio-inline link failed" [class.active]="isFailedActive">
            <input type="radio" name="filter1" [value]="failed" (change)="onFilterChange('failed')">Failed<span
              *ngIf="failedCount > 0"> ({{ failedCount }})</span>
          </label>
        </div>
      </form>
    </div>

    <table class="table table-striped" *ngIf="!showLCContentOnly">
      <thead>
        <tr>
          <th style="width: 5%"><span>&nbsp;</span></th>
          <th style="width: 10%; text-align: center"><span>Original Filename</span></th>
          <th style="width: 10%; text-align: center"><span>Slug</span></th>
          <th style="width: 19%; text-align: center">
            <p><span>Media Info</span></p>
          </th>
          <th style="width: 5%;  text-align: center"><span>User</span></th>
          <th style="width: 7%; text-align: center"><span>Upload</span></th>
          <th style="width: 7%; text-align: center"><span>Transcode</span></th>
          <th style="width: 7%; text-align: center;"><span>Metadata</span></th>
          <th style="width: 15%;"><span>Details</span></th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}">
          <!-- status -->
          <td>
            <div class="media-block">
              <!--                        <span class="indicator {{item.groups[0].status}}" title="{{getGroupErrorMessage(i)}}"-->
              <!--                              (click)="copyErrorToClipboard(i)"></span>-->
              <i class="ico-file" (click)="displayOneUp(i)"></i>
              <a *ngIf="item.fields.FileName && item.fields.mimir_id" [href]="" target="_new"
                class="ico-download-ingest"
                (click)="handleIngestVideoDownload(item.fields.FileName,item.fields.mimir_id)"></a>

            </div>
          </td>
          <!-- download and filename -->
          <td>
            <div>
              <!-- <a  [href]=""
                    target="_new" class="ico-download" (click)="handleVideoDownloadNew(i)"></a> -->
              <!-- <a *ngIf="item.fields.FileName && item.fields.mimir_id" [href]=""
                         target="_new" class="ico-download" (click)="handleIngestVideoDownload(item.fields.FileName,item.fields.mimir_id)"></a> -->


              <img src="../../assets/img/Archive-Restore-Icon.png" class="ico-restore-to-newsroom" height="20px"
                alt="Restore to Newsroom"
                *ngIf="item.fields.Source!='Archive-Restore' && item.fields.fox_content_class === 'restore_to_newsroom' " />
              <span
                *ngIf="item.fields.Source!='Archive-Restore' && (item.fields.fox_content_class === 'restore_to_newsroom' || item.fields.ingest_deliveryType === 'expedat')">&nbsp;</span>

              <span *ngIf="item.fields.Source!='Archive-Restore' && item.fields.FileName"
                style="justify-content: left;">
                <a *ngIf="item.fields.mimir_id" [href]="getOriginalFileLink(item.fields.mimir_id)"
                  target="_new">{{item.fields.FileName}}</a>
                <span *ngIf="!item.fields.mimir_id">{{item.fields.FileName}}</span>
              </span>
              <span *ngIf=" item.fields.Source!='Archive-Restore' && !item.fields.FileName">
                {{'--- No File Name ---'}}
              </span>

              <span *ngIf="item.fields.Source=='Archive-Restore' && item.fields.FileName"
                style="justify-content: left;">
                <a *ngIf="item.fields.content_provider_storyNumber"
                  [href]="getOriginalFileLink(item.fields.content_provider_storyNumber)"
                  target="_new">{{item.fields.FileName}}</a>
                <span *ngIf="!item.fields.content_provider_storyNumber">{{item.fields.FileName}}</span>
              </span>
              <span *ngIf="item.fields.Source=='Archive-Restore' && !item.fields.FileName">
                {{'--- No File Name ---'}}
              </span>
            </div>
          </td>

          <td>
            <div class="result-info" style="text-align: left;">
              <p *ngIf="item.fields.Source!='Archive-Restore'">{{item.fields.title || item.fields.VideoSlug || item.fields.mimir_id}}</p>
              <p *ngIf="item.fields.Source=='Archive-Restore'">Archive-Restore <b>to</b> {{item.fields.restoreTarget ||
                ''}}</p>
            </div>
          </td>
          <td>
            <!-- updated media info display -->
            <div class="result-info" style="text-align: left;">
              <div *ngIf="item.fields.Source!='Archive-Restore'" class="table-responsive">
                <table>
                  <tr>
                    <td style="width:170px"><b>file size:</b></td>
                    <td>{{item.fields.mediainfo_videoFileSize? getMb(item.fields.mediainfo_videoFileSize)+" MB":'-- File
                      Not Ready--'}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>duration:</b></td>
                    <td>{{getDuration(item.fields.mediainfo_videoDuration)}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>width x height:</b></td>
                    <td>{{item.fields.mediainfo_videoWidth? item.fields.mediainfo_videoWidth + " x " +
                      item.fields.mediainfo_videoHeight: ''}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>format:</b></td>
                    <td>{{item.fields.mediainfo_videoCommercialName || ''}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>bitrate:</b></td>
                    <td>{{item.fields.mediainfo_videoBitRate? getMb(item.fields.mediainfo_videoBitRate) + " Mb/s": ''}}
                    </td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>framerate:</b></td>
                    <td>{{item.fields.mediainfo_videoFramRate || ''}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b>scan type:</b></td>
                    <td>{{item.fields.mediainfo_videoScanType || ''}}</td>
                  </tr>
                  <tr>
                    <td style="width:170px;"><b># of audio tracks:</b></td>
                    <td>{{item.fields.mediainfo_audioTracksCount || ''}}</td>
                  </tr>

                </table>
              </div>
              <div *ngIf="item.fields.Source=='Archive-Restore' " style="text-align: center;">
                <span *ngIf="item.fields.contentType!=''">{{item.fields.contentType}}</span>
                <span *ngIf="item.fields.contentType==''">-- File Not Ready--</span>
              </div>
            </div>

          </td>
          <!-- Source -->
          <!-- <td align="center">
                      <div class="result-info">
                          <p *ngIf="item.fields.Source">{{item.fields.Source || item.fields.environment}}</p>
                          <p *ngIf="item.fields.Source === undefined">{{ item.fields.environment}}</p>
                          <p *ngIf="item.fields.Location">{{item.fields.Location}}</p>
                      </div>
                  </td> -->
          <!-- User -->
          <td>
            <div class="result-info" style="text-align: left;">
              <p>{{item.fields.UploadUser || item.fields.User}}</p>
            </div>
          </td>

          <!-- Upload -->
          <td>
            <div *ngIf="item.fields.Source!='Archive-Restore' " class="flex-parent">
              <div *ngFor="let stp of item.groups[0].stages[0].steps; let s = index">
                <div *ngIf="stp.name === 'upload'">
                  <div *ngIf="stp.status.toLowerCase() == 'completed'">
                    <span class="indicator {{stp.status}}" title="{{stp.name}}"> {{ getTimeToMarket(stp.start, stp.end)
                      }}</span>
                    <!--                              <p><app-timer [time]="getTime(stp.start)" ></app-timer> ago</p>-->
                  </div>
                  <div *ngIf="stp.status.toLowerCase() !== 'completed'">
                    <span class="indicator {{stp.status}}" title="{{stp.name}}"> {{stp.start}}</span>
                  </div>
                  <p><i class="sub-info-cell-d">{{stp.start | dateFormate}}</i></p>
                </div>
              </div>

            </div>
            <div *ngIf="item.fields.Source!='Archive-Restore' " class="">
              <img src="../../assets/img/expedat-icon.png" height="20px" alt="Aspera"
                *ngIf="item.fields.ingest_deliveryType === 'expedat'" />
            </div>

            <div *ngIf="item.fields.Source=='Archive-Restore' " class="flex-parent">

              <p><i class="sub-info-cell-d">{{item.groups[0].stages[0].end | dateFormate}}</i></p>


            </div>
          </td>

          <!-- Transcode -->
          <td>
            <div *ngIf="item.fields.Source!='Archive-Restore' " class="flex-parent">

              <div *ngFor="let stp of item.groups[0].stages[0].steps; let s = index">

                <div *ngIf="stp.name === 'transcode'">
                  <div *ngIf="stp.status.toLowerCase() == 'completed'">
                    <span class="indicator {{stp.status}}" title="{{stp.name}}"> {{ getTimeToMarket(stp.start, stp.end)
                      }}</span>

                  </div>
                  <div *ngIf="stp.status.toLowerCase() !== 'completed'">
                    <span class="indicator {{stp.status}}" title="{{stp.name}}"></span>
                  </div>
                  <!-- <div *ngIf="stp.status.toLowerCase() == 'completed'" >
                              <span  class="indicator {{stp.status}}" title="{{stp.name}}" > {{ getTimeToMarket(stp.start, stp.end) }}</span>



                            </div> -->
                  <div *ngIf="stp.status.toLowerCase() !== 'completed'">
                    <span *ngIf="stp.status.toLowerCase() !== 'failed'" class="indicator {{stp.status}}"
                      title="{{stp.name}}"> {{stp.message}} </span>
                    <span *ngIf="stp.status.toLowerCase() == 'failed'" class="indicator {{stp.status}}"
                      title="{{stp.name}}"> {{stp.status}} </span>
                  </div>
                  <p><i class="sub-info-cell-d">{{stp.start | dateFormate}}</i></p>
                </div>

              </div>
            </div>
          </td>

          <!-- File Transfer -->
          <td align="center">
            <div *ngIf="item.fields.Source!='Archive-Restore' " class="flex-parent">
              <div *ngFor="let stp of item.groups[0].stages[0].steps; let s = index">
                <div *ngIf="stp.name === 'metadata_ingest'">
                  <div *ngIf="stp.status.toLowerCase() === 'completed'">
                    <span class="indicator {{stp.status}}" title="{{stp.name}}"> {{ getTimeToMarket(stp.start, stp.end)
                      }}</span>
                  </div>
                  <div *ngIf="stp.status.toLowerCase() !== 'completed'">
                    <span *ngIf="stp.status.toLowerCase() !== 'failed'" class="indicator {{stp.status}}"
                      title="{{stp.name}}"> {{stp.message}} </span>
                    <span *ngIf="stp.status.toLowerCase() == 'failed'" class="indicator {{stp.status}}"
                      title="{{stp.name}}"> {{stp.status}} </span>
                  </div>
                  <p><i class="sub-info-cell-d">{{stp.start | dateFormate}}</i></p>
                </div>
              </div>
            </div>
          </td>
          <!-- Message -->
          <td>
            <div>
              <div *ngIf="item.groups[0].status !== 'failed'">
                <div *ngFor="let stg of item.groups[0].stages">
                  <span *ngIf="stg.start && stg.end"><b>Total Time: {{ getTimeToMarket(stg.start, stg.end) }}
                    </b><br /></span>
                  <span>Start: </span>{{stg.start | dateFormate}}
                </div>
              </div>
              <div *ngIf="item.fields.fox_content_class === 'restore_to_newsroom'">
                <span>Story Number: </span>{{item.fields.content_provider_storyNumber}}
                <br /><span>ItemId: </span><a href="{{ getEcrUrl(item.fields.content_provider_archive_itemId) }}"
                  target="_new">{{item.fields.content_provider_archive_itemId}}</a>
              </div>
              <div *ngIf="item.fields.mimir_region !== undefined && item.fields.mimir_region != undefined">
                <span>Ingest Region: </span>{{item.fields.mimir_region}}
              </div>
              <div *ngIf="item.groups[0].status === 'failed'">
                <div *ngFor="let step of getGroupErrorMessage(i)">
                  <span><b>Step:</b> </span>{{step['name']}}
                  <!--                              <span><b>Details:</b> </span>{{step['message']}}-->
                  <!--                              <span *ngIf="step.message"><br/><b>Details:</b> </span>-->
                  <button *ngIf="step['message'] && step['message'].length > 65" class="ingest_accordion"
                    style="color:red;cursor: pointer" (click)="displayError(i)">{{ (step['message'].length>65)?
                    (step['message'] | slice:0:65)+'..':(step['message']) }}</button>
                  <div *ngIf="step['message'] && (showDetails.includes(item.id) || step['message'].length<65)"
                    class="ingest_panel" id="ingest_panel_{{i}}">
                    <p>{{step["message"]}}</p>
                  </div>
                </div>
              </div>

              <!--                     <div *ngIf="item.groups[0].status !== 'failed' && item.fields.fox_content_class === 'ingest_wf' || item.fields.fox_content_class == undefined">
                       <table>
                         <tr>
                           <td style="padding-right: 15px; color: dodgerblue"> File Size (MB)</td>
                           <td style=" color: dodgerblue"> Total Time</td>
                         </tr>
                         <tr>
                            <td>
                             <p *ngIf="item.fields.Transfer">{{item.fields.Transfer}}</p>
                              <p *ngIf="!item.fields.Transfer">{{item.fields.Size}}<span *ngIf="item.fields.Speed"><br/><small>({{item.fields.Speed}})</small></span></p>
                           </td>
                           <td>
                             <p>{{ getTransferTime(i) }}</p>
                              <small>{{item.timestamp}}</small>
                           </td>
                         </tr>
                       </table>
                     </div>-->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped" *ngIf="showLCContentOnly">
      <thead>
        <tr>
          <th style="width: 5%"><span>&nbsp;</span></th>
          <th style="width: 10%; text-align: center"><span>Title</span></th>
          <th style="width: 10%; text-align: center"><span>Slug</span></th>
          <th style="width: 10%; text-align: center">
            <span>LC Story Number</span>
          </th>
          <th style="width: 10%;  text-align: center"><span>Auto Publish</span></th>
          <th style="width: 20%; text-align: center"><span>Transcribe</span></th>
          <th style="width: 5%; text-align: center"><span>Language</span></th>
          <th style="width: 35%;"><span>Details</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}">
          <td class="text-center">
            <div class="media-block">
              <i class="ico-file" (click)="displayOneUp(i)"></i>
            </div>
          </td>
          <td>
            <span style="justify-content: left;">
              <a *ngIf="item.fields.mimir_id" [href]="getOriginalFileLink(item.fields.mimir_id)"
                target="_new">{{item.fields.title}}</a>
            </span>
          </td>
          <td>{{item.fields.Slug}}</td>
          <td class="text-center">{{item.fields.LiveChoiceStoryNbr}}</td>
          <td>
            <div class="row no-gutter text-center">
              <!-- <div class="col-md-8"><strong>Auto Publish:</strong></div>
              <div class="col-md-4"> -->
              <!-- {{item.fields.AutoPublish ? 'Yes' : 'No'}} -->

              <span *ngIf="item.fields.AutoPublish">
                <i class="ico ico-2x ico-bold ico-check text-success"></i> Yes
              </span>
              <span *ngIf="!item.fields.AutoPublish">
                <i class="ico ico-2x ico-bold ico-times text-danger"></i> No
              </span>
              <!-- </div> -->
            </div>

          </td>
          <td>
            <div class="row no-gutter">
              <div class="col-md-8"><strong>Auto Publish Transcript:</strong></div>
              <div class="col-md-4">
                <!-- {{item.fields.AutoPublishTranscript ? 'Yes' : 'No'}} -->
                <span *ngIf="item.fields.AutoPublishTranscript">
                  <i class="ico ico-2x ico-bold ico-check text-success"></i> Yes
                </span>
                <span *ngIf="!item.fields.AutoPublishTranscript">
                  <i class="ico ico-2x ico-bold ico-times text-danger"></i> No
                </span>
              </div>
            </div>
            <div class="row no-gutter">
              <div class="col-md-8"><strong>Transcribe Event:</strong></div>
              <div class="col-md-4">
                <span *ngIf="item.fields.TranscribeEvent">
                  <i class="ico ico-2x ico-bold ico-check text-success"></i> Yes
                </span>
                <span *ngIf="!item.fields.TranscribeEvent">
                  <i class="ico ico-2x ico-bold ico-times text-danger"></i> No
                </span>
              </div>
            </div>
          </td>
          <td class="text-center">
            {{item.fields.Language}}
          </td>
          <td>
            <div *ngFor="let field of item.fieldsJson; let i2 = index" class="row no-gutter">
              <div class="col-md-4"><strong>{{field[0]}}:</strong></div>
              <div class="col-md-8">
                <span *ngIf="field[0] == 'LC ingest initiated time'; else other">{{ field[1] | dateFormate }}</span>
                <ng-template #other>
                  {{ field[1] }}
                </ng-template>
              </div>
            </div>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</div>
