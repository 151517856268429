import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css']
})
export class ClockComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;
  time: Date;
  constructor() { }

  ngOnInit() {
    this._subscription = IntervalObservable.create(1000).subscribe(x => {
      const currentDate = new Date();
      this.time = new Date(currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(),
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds());
     });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
