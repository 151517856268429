import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import {GoogleAnalyticsEventsService} from "../app.google-analynics-events.service";
import {RestrictionItem} from "../restrictionsobj/restriction.model";
import {RestrictionFields} from "../restrictionsobj/restriction-fields";
import {PopupComponent} from "../popup/popup.component";
import {PopupOptions} from "../popup/popup-options";
import { Observable } from '../../../node_modules/rxjs/Observable';
import { catchError, retry } from 'rxjs/operators';
import {_catch} from "rxjs/operator/catch";
import { ActiveTabService } from '../service/active-tab.service';

@Component({
  selector: 'app-restrictions',
  templateUrl: './restrictions.component.html',
  styleUrls: ['./restrictions.component.css']
})
export class RestrictionsComponent implements OnInit, OnDestroy {
  @ViewChild('restrictionsPopup') popupRestrictions: PopupComponent;
  // @ViewChild('uploadVideoPopup') videouploadpopup: PopupComponent;
  @Input() httpGetUri: string;
  public restrictionItems: RestrictionItem[] = [];
  public isAuthorizedToEdit = false;
  public allCount = 0;
  public query = '';
  public showDetails = [];
  public disableTable = false;
  private storedSearchQuery = '';
  private _subscription: Subscription;
  public errorMTop="0px";

  constructor(private activeTabService:ActiveTabService,private http: HttpClient, private sanitizer: DomSanitizer, private service: LoginService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
     this.isAuthorizedToEdit = this.canUpdateRestrictions();
  }

  ngOnInit() {
    this.activeTabService.activeTab.subscribe(data => {
      if (data == "restrictions") {
        if(this.restrictionItems.length==0){
          this.getRestrictions();
        }
      }
    });
   
    this._subscription = IntervalObservable.create(5000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "restrictions") {
        this.getRestrictions();
        this.disableTable=false;
      }
    });
  }



  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  canUpdateRestrictions(): boolean {
  var authenicated = false;
  const authRulesValue =  localStorage.getItem("authorizedRoles")
    console.log("restriction.js",authRulesValue)
   if (authRulesValue === null) {
     authenicated = false;
   }
   const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
   if (authRoles === null) {
     return false;
   }
   if(authRoles["AuthorizedRoles"].includes("Editor"+environment.envName) ||
   authRoles["AuthorizedRoles"].includes("Admin"+environment.envName))
     authenicated = true;

    return authenicated;
  }

  displayOneUp(i:number) {
    this.errorMTop=(window.scrollY+100)+"px";
    const optionsRestrictions: PopupOptions = new PopupOptions();
    optionsRestrictions.restrictionItem = this.restrictionItems[i];
    console.log(optionsRestrictions.restrictionItem);
    console.log(this.restrictionItems[i]);
    optionsRestrictions.showButtons = true;
    optionsRestrictions.showForm = false;
    optionsRestrictions.color = '#777';
    optionsRestrictions.header = 'Restrictions';
    optionsRestrictions.confirmBtnContent = 'Save';
    optionsRestrictions.widthPercentage = 55;
    optionsRestrictions.animation = 'fadeInDown';
    this.popupRestrictions.show(optionsRestrictions);
    // window.scrollTo(0, 0);
  }
displayEmptyOneUp() {
    const optionsRestrictions: PopupOptions = new PopupOptions();
    var restrictItem: RestrictionItem = new RestrictionItem();
    restrictItem.FieldKeyName = 'SOURCE';
    restrictItem.FieldKeyValue = '';
    var restrictfld_int: RestrictionFields = new RestrictionFields();
    restrictItem.Fields = new Array <RestrictionFields>();

    restrictfld_int.field_name = 'InternationalRestrictions';
    restrictfld_int.field_value = '';
    restrictItem.Fields.push(restrictfld_int);
    var restrictfld_DMA: RestrictionFields = new RestrictionFields();
    restrictfld_DMA.field_name = 'DMA';
    restrictfld_DMA.field_value = '';
    restrictItem.Fields.push(restrictfld_DMA);
    var restrictfld_source: RestrictionFields = new RestrictionFields();
    restrictfld_source.field_name = 'Source';
    restrictfld_source.field_value = '';
    restrictItem.Fields.push(restrictfld_source);

    optionsRestrictions.restrictionItem = restrictItem;
    console.log(optionsRestrictions.restrictionItem);
    this.errorMTop=(window.scrollY+100)+"px";
    optionsRestrictions.addnew = true;
    optionsRestrictions.showButtons = true;
    optionsRestrictions.showForm = false;
    optionsRestrictions.color = '#777';
    optionsRestrictions.header = 'Restrictions';
    optionsRestrictions.confirmBtnContent = 'Save';
    optionsRestrictions.widthPercentage = 45;
    optionsRestrictions.animation = 'fadeInDown';
    this.popupRestrictions.show(optionsRestrictions);
    // window.scrollTo(0, 0);
  }
 hideOneUp() {
    this.popupRestrictions.hide();
  }


  updateRestriction() {
    console.log(this.popupRestrictions.fieldResults);

    const restricionItem: RestrictionItem = JSON.parse(JSON.stringify(this.popupRestrictions.fieldResults));
    console.log(restricionItem);
    this.hideOneUp();
    console.log(this.putRestriction(restricionItem));
    this.getRestrictions();

  }

    delete(index:number){
    var item = this.restrictionItems[index];
      if(window.confirm("Are you sure you want to delete: [" + item.FieldKeyName + "/" + item.FieldKeyValue + "]?")) {
        this.deleteRestriction(item);
        this.getRestrictions();
      }
    }

    deleteRestriction(restrictionToDelete: RestrictionItem){
      var url = environment.RestrictionServiceUrl;
      if (!url || url === '') {
        return;
      }
      url = url + '/field-key-name/' + restrictionToDelete.FieldKeyName + '/field-key-value/' + restrictionToDelete.FieldKeyValue;

      console.log(url);
      var header = new HttpHeaders();
      header.set('Content-Type', 'application/json');
      header.set('Access-Control-Allow-Origin', '*');
      console.log("send delete!")
      this.disableTable = true;
      return this.http.delete(url,  {headers: header}).subscribe(resp => {
           console.log(resp);
         },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
          if(err.status ===404){
            console.log("execution not found!");
          }
          if(err.status ===500){
            console.log("Failed to delete restriction!" + "\r\n" + err.message);
          }
        });
  }

  putRestriction(restrictionToPut: RestrictionItem){
      var url = environment.RestrictionServiceUrl;
      if (!url || url === '') {
        return;
      }
      url = url + '/field-key-name/' + restrictionToPut.FieldKeyName + '/field-key-value/' + restrictionToPut.FieldKeyValue + '?userid=' + encodeURI(this.getCurrentUser());
     // var resubmitFailuresUri = environment.resubmitFailuresUri + 'core/?user=' + this.getCurrentUser();
     //  console.log(resubmitFailuresUri);
      console.log(url);
      console.log(restrictionToPut.Fields);
      var header = new HttpHeaders();
      header.set('Content-Type', 'application/json');
      header.set('Access-Control-Allow-Origin', '*');
      console.log("send put!")
      var toSend = '{ "Fields" : ' +  JSON.stringify(restrictionToPut.Fields) + '}';
      console.log(toSend);
      this.disableTable = true;
      return this.http.put(url, restrictionToPut, {headers: header}).subscribe(resp => {
           console.log(resp);
         },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
          if(err.status ===404){
            console.log("execution not found!");
          }
          if(err.status ===500){
            console.log("Failed to put new restriction!" + "\r\n" + err.message);
          }
        });
  }

  getRestrictions(): void {
    const url = environment.RestrictionServiceUrl;
    if (!url || url === '') {
      this.restrictionItems = [];
      return;
    }
    var header = new HttpHeaders();
    header.set('Content-Type', 'application/json');
    this.http.get(url, {headers: header}).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }

  processSearchResults(item: string): void {
    this.restrictionItems = [];
    this.resetCounts();
    const results: RestrictionItem[] = JSON.parse(item);
    if (this.storedSearchQuery.length != 0)
    {
      results.forEach(element => {
        var include = false;
        if (element.FieldKeyValue.toLowerCase().includes(this.storedSearchQuery.toLowerCase())) {
          include = true;
        }
        element.Fields.forEach(field => {
          if (field.field_value.toLowerCase().includes(this.storedSearchQuery.toLowerCase())) {
            include = true;
          }
        });
        if (include){
          this.allCount += 1;
          this.restrictionItems.push(element);
        }
    });
    }
    else this.restrictionItems = results;

  }

  resetCounts() {
    this.allCount = 0;
  }


  submit(search: string) {
    this.storedSearchQuery = search;
    this.getRestrictions();
  }

  logAnalynics(index: number, restrictionEvent: string) {
    try {
      const item = this.restrictionItems[index];
      this.googleAnalyticsEventsService.emitEvent("restrictions", restrictionEvent, (environment.envName.toLowerCase() === 'dev' ? 'dev_' : '') + this.getCurrentUser() + " | " + item.FieldKeyName + " | " + item.FieldKeyValue, 1);

    } catch (errGA) {
      console.log("error processing Google Analynics: " + errGA.toString());
    }

  }

  getCurrentUser(): string {
    var localuser = 'ADMIN';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(currentUser)
    if (currentUser) {
      if (currentUser.username) {
        const begin = currentUser.username.toString().indexOf('_');
        const end = currentUser.username.toString().indexOf('@');
        localuser = currentUser.username.toString().slice(begin + 1, end);
      } else
        console.log('user not found');
    }
    return localuser
  }


  getTime(time: string): number {
    return new Date(time).getTime();
  }

}
