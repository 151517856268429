import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ProviderConfig } from '../../../configuration/models/configuration/configuration';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../../login/login.service';
@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.css']
})

export class EditConfigurationComponent implements OnInit {

  @Output() confirmClick = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<any>();
  width: string="45";
  height: string;
  marginLeft: string="27.5%";
  marginTop: string;
  visible: boolean;
  header: string;
  confirmBtnContent: string;
  cancelBtnContent: string;
  showButtons: boolean;
  color: string;
  confirmBtnStyle: string;
  cancelBtnStyle: string;
  wellStyle: any;
  mainStyle: any;
  mainClass: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  public configuration:ProviderConfig
  constructor(private http: HttpClient, private service: LoginService) {

    this.confirmBtnStyle = 'ng2-opd-popup-Button';
    this.cancelBtnStyle = 'ng2-opd-popup-Button';
    this.mainClass = 'container ng2-opd-popup-content-main';

  }

  ngOnInit(): void {

    this.dropdownList = [
      { item_id: "VH", item_text: 'VH' },
      { item_id: "CR US", item_text: 'CR US' },
      { item_id: "Archive", item_text: 'Archive' }
    ];
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  public hide(): void {
    document.getElementById('overlay').style.display = 'none';
    this.visible = false;
  }

   
  confirmNo(): void {
    this.cancelClick.emit();
    this.hide();
  }

  getCurrentUser(): string {
    var localuser = 'ADMIN';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(currentUser)
    if (currentUser) {
      if (currentUser.username) {
        const begin = currentUser.username.toString().indexOf('_');
        const end = currentUser.username.toString().indexOf('@');
        localuser = currentUser.username.toString().slice(begin + 1, end);
      } else
        console.log('user not found');
    }
    return localuser
  }

  saveChanges(): void {


    let obj:any={}
    obj.provider_id=this.configuration.provider_id;
    obj.last_modified_by=encodeURI(this.getCurrentUser());
    obj.config={
      thumb_offset:this.configuration.config.thumb_offset,
      origin_format:this.configuration.config.origin_format
    }
    const url = environment.configurationBaseURL+"/providers"+"?provider-id="+this.configuration.provider_id;
   
   this.http.put(url, obj).subscribe(resp => {
     console.log(resp);
   },
   (err: HttpErrorResponse) => {
    this.confirmClick.emit();
    this.hide();
   });

  }

  public show(obj) {

    //configure mapping object
    this.configuration=JSON.parse(JSON.stringify(obj));
    if(this.configuration.config.package_ready_events.includes("encodation")){
      this.configuration.config.teletrax=true;
    }
    else{
      this.configuration.config.teletrax=false;
    }

    if(this.configuration.config.provider_ebiz_status && this.configuration.config.provider_ebiz_status==1){
      this.configuration.config.active=true;
    }
    else{
      this.configuration.config.active=false;
    }

    if(this.configuration.config.targets && this.configuration.config.targets!=""){
      if(this.configuration.config.targets=="videohub_archive"){
        this.selectedItems = [
          { item_id: "VH", item_text: 'VH' },
          { item_id: "Archive", item_text: 'Archive' }
        ];
      }
      else if(this.configuration.config.targets=="videohub"){
        this.selectedItems = [
          { item_id: "VH", item_text: 'VH' }
        ];
      }
      else if(this.configuration.config.targets=="archive"){
        this.selectedItems = [
          { item_id: "Archive", item_text: 'Archive' }
        ];
      }
    }
    else{
      this.selectedItems = []
    }



    //setting popup
    this.confirmBtnStyle = "ng2-opd-popup-Button";
    this.cancelBtnStyle = "ng2-opd-popup-Button";
    this.setWidth();
    this.wellStyle = { };
    this.mainStyle = { 'border-color': "#5cb85c",
      'position': 'absolute',
      'border-style': 'solid',
      'border-width': '1px',
      'width': this.width,
      'margin-top': this.marginTop,
      'margin-left': this.marginLeft,
      'animation-duration': 0 + 's',
      'animation-name': 'fadeInDown',
      'max-height': '700px',
      'overflow': 'auto'
    };

    document.getElementById('overlay').style.display = 'block';
    this.visible = true;

  }


  private setWidth() {
    const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (w < 736) {
        this.marginTop = '2%';
        this.marginLeft = '2%';
        this.width = '96%';
    } else {
        this.marginTop = '10%';
        this.width = '50%';
        this.marginLeft = '27.5%';
    }
  }


}
