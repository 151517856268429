
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


export class TokenPayload {
    access_token: string;
    refresh_token: string;
    id_token: string;
    token_type: string;
    expires_in: number;
}

@Injectable()
export class LoginService {

  public static userTokens: TokenPayload;

  constructor(private http: HttpClient) { }

  getTokensFromCode(code: string): Observable<boolean> {
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('grant_type', 'authorization_code');
    body = body.set('client_id', environment.GetTokenClientId);
    body = body.set('redirect_uri', environment.GetTokenRedirectUri);
    body = body.set('code', code);
    return this.http.post<TokenPayload>(environment.GetTokenUrl, body, { headers: header }).pipe(map(this.loadTokens));
  }

  loadTokens(res: TokenPayload): boolean {
    LoginService.userTokens = res;
    console.log(LoginService.userTokens.access_token);
    console.log(LoginService.userTokens.id_token);
    return true;
  }

  addAccessToken(header: HttpHeaders): HttpHeaders {
    if (!header) {
        header = new HttpHeaders();
    }
    // if (LoginService.userTokens) {
    //     header = header.set('Authorization', 'Bearer ' + LoginService.userTokens.access_token);
    // }
    return header;
  }

  addIdToken(header: HttpHeaders): HttpHeaders {
    if (!header) {
        header = new HttpHeaders();
    }
    if (LoginService.userTokens) {
        header = header.set('Authorization', 'Bearer ' + LoginService.userTokens.id_token);
    }
    return header;
  }

}
