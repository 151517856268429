import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

if (environment.googleAnalynicsId.length > 0) {
  // document.write('<script type="text/javascript">(function(i, s, o, g, r, a, m) {\n' +
  //   '            i[\'GoogleAnalyticsObject\'] = r;\n' +
  //   '            i[r] = i[r] || function() {\n' +
  //   '                (i[r].q = i[r].q || []).push(arguments)\n' +
  //   '            }, i[r].l = 1 * new Date();\n' +
  //   '            a = s.createElement(o),\n' +
  //   '                m = s.getElementsByTagName(o)[0];\n' +
  //   '            a.async = 1;\n' +
  //   '            a.src = g;\n' +
  //   '            m.parentNode.insertBefore(a, m)\n' +
  //   '        })(window, document, \'script\', \'https://www.google-analytics.com/analytics.js\', \'ga\');\n' +
  //   'ga(\'create\', \'' + environment.googleAnalynicsId  + '\', \'auto\');</script>');
}

