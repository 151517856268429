import {Component, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import {Subscription} from "rxjs";
import {LoginService} from "../login/login.service";
import {NavigationEnd, Router} from "@angular/router";
import { ActiveTabService } from '../service/active-tab.service';

@Component({
  selector: 'app-content-tabset',
  templateUrl: './content-tabset.component.html',
  styleUrls: ['./content-tabset.component.css']
})

export class ContentTabsetComponent {
  foxTabActive = true;
  youTubeTabActive = false;
  upcomingTabActive = false;
  opsTabActive = false;
  contributionsTabActive = false;
  reportingTabActive = false;
  restrictionsTabActive = false;
  configurationTabActive = false;
  archiveorderfulfillmentTabActive = false;
  searchUri = environment.SearchQuery;
  public authorized = false;
  public _currentPage = '/editorial';
  private _subscription: Subscription;


  // canDisplayOperationsPage():boolean{
  //   return true;
  //   const authRulesValue =  localStorage.getItem("authorizedRoles")
  //    if (authRulesValue === null) {
  //      return false;
  //    }
  //    const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
  //    if (authRoles === null) {
  //      return false;
  //    }
  //    if(authRoles["AuthorizedRoles"].includes("Admin"+environment.envName))
  //      return true;
  //    else
  //      return false;
  //   }





  setActive(tabname: string): void {
    if (tabname === "editorial") {
      this.foxTabActive = true;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    } else if (tabname === "youtube") {
      this.foxTabActive = false;
      this.youTubeTabActive = true;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    } else if (tabname === "operations")  {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = true;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    } else if (tabname === "upcoming") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = true;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    } else if (tabname === "ingest") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = true;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    } else if (tabname === "reporting") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = true;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    }else if (tabname === "restrictions") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = true;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=false;
    }else if (tabname === "archiveorderfulfillment") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = true;
      this.configurationTabActive=false;
      console.log(this.archiveorderfulfillmentTabActive);
    }
    else if (tabname === "configuration") {
      this.foxTabActive = false;
      this.youTubeTabActive = false;
      this.upcomingTabActive = false;
      this.opsTabActive = false;
      this.contributionsTabActive = false;
      this.reportingTabActive = false;
      this.restrictionsTabActive = false;
      this.archiveorderfulfillmentTabActive = false;
      this.configurationTabActive=true;
    }
  }
  constructor(private service: LoginService, private router: Router,private activeTabService:ActiveTabService) {
     this.router.events.subscribe(event => {
       if (event instanceof NavigationEnd) {
         console.log(event);
         console.log(event.urlAfterRedirects);
         this._currentPage = event.urlAfterRedirects;
         if (this._currentPage === '/editorial') {
           this.setActive('editorial');
           this.activeTabService.setActiveTab("editorial");
         } else if (this._currentPage.toLowerCase() === '/youtube') {
           this.setActive('youtube');
           this.activeTabService.setActiveTab("youtube");
         } else if (this._currentPage.toLowerCase() === '/operations') {
           this.setActive('operations');
           this.activeTabService.setActiveTab("operations");
         } else if (this._currentPage.toLowerCase() === '/upcoming') {
           this.setActive('upcoming');
           this.activeTabService.setActiveTab("upcoming");
         } else if ((this._currentPage.toLowerCase() === '/contributions' || (this._currentPage.toLowerCase() === '/ingest')) ) {
           this.setActive('ingest');
           this.activeTabService.setActiveTab("ingest");
         } else if (this._currentPage.toLowerCase() === '/reporting') {
           this.setActive('reporting');
           this.activeTabService.setActiveTab("reporting");
         } else if (this._currentPage.toLowerCase() === '/restrictions') {
           this.setActive('restrictions');
           this.activeTabService.setActiveTab("restrictions");
         } else if (this._currentPage.toLowerCase() === '/archiveorderfulfillment') {
           this.setActive('archiveorderfulfillment');
           this.activeTabService.setActiveTab("archiveorderfulfillment");
         } else if (this._currentPage.toLowerCase() === '/configuration') {
          this.setActive('configuration');
          this.activeTabService.setActiveTab("configuration");
        }
       }
     }
    );
    console.log("isRoleOperationsResubmit...",this.isRoleOperationsResubmit());
  }

  isRoleOperationsResubmit(){
    const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
    console.log("authRoles..........",authRoles);
    if (authRoles && authRoles["AuthorizedRoles"] && authRoles["AuthorizedRoles"].includes("Admin"+environment.envName)){
      return true;
    }
    else{
      return false;
    }
  }


}
