import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { FoxEventGroup } from "../foxeventobj/foxevent-group";
import { FoxEventStage } from "../foxeventobj/foxevent-stage";
import { FoxEventStep } from "../foxeventobj/foxevent-step";
import {HistogramData} from "../histogramobj/histogram.model";
import {HistogramDataHour} from "../histogramobj/histogram-hour";
import { start } from "repl";
import { booleanObjectType } from "aws-sdk/clients/iam";
 
import { GoogleAnalyticsEventsService } from "../app.google-analynics-events.service";
import { color } from 'echarts';
import { ActiveTabService } from '../service/active-tab.service';
import * as moment from 'moment';

@Component({
  selector: 'app-operationslist',
  templateUrl: './operationslist.component.html',
  styleUrls: ['./operationslist.component.css']
})
export class OperationslistComponent implements OnInit, OnDestroy {
  @ViewChild('operationsPopup') popup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public histogramItems: HistogramData;
  public storedItemsCondensed: [String[][]];
  public filterCompleted = 'all';
  public oneUp: string;
  public isAllActive = true;
  public isProcessingActive = false;
  public isCompletedActive = false;
  public isFailedActive = false;
  public allowHeartbeat = false;
  public showArchiveContent = false;
  public viewAudio = true;
  public viewVideo = true;
  public showAPContentOnly = false;
  public allCount = 0;
  public completedCount = 0;
  public inProgressCount = 0;
  public failedCount = 0;
  public query = '';
  private storedSearchQuery = '';
  private _subscription: Subscription;
  public isResubmitButtonDisplayed = false;
  public showDetails = [];
  public disableResubmit = [];
  private isAuthorizedToResubmit = false;
  private pauseRefresh = false;
  private d = new Date();
  private startDate = '';
  private endDate = '';
  public histogramStartDate = '';
  private showMamEvents = false;
  private showPreviewFirst = false;
  private showArchiveOrderEvents = false;
  public histogramRadio = 'StoryCount';
  paging = 50;
  regions = 'all';
  public histogramTotalStories=0;
  public histogramTotalMinutes=0;
  public showHistogram=false;
  public errorMTop="0px";
  initOpts = {
    renderer: 'svg',
    width: 1200,
    height: 300
  };

  options: any = {
    responsive: true,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        label:'sasasa',
        type: 'category',
        data: ['0','1', '2', '3', '4', '5', '6', '7','8','9','10','11','12','13','14','15','16','17'
        ,'18','19','20','21','22','23'],
        axisTick: {
          alignWithLabel: true
        },
      }
    ],
    yAxis: [{
      type: 'value'
    }],
    series: [{
      name: 'Counters',
      type: 'bar',
      barWidth: '60%',
      //data: [10, 52, 200, 334, 390, 330, 220,10, 600, 200, 334, 390, 330, 220,10, 52, 200, 334, 390, 330, 220,3,4,6]
    }]
  };

  constructor(private activeTabService:ActiveTabService,private http: HttpClient, private sanitizer: DomSanitizer, private service: LoginService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
    this.isAuthorizedToResubmit = this.canResubmit();

  }

  onBarChartEvent(e: any) {
    console.log(e.value);
  }

  canViewPage(): boolean {
    // const authRulesValue = localStorage.getItem("authorizedRoles")
    // if (authRulesValue === null) {
    //   // console.log("no authorizedRoles, but view anyway")
    //   return false;
    // }
    // const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
    // if (authRoles === null) {
    //   return false;
    // }
    // if (authRoles["AuthorizedRoles"].includes("RoleOperationsPage"))
    //   return true;
    // else
    // console.log("not RoleOperationsPage, but view anyway")
    return true;
  }

  canResubmit(): boolean {
    /* const isDebug = environment.debug;
     if (isDebug) {
         var listofadmins:string[] = environment.adminUsers.toLowerCase().split(';');
         if (listofadmins.indexOf(this.getCurrentUser().toLowerCase().trim()) != -1) {
           console.log('current user found: ' + this.getCurrentUser().toLowerCase().trim());
           return true;
         } else
           return false;
     }*/
    const authRulesValue = localStorage.getItem("authorizedRoles")
    if (authRulesValue === null) {
      console.log('no authorizedRoles');
      return false;
    }
    const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
    if (authRoles === null) {
      return false;
    }
    if (authRoles["AuthorizedRoles"].includes("Admin"+environment.envName))
      return true;
    else
      return false;
  }

  ngOnInit() {
    let localStoragePaging = localStorage.getItem("OpsPaging");
    if (!localStoragePaging || localStoragePaging == undefined) {
      localStorage.setItem("OpsPaging", this.paging.toString());
    }
    else {
      this.paging = parseInt(localStoragePaging);
    }

    let localStorageRegion = localStorage.getItem("OpsRegion");
    if (!localStorageRegion || localStorageRegion == undefined) {
      localStorage.setItem("OpsRegion", this.regions.toString());
    }
    else {
      this.regions = localStorageRegion;
    } 

    var d = new Date();
    var dStart = new Date(d);
    dStart.setDate(d.getDate() - 3);
    this.startDate = dStart.getFullYear() + '-' + ('0' + (dStart.getMonth() + 1)).slice(-2) + '-' + ('0' + dStart.getDate()).slice(-2);

    var dStart = new Date(d);
    dStart.setDate(d.getDate());
    this.histogramStartDate = dStart.getFullYear() + '-' + ('0' + (dStart.getMonth() + 1)).slice(-2) + '-' + ('0' + dStart.getDate()).slice(-2);


    var dEnd = new Date();
    dEnd.setDate(dEnd.getDate() + 1);
    this.endDate = dEnd.getFullYear() + '-' + ('0' + (dEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + dEnd.getDate()).slice(-2);

    this.activeTabService.activeTab.subscribe(data => {
      if (data == "operations") {
        if(this.storedItems.length==0){
          this.getEventItems();
        }
      }
    });
    //this.getHistogramItems();
    this._subscription = IntervalObservable.create(15000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "operations") {
        this.getEventItems();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getEventItems(): void {
    const url = this.createSearchRequest();
    if (this.pauseRefresh)
      return;

    if (!url || url === '') {
      this.storedItems = [];
      return;
    }
    //console.log(url);
    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
    if (this.isFailedActive === true)
      this.pauseRefresh = true;
  }

  onPagingSelectChange() {
    localStorage.setItem("OpsPaging", this.paging.toString());
    localStorage.setItem("OpsRegion", this.regions.toString());
    this.getEventItems();
  }

  createSearchRequest(): string {
    if (this.viewAudio === false && this.viewVideo === false) {
      return '';
    }

    let baseUrl=this.httpGetUri + 'limit=' + this.paging + '&query=';
    // base query for operations tab.
    let searchQuery = baseUrl + 'NOT source:ingest_mam AND NOT source:planning AND NOT source:ingest_lcr AND NOT source:youtube AND NOT source:expedat AND NOT source:aspera AND NOT fields.fox_content_class:bulkarchiveingest AND NOT source:mimir_story_publisher AND NOT source:transmitted';

    // Show both Audio and Video?  OR just Audio, or just video
    if (this.viewAudio === true && this.viewVideo === true) {
      searchQuery = searchQuery + '';
    } else if (this.viewAudio === true && this.viewVideo === false) {
      searchQuery = searchQuery + ' AND NOT fields.mediainfo_sourceFormat:Video';
    } else if (this.viewAudio === false && this.viewVideo === true) {
      searchQuery = searchQuery + ' AND NOT fields.mediainfo_sourceFormat:Audio';
    }
    // show all status by default or completed or failed
    if (this.isProcessingActive === true) {
      searchQuery = searchQuery + ' AND (groups.status:processing OR groups.status:pending)';
    } else if (this.isCompletedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:completed';
    } else if (this.isFailedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:failed';
    }
    // Display AP content
    if (this.showAPContentOnly === true) {
      searchQuery = searchQuery + " AND (fields.provider_id:ap OR fields.provider_id:apus OR fields.provider_id:apaudio OR fields.provider_id:AP OR fields.provider_id:APUS OR fields.provider_id:u'ap' OR fields.provider_id:u'apus' OR source:video_publish_event)";
      if (this.allowHeartbeat === false) {
        searchQuery = searchQuery + ' AND NOT fields.provider_id:heartbeataudio AND NOT fields.title:heartbeatvideo AND NOT "Video Publish Heartbeat"';
      }
    }

    // *********** disable items from the view ****************
    // MAM events (uploads of videos from the MAM)
    if (this.showMamEvents == false) {
      searchQuery = searchQuery + ' AND NOT source:video_publish_event';
    }

    // do not display preview first AND Watermark (WOS)
    // if (this.showPreviewFirst == false) {
     // searchQuery = searchQuery + ' AND NOT fields.fox_namespace:*previewfirst AND NOT source:binary_preprocessing';
    // }
    //do not display archive order fulfillment

    if (!this.showArchiveOrderEvents) {
      searchQuery = searchQuery + ' AND NOT fields.fox_content_class:archive_order_fulfillment AND NOT source:AOF ';
    }
    // do not display heartbeats
    if (this.allowHeartbeat === false) {
      searchQuery = searchQuery + ' AND NOT fields.provider_id:heartbeataudio AND NOT fields.title:heartbeatvideo AND NOT "Video Publish Heartbeat"';
    }
    // Do not display bulk archive ingest
    if (this.showArchiveContent === false) {
      searchQuery = searchQuery + ' AND NOT fields.fox_content_class:bulkarchiveingest';
    }
    // if failed display refresh
    if (!this.isFailedActive)
      this.pauseRefresh = false;
    // include search text from the text box
    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery;
    }

    if(this.regions && this.regions !== 'all')
    {
      searchQuery = searchQuery + ' AND fields.fox_processing_region:' + this.regions;
    }
    // if there are start and end dates then please include them
    if (this.startDate && this.endDate) {
      searchQuery = searchQuery + '&date_start=' + this.startDate + '&date_end=' + this.endDate;
    }

    console.log(searchQuery);
    return searchQuery;
  }

  processSearchResults(item: string): void {
    this.storedItems = [];
    this.resetCounts();
    const results: SearchResult = JSON.parse(JSON.stringify(JSON.parse(item)));
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      switch (event.groups[0].status) {
        case 'completed':
          this.completedCount += 1;
          break;
        case 'processing':
          this.inProgressCount += 1;
          break;
        case 'failed':
          this.failedCount += 1;
          break;
      }
      this.allCount += 1;

      const itemsCondensed: FoxEvent = this.getCondensedEventDetails(event);

      if (itemsCondensed.groups[0].stages.length != 0) {
        this.storedItems.push(itemsCondensed);
      }
    });
  }

  resetCounts() {
    this.allCount = 0;
    this.completedCount = 0;
    this.inProgressCount = 0;
    this.failedCount = 0;
  }

  getThumbUrl(item): String {
    const status = item.groups[0].status;
    const story_number = item.fields.content_provider_storyNumber;
    const item_id = item.fields.content_provider_newsroom_itemId;
    // thumb uri should be moved to environment file
    if (status === 'completed') {
      return `http://qa-apvideo-proxies.s3.amazonaws.com/${story_number}_${item_id}/${story_number}_${item_id}_JPEG_480x270-00002.jpg`;
    }

  }
  getEcrUrl(item_id): String {
    return environment.EcrUrl + item_id;
  }

  getWebFeedsUrl(item_id): SafeUrl {
    let url=environment.WebFeedsUrl.replace("itemValue",item_id);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getWebFeedsDocuement(item_id) {
    var url=environment.WebFeedsUrl.replace("itemValue",item_id);
    var response = ''
    var header = new HttpHeaders();
    header = header.set('Content-Type', 'application/xml');
    this.http.get(url, { headers: header }).subscribe(response => {
      console.log('User\'s Location Data is ', response);
      console.log(response);
      alert(response);
    });
  }
  getGroupStepStatus(index: number): String[][] {
    const messages = [];
    var i = 0;
    for (const stage of this.storedItems[index].groups[0].stages) {
        // determine if the upload step (package ready check events) should be displayed
        var boolDisplayUpload = false;
        for (const step of stage.steps.reverse()) {
          if (step.name === 'metadata_event_store')
            boolDisplayUpload = true;
        }
      if (stage.name.startsWith('core') || stage.name.startsWith('ingest') || stage.name.startsWith('watermark') || boolDisplayUpload || stage.name.startsWith('upload')) {
        var stagename = 'UNIVERSALDOC'
        if (stage.name.split('-')[2] != 'videohub' || stage.name.split('-').length == 3)
          stagename = stage.name.split('-')[2]
        else if (stage.name.split('-')[2] != 'videohub' || stage.name.split('-').length == 5)
          stagename = stage.name.split('-')[5]
        else if (stage.name === 'upload')
          stagename = 'MAM INGEST'
        for (const step of stage.steps) {
          if (step.status != 'completed') {
            messages.push({
              stage: stagename,
              name: step.name === 'scheduler' ? 'transcode' : step.name === 'upload' ? 'Story Published' : step.name,
              status: step.status,
              message: step.message,
              start: step.start,
              end: step.end,
              displayResubmit: i === 0
            });
            ++i;
          }
        }
      }
    }
    return messages.reverse();
  }


  getCondensedEventDetails(foxEvent): FoxEvent {
    const event: FoxEvent = new FoxEvent();
    const listOfStagePrefixes = [];
    event.fields = foxEvent.fields;
    event.id = foxEvent.id;
    event.rawEvent = foxEvent.rawEvent;
    event.source = foxEvent.source;
    event.timestamp = foxEvent.timeStamp;

    const grp: FoxEventGroup = new FoxEventGroup();
    grp.name = foxEvent.groups[foxEvent.groups.length - 1].name;
    grp.status = foxEvent.groups[foxEvent.groups.length - 1].status;
    grp.version = foxEvent.groups[foxEvent.groups.length - 1].version;

    event.groups = [];
    const tempStagesArray: FoxEventStage[] = [];
    for (const stage of foxEvent.groups[foxEvent.groups.length - 1].stages.reverse()) {
      const stg: FoxEventStage = new FoxEventStage();
      // determine if the upload step (package ready check events) should be displayed
        var boolDisplayUpload = false;
        for (const step of stage.steps.reverse()) {
          if (step.name === 'metadata_event_store')
            boolDisplayUpload = true;
        }
      if (stage.name.startsWith('core') || stage.name.startsWith('ingest') || stage.name.startsWith('watermark') || boolDisplayUpload || stage.name.startsWith('upload') ) {
        //stg.status=stage.status;
        stg.start = stage.start;
        stg.end = stage.end;

        var isLatestStep = false;
        var markAsOld = false;
        if (listOfStagePrefixes.indexOf(stage.name.split('-')[0] + stage.name.split('-')[2]) == -1) {
          listOfStagePrefixes.push(stage.name.split('-')[0] + stage.name.split('-')[2]);
          isLatestStep = true;
        }
        else { markAsOld = true; }

        var stagename = 'UNIVERSALDOC';
        if (stage.name.split('-')[2] != 'videohub' && stage.name.split('-').length == 3)
          stagename = stage.name.split('-')[2].toUpperCase();
        else if (stage.name.split('-')[2] != 'videohub' || stage.name.split('-').length == 5)
          stagename = stage.name.split('-')[5]
        if (stage.name.startsWith('ingest'))
          stagename = 'INGEST';

        if (stage.name === 'upload')
          stagename = 'MAM INGEST'
        if (stage.name === 'watermark')
          stagename = 'WATERMARK'
        if (stagename === '' || stagename === undefined)
          stagename = 'UNIVERSALDOC';

        //set stagename:
        stg.stageLabel = stagename;
        stg.name = stage.name === 'upload' ? 'Story Published' : stage.name === 'watermark' ? 'WATERMARK' : stage.name;
        stg.status = markAsOld && stage.status != 'failed' ? 'pending' : stage.status;

        var stepName = '';
        var message = '';
        var i = 0;
        stg.steps = [];
        var boolDisplaythisStep = false;
        for (const step of stage.steps) {
          boolDisplaythisStep = false;
          //fix a bug without a code hotfix to lambda
          if (step.status === 'falied') {
            grp.status = 'failed';
            stg.status = 'failed';
            step.status = 'failed';
            console.log('set group and staging to "failed"');
          }
          if (i === 0 && step.status != 'failed') {
            stepName = step.name;
            boolDisplaythisStep = true;
          }
          else if (step.status === 'failed') {
            stepName = step.name;
            boolDisplaythisStep = true;
          }

          else { continue; }
          //else {boolDisplaythisStep=true;}
          if (step.status === 'failed'){
            message = step.message;
          }

          stepName = stepName === 'scheduler' ? 'transcode' : (step.name === 'upload' ? 'Story Published' : stepName)

          if (boolDisplaythisStep === true) {
            var displayResubmitButton = (isLatestStep && this.isAuthorizedToResubmit && stage.status === 'failed') || (isLatestStep && this.isAuthorizedToResubmit && step.status === 'completed' && this.isResubmitButtonDisplayed);
            //see if we cna assign step name here and if it will take
            stg.steps.push(new FoxEventStep(stepName, step.start, step.end, markAsOld && stage.status != 'failed' ? 'pending' : step.status, message, isLatestStep, displayResubmitButton));
          }
          else { console.log("displaythisStep is false: " + stepName); }
          ++i;
        }
        //push stages...

        tempStagesArray.push(stg);
      }
    }

    grp.stages = [];
    for (const stgA of tempStagesArray) {
      grp.stages.push(stgA);
    }

    event.groups.push(grp);

    return event;
  }

  hasTarget(index: number, target: string): boolean {
    var hasit = false;

    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {
      var stagename = '';
      if (stage.name.split('-').length === 3) {
        if (stage.name.split('-')[2] !== 'videohub') {
          stagename = stage.name.split('-')[2];
        }
        else if (stage.name.split('-')[2] === 'videohub') {
          stagename = 'universaldoc';
        }
      }
      else if (this.storedItems[index].fields.provider_id === 'apaudio') {
        stagename = 'newsroom';
      }

      if (stagename === target)
        return true;
      else
        continue;
    }

    return hasit;
  }

  getGroupErrorMessage(index: number): String[][] {
    const messages = [];
    var i = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {
      var stagename = 'UNIVERSALDOC';
      if (stage.name.split('-').length == 3)
        if (stage.name.split('-')[2] !== 'videohub')
          stagename = stage.name.split('-')[2].toUpperCase();
        else if (stage.name.split('-')[2] != 'videohub' || stage.name.split('-').length == 5)
          stagename = stage.name.split('-')[5]
      for (const step of stage.steps) {
        if (step.status === 'failed') {
          messages.push({ displayResubmit: i === 0, stage: stagename, name: step.name === 'scheduler' ? 'transcode' : step.name, message: step.message, stagename: stage.name, start: step.start, end: step.end });
          ++i;
        }
      }
    }
    return messages.reverse();
  }

  getEndTime(index: number): number {
    let endDate = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {

      endDate = new Date(stage.end).getTime();
      break;

    }
    return endDate;
  }


  getStartTime(index: number): number {
    let startDate = 0;
    if(this.storedItems[index].fields.fox_content_class == 'enps' || this.storedItems[index].fields.fox_content_class =='mimimr')
    {
      for (const stage of this.storedItems[index].groups[0].stages) {
        if(stage.name === 'upload') {
          for (const step of stage.steps) {
            if (step.name === 'metadata_event_store') {
              startDate = new Date(step.start).getTime();
              break;
            }
          }
        }
      }
    }
    if (startDate === 0){
          for (const stage of this.storedItems[index].groups[0].stages) {
            if(stage.name.startsWith('core') || stage.name.startsWith('ingest') || stage.name === 'upload') {
                  startDate = new Date(stage.start).getTime();
                  break;
            }
          }
    }
    return startDate;
  }


  getTimeToMarket(index: number): string {
    let result = '';
    let difference = -1;
    const endDate: number = this.getEndTime(index);
    const startDate: number = this.getStartTime(index);
    if (endDate === 0 || startDate === 0) {
      return '';
    }
    difference = endDate - startDate;
    const diffMinutes = difference / (60000);
    const absoluteMinutes = Math.floor(diffMinutes);
    if (absoluteMinutes > 0) {
      const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
      result = mins + ' mins ';
    }
    const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
    const absoluteSeconds = Math.floor(diffSeconds);
    if (absoluteSeconds > 0) {
      const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
      result = result + secs + ' secs';
    }
    if (result === '') {
      result = '0 mins 0 secs';
    }
    return result;
  }

  getDuration(duration: any): string {
    const diffHours = duration / 3600000;
    const absoluteHours = Math.floor(diffHours);
    const hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
    const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
    const absoluteSeconds = Math.floor(diffSeconds);
    const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
    return hours + ':' + mins + ':' + secs;
  }

  onFilterChange(filter: string): void {
    switch (filter) {
      case 'all':
        this.isAllActive = true;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'processing':
        this.isAllActive = false;
        this.isProcessingActive = true;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'completed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = true;
        this.isFailedActive = false;
        break;
      case 'failed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = true;
        break;
    }
    this.filterCompleted = filter;
    this.getEventItems();
    this.getHistogramItems();
  }

  APOnlyChanged() {
    this.showAPContentOnly = !this.showAPContentOnly;
    this.onFilterChange(this.filterCompleted);
  }

  heartbeatChanged() {
    this.allowHeartbeat = !this.allowHeartbeat;
    this.onFilterChange(this.filterCompleted);
  }

  audioChanged() {
    this.viewAudio = !this.viewAudio;
    this.onFilterChange(this.filterCompleted);
  }

  videoChanged() {
    this.viewVideo = !this.viewVideo;
    this.onFilterChange(this.filterCompleted);
  }

  archiveFilterChanged() {
    this.showArchiveContent = !this.showArchiveContent;
    this.onFilterChange(this.filterCompleted);
  }

  copyErrorToClipboard(index: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}]\n`;
          i++;
        }
      }
    }

    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id:${item_url}
Error:${error_message}`;
    selBox.value = copy_text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getErrorIfExists(errorMsg: string) {
    var returnValue = '';
    var errorMessageInt = errorMsg.indexOf('errorMessage:');
    if (errorMessageInt === -1) {
      errorMessageInt = errorMsg.indexOf('\\"errorMessage\\":');
    }
    if (errorMessageInt === -1) {
      errorMessageInt = errorMsg.indexOf('Exception: ');
    }
    if (errorMessageInt === -1) {
      errorMessageInt = errorMsg.indexOf('"Error":');
    }
    if (errorMessageInt !== -1) {
      returnValue = errorMsg.substring(errorMessageInt, errorMessageInt + 65) + '..';
    }
    return returnValue;
  }

  displayError(index: number) {
    this.errorMTop=(window.scrollY+80)+"px";
    // console.log("panel_" + index);
    // console.log(this.storedItems[index].id);
    // if (this.showDetails.includes(this.storedItems[index].id)) {
    //   for (var i = 0; i < this.showDetails.length; i++) {
    //     if (this.showDetails[i] === this.storedItems[index].id) {
    //       this.showDetails.splice(i, 1);
    //       i--;
    //     }
    //   }
    // }
    // else {
    //   this.showDetails.push(this.storedItems[index].id);
    // }

    this.displayErrorUp(index);

    this.copyErrorToClipboard(index);

  }


  displayErrorUp(index: number) {
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}] \n`;
          i++;
        }
      }
    }
    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id: ${item_url}
Error:${error_message}`;

    this.displayOneUpWithMessage(copy_text, 'Error Details', 50);
  }

  displayOneUpWithMessage(content, header, widthPercent) {
    this.errorMTop=(window.scrollY+80)+"px";
    this.oneUp = content;
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = header;
    options.widthPercentage = widthPercent;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    //window.scrollTo(0, 0);
  }

  displayOneUp(index: number) {
    this.errorMTop=(window.scrollY+80)+"px";
    this.oneUp = JSON.stringify(this.storedItems[index].rawEvent, null, 2);
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = 'Item Details';
    options.widthPercentage = 75;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    // window.scrollTo(0, 0);
  }

  hideOneUp() {
    this.popup.hide();
  }

  submit(search: string) {
    this.storedSearchQuery = search;
    this.getEventItems();
    this.getHistogramItems();
  }

  sanitizeUrl(url: string) {
    if (!url || url === '') {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  showResubmitButton() {
    if (this.isAuthorizedToResubmit)
      this.isResubmitButtonDisplayed = !this.isResubmitButtonDisplayed;
    this.getEventItems();
    console.log("replay button: " + this.isResubmitButtonDisplayed);
  }

  showMamEventsClick() {
    console.log("MAM!");
    this.showMamEvents = !this.showMamEvents;
    this.onFilterChange(this.filterCompleted);
    this.getEventItems();
  }

  showPreviewFirstClick() {
    console.log("PF!");
    this.showPreviewFirst = !this.showPreviewFirst;
    this.onFilterChange(this.filterCompleted);
    this.getEventItems();
  }


  showArchiveOrdersClick() {
    this.showArchiveOrderEvents = !this.showArchiveOrderEvents;
    this.onFilterChange(this.filterCompleted);
    this.getEventItems();
    this.getHistogramItems();
  }

  getCurrentUser(): string {
    var localuser = '';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if (currentUser.username) {
        const begin = currentUser.username.toString().indexOf('_');
        const end = currentUser.username.toString().indexOf('@');
        localuser = currentUser.username.toString().slice(begin + 1, end);
      }
      else
        console.log('user not found');
    }
    return localuser
  }


  resubmit(stagename, region, index) {
    var baseUrl = environment.resubmitFailuresUri;
    if (region.includes('us-west-2')){
      baseUrl = environment.resubmitFailuresUriUsw2;
    }
    //disable
    var resubmitFailuresUri = baseUrl + stagename + '/?user=' + this.getCurrentUser();
    console.log(resubmitFailuresUri);
    try {
      this.googleAnalyticsEventsService.emitEvent("operations", "resubmit-user-refId", (environment.envName.toLowerCase() === 'dev' ? 'dev_' : '') + this.getCurrentUser() + ' | ' + stagename, 1);
    }
    catch (errGA) { console.log("error processing Google Analynics: " + errGA.toString()); }
    try {
      this.storedItems[index].resubmitted = true;
      if (this.disableResubmit.includes(this.storedItems[index].id)) {
        for (var i = 0; i < this.disableResubmit.length; i++) {
          if (this.disableResubmit[i] === this.storedItems[index].id) {
            this.disableResubmit.splice(i, 1);
            i--;
          }
        }
      }
      else {
        this.disableResubmit.push(this.storedItems[index].id);
        console.log("disbledResubmit");
        console.log(this.disableResubmit);
      }

      this.http.put(resubmitFailuresUri, '', { headers: null, responseType: "text" }).subscribe(resp => {
        console.log(resp);

      },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
          if (err.status === 404) {
            this.errorMTop=(window.scrollY+80)+"px";
            console.log("execution not found! Orchestation id: " + stagename);
            this.oneUp = "Execution not found!\r\nOrchestation id:\r\n" + stagename;
            const options: PopupOptions = new PopupOptions();
            options.showButtons = false;
            options.color = '#777';
            options.header = 'Issue with resubmit';
            options.widthPercentage = 25;
            options.animation = 'fadeInDown';
            this.popup.show(options);
            // window.scrollTo(0, 0);//enable
          }
          if (err.status === 500) {
            this.errorMTop=(window.scrollY+80)+"px";
            console.log("Failed to process resubmit! \r\nOrchestation id: " + stagename);
            this.oneUp = "Failed to process resubmit!\r\nOrchestation id:\r\n" + stagename + "\r\n" + err.message;
            const options: PopupOptions = new PopupOptions();
            options.showButtons = false;
            options.color = '#777';
            options.header = 'Issue with resubmit';
            options.widthPercentage = 25;
            options.animation = 'fadeInDown';
            this.popup.show(options);
            // window.scrollTo(0, 0);//enable
          }
        });
    }
    catch (err) {
      console.log("Error processing http put :" + err);
    }
  }

  checkMakeLink(value) {
    if (value.includes('ingest-') || value.includes('core-') || value.includes('transcode-')) {
      return true;
    }
    else {
      return false;
    }

  }

  
  getLink(value, version, region)
  {
    if (region === null || region === undefined){ region = 'us-east-1'}
    // item.fields.
    let link=environment.OperationRefUrl;
    if (value.includes('ingest-')) {

      link+= environment.ingest_stack.replace('{}', version) + ':'+ value;
    }
    else if (value.includes('core-')) {
      link+= environment.core_stack.replace('{}', version) + ":" + value;
    }
    else if (value.includes('transcode-')) {
      link+= environment.transcode_stack.replace('{}', version) + ":" + value;

    }
    var region_short = 'use1'
    if (region.includes('us-west-2')){
      region_short = 'usw2'
    }
    return link.replace(/{region-short}/g, region_short).replace(/{region}/g, region);
  }

// new histogram view calculations
  getHistogramItems(): void {
    if(this.showHistogram){

      const url = this.createHistogramRequest();

      // if (!url || url === '') {
      //   this.storedItems = [];
      //   return;
      // }
      //console.log(url);
      this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
        this.processHistogramSearchResults(JSON.stringify(data));
      });
    }
  }

  //histogram search query
  createHistogramRequest(): string {
      // base query for operations tab.
      let searchQuery = this.createSearchRequest();
      searchQuery = searchQuery.replace('&date_start=' + this.startDate + '&date_end=' + this.endDate, '&date_start=' + this.histogramStartDate + '&date_end=' + this.histogramStartDate);
      console.log("histogram: " + searchQuery);
      //TODO: Replace the limit value with a hardcoded value of 700 to be able to display all 24 hours of content
      searchQuery = searchQuery.replace('?limit=' + this.paging + '&', '?limit=700&');
      console.log("histogram: " + searchQuery);
      return searchQuery
  }

  //process histogram results
  processHistogramSearchResults(item: string): void {

    const results: SearchResult = JSON.parse(JSON.stringify(JSON.parse(item)));
    let storyCountData=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    let minutesCountData=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

    let seconds=[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      var eventHour = new Date(event.timestamp).getUTCHours();
        if((event.fields.publish_metadata_only && event.fields.publish_metadata_only.toString().toLowerCase()  !=='true') || (event.fields.fox_content_class != 'mimir')){
          let durationMin=0;
          if(event.fields.fox_content_class == 'mam' && event.fields.source_mam_duration){
            let duration=event.fields.source_mam_duration_string;
            let split=duration.split(':');
            durationMin= (parseInt(split[0]));
            seconds[eventHour]=seconds[eventHour]+(parseInt(split[1]));
          }
          else if(event.fields.mediainfo_audioDuration){
            let duration=this.getDuration(event.fields.mediainfo_audioDuration)
            let split=duration.split(':');
            durationMin= (parseInt(split[0])*60) +(parseInt(split[1]));
            seconds[eventHour]=seconds[eventHour]+(parseInt(split[2]));
          }
          else if(event.fields.mediainfo_videoDuration){
            let duration=this.getDuration(event.fields.mediainfo_videoDuration)
            let split=duration.split(':');
            durationMin= (parseInt(split[0])*60) +(parseInt(split[1]));
            seconds[eventHour]=seconds[eventHour]+(parseInt(split[2]));
          }
          minutesCountData[eventHour]=minutesCountData[eventHour]+durationMin;
        }

        storyCountData[eventHour]++;
    });

    let finalMinutesCountData=[];
      seconds.forEach((element,index) => {
        let minutes=element / 60;
        minutesCountData[index]=minutesCountData[index]+minutes;
      });

      minutesCountData.forEach((element,index) => {
        finalMinutesCountData[index]=parseFloat(element.toFixed(2));
      });
    let histogramData=[];
    if(this.histogramRadio=="MinutesOfVideo"){
      histogramData=finalMinutesCountData;
    }
    else{
      histogramData=storyCountData;
    }

    this.histogramTotalStories=0;
    this.histogramTotalMinutes=0;

    storyCountData.forEach(element => {
      this.histogramTotalStories=this.histogramTotalStories+element;
    });

    finalMinutesCountData.forEach(element => {
      this.histogramTotalMinutes=this.histogramTotalMinutes+element;
    });
    this.histogramTotalMinutes=parseFloat(this.histogramTotalMinutes.toFixed(2))

    console.log("Histogram data",histogramData);
    this.setHistogramData(histogramData);
  }



changeHistogramData(){
  this.getHistogramItems();
}

setHistogramData(data){
  let hoveraLabel="Story count";
  let color="#5470C6";
  if(this.histogramRadio=="MinutesOfVideo"){
    hoveraLabel="Minutes"
    color="#567A0D";
  }


  this.options=
  {
    responsive: true,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        label:'sasasa',
        type: 'category',
        data: ['0','1', '2', '3', '4', '5', '6', '7','8','9','10','11','12','13','14','15','16','17'
        ,'18','19','20','21','22','23'],
        axisTick: {
          alignWithLabel: true
        },
      }
    ],
    yAxis: [{
      type: 'value'
    }],
    series: [{
      name: hoveraLabel,
      type: 'bar',
      barWidth: '60%',
      data: data,
      color:color
    }]
  };

}
histogramRadioChange(value){
  this.histogramRadio=value;
  this.getHistogramItems();
}

displayJSON(index: number) {
  this.displayOneUpWithMessage(JSON.stringify(this.storedItems[index].rawEvent, null, 2),'Item Details', 75);
}

histogramBtn(){
  this.showHistogram=!this.showHistogram;
  this.getHistogramItems();
}

getFileLink(mimirId){
  return environment.ingestOriginalFileName+mimirId;
}

}
