<div class="ui top attached tabular menu">
  <a class="item" [class.active]="foxTabActive" data-tab="fox" (click)="setActive('editorial')" routerLink="/editorial">
    Editorial
  </a>
  <a class="item" [class.active]="youTubeTabActive" data-tab="youTube" (click)="setActive('youtube')" routerLink="/youtube">
    YouTube
  </a>
  <a class="item" [class.active]="opsTabActive" data-tab="ops" (click)="setActive('operations')" routerLink="/operations" >
    Operations
  </a>
  <!-- <a class="item" [class.active]="upcomingTabActive" data-tab="upcoming" (click)="setActive('upcoming')" routerLink="/upcoming">
    Upcoming
  </a> -->
  <a class="item" [class.active]="contributionsTabActive" data-tab="contributions" (click)="setActive('ingest')" routerLink="/ingest">
    Ingest
  </a>
  <a class="item" [class.active]="archiveorderfulfillmentTabActive" data-tab="archiveorderfulfillment" (click)="setActive('archiveorderfulfillment')" routerLink="/archiveorderfulfillment">
    Archive Orders
  </a>
  <a class="item" [class.active]="reportingTabActive" data-tab="reporting" (click)="setActive('reporting')" routerLink="/reporting">
    Reporting
  </a>
  <a class="item" [class.active]="restrictionsTabActive" data-tab="restrictions" (click)="setActive('restrictions')" routerLink="/restrictions">
    Restrictions
  </a>
  <a  class="item" [class.active]="configurationTabActive" data-tab="configuration" (click)="setActive('configuration')" routerLink="/configuration">
    Provider Settings
  </a>
  <!-- *ngIf="isRoleOperationsResubmit()" -->
</div>
<div class="ui bottom attached tab segment" [class.active]="foxTabActive" data-tab="fox">
  <app-foxeventlist [httpGetUri]="searchUri"></app-foxeventlist>
</div>
<div class="ui bottom attached tab segment" [class.active]="youTubeTabActive" data-tab="youTube">
  <app-youtubeeventlist [httpGetUri]="searchUri"></app-youtubeeventlist>
</div>
<div class="ui bottom attached tab segment" [class.active]="upcomingTabActive" data-tab="upcoming">
  <app-upcominglist [httpGetUri]="searchUri"></app-upcominglist>
</div>
<div class="ui bottom attached tab segment" [class.active]="opsTabActive" data-tab="ops">
  <app-operationslist [httpGetUri]="searchUri"></app-operationslist>
</div>
<div class="ui bottom attached tab segment" [class.active]="contributionsTabActive" data-tab="contributions">
  <app-contributionslist [httpGetUri]="searchUri"></app-contributionslist>
</div>

<div class="ui bottom attached tab segment" [class.active]="archiveorderfulfillmentTabActive" data-tab="archiveorderfulfillment">
  <app-archiveorderfulfillment [httpGetUri]="searchUri"></app-archiveorderfulfillment>
</div>
<div class="ui bottom attached tab segment" [class.active]="reportingTabActive" data-tab="reporting">
  <app-reporting [httpGetUri]="searchUri"></app-reporting>
</div>
<div class="ui bottom attached tab segment" [class.active]="restrictionsTabActive" data-tab="restrictions">
  <app-restrictions [httpGetUri]="searchUri"></app-restrictions>
</div>
<div class="ui bottom attached tab segment" [class.active]="configurationTabActive" data-tab="configuration">
  <app-configuration [httpGetUri]="searchUri"></app-configuration>
</div>
