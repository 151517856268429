import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { LoginService } from '../login/login.service';
import { Observable } from '../../../node_modules/rxjs/Observable';

import { GoogleAnalyticsEventsService } from "../app.google-analynics-events.service";
import { FoxEventStage } from "../foxeventobj/foxevent-stage";
import { FoxEventStep } from "../foxeventobj/foxevent-step";
import { FoxEventGroup } from "../foxeventobj/foxevent-group";
import { ActiveTabService } from '../service/active-tab.service';

@Component({
  selector: 'app-foxeventlist',
  templateUrl: './foxeventlist.component.html',
  styleUrls: ['./foxeventlist.component.css']
})
export class FoxeventlistComponent implements OnInit, OnDestroy {
  @ViewChild('eventPopup') popup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public filterCompleted = 'all';
  public oneUp: string;
  public isAllActive = true;
  public isProcessingActive = false;
  public isCompletedActive = false;
  public isFailedActive = false;
  public allowHeartbeat = false;
  public showArchiveContent = false;
  public viewAudio = true;
  public viewVideo = true;
  public showAPContentOnly = false;
  public allCount = 0;
  public completedCount = 0;
  public inProgressCount = 0;
  public failedCount = 0;
  public query = '';
  public completed = '';
  public processing = '';
  public failed = '';
  public all = '';
  private storedSearchQuery = '';
  private _subscription: Subscription;
  paging = 50;
  public errorMTop="0px";

  constructor(private http: HttpClient, private service: LoginService, private activeTabService: ActiveTabService) {
  }

  ngOnInit() {
    let localStoragePaging = localStorage.getItem("EditoPaging");
    if (!localStoragePaging || localStoragePaging == undefined) {
      localStorage.setItem("EditoPaging", this.paging.toString());
    }
    else {
      this.paging = parseInt(localStoragePaging);
    }

    this.activeTabService.activeTab.subscribe(data => {
      if (data == "editorial") {
        if(this.storedItems.length==0){
          this.getEventItems();
        }
      }
    })


    this._subscription = IntervalObservable.create(30000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "editorial") {
        this.getEventItems();
      }
    });
  }



  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getEventItems(): Observable<Object> {
    const url = this.createSearchRequest();
    if (!url || url === '') {
      this.storedItems = [];
      return;
    }
    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }

  createSearchRequest(): string {
    if (this.viewAudio === false && this.viewVideo === false) {
      return '';
    }
    let searchQuery = this.httpGetUri + 'limit=' + this.paging + '&query=';
    searchQuery = searchQuery + 'NOT source:planning AND NOT source:youtube AND NOT source:expedat AND NOT source:aspera AND fields.fox_orchestration_id:* AND NOT source:video_publish_event AND NOT fields.fox_content_class:archive_order_fulfillment NOT source:ingest_mam AND NOT fields.fox_content_class:bulkarchiveingest AND NOT source:mimir_story_publisher AND NOT source:transmitted';
    if (this.viewAudio === true && this.viewVideo === true) {
      searchQuery = searchQuery + '';
    } else if (this.viewAudio === true) {
      searchQuery = searchQuery + ' AND fields.mediainfo_sourceFormat:Audio';
    } else {
      searchQuery = searchQuery + ' AND fields.mediainfo_sourceFormat:Video';
    }

    if (this.isProcessingActive === true) {
      searchQuery = searchQuery + ' AND (groups.status:processing OR groups.status:pending)';
    } else if (this.isCompletedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:completed';
    } else if (this.isFailedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:failed';
    }

    if (this.allowHeartbeat === false) {
      searchQuery = searchQuery + ' AND NOT fields.provider_id:heartbeataudio AND NOT fields.title:heartbeatvideo AND NOT "Video Publish Heartbeat"';
    }

    if (this.showArchiveContent === false) {
      searchQuery = searchQuery + ' AND NOT fields.fox_content_class:bulkarchiveingest';
    }

    if (this.showAPContentOnly === true) {
      searchQuery = searchQuery + " AND (fields.provider_id:ap OR fields.provider_id:apus OR fields.provider_id:apaudio OR fields.provider_id:AP OR fields.provider_id:APUS OR fields.provider_id:u'ap' OR fields.provider_id:u'apus')";
    }
    // else {
    //   searchQuery = searchQuery + " AND NOT (fields.provider_id:ap OR fields.provider_id:apus OR fields.provider_id:apudio OR fields.provider_id:AP OR fields.provider_id:APUS OR fields.provider_id:u'ap' OR fields.provider_id:u'apus')";
    //   }

    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery;
    }

    // console.log(searchQuery);
    return searchQuery;
  }

  processSearchResults(item: string): void {
    this.storedItems = [];
    this.resetCounts();
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      switch (event.groups[0].status) {
        case 'completed':
          this.completedCount += 1;
          break;
        case 'processing':
          this.inProgressCount += 1;
          break;
        case 'failed':
          this.failedCount += 1;
          break;
      }
      this.allCount += 1;
      //this.storedItems.push(event);

      const itemsCondensed: FoxEvent = this.getCondensedEventDetails(event);

      if (itemsCondensed.groups[0].stages.length != 0) {
        this.storedItems.push(itemsCondensed);
      }
    });
  }
  getCondensedEventDetails(foxEvent): FoxEvent {
    const event: FoxEvent = new FoxEvent();
    const listOfStagePrefixes = [];
    event.fields = foxEvent.fields;
    event.id = foxEvent.id;
    event.rawEvent = foxEvent.rawEvent;
    event.source = foxEvent.source;
    event.timestamp = foxEvent.timeStamp;

    const grp: FoxEventGroup = new FoxEventGroup();
    grp.name = foxEvent.groups[0].name;
    grp.status = foxEvent.groups[0].status;
    grp.version = foxEvent.groups[0].version;
    grp.stages = foxEvent.groups[0].stages;

    event.groups = [];
    // Set three status - Submitted = Pending/Completed; Inprogress = Pending/Progressing/Completed; Complete = Pending/Completed
    // Submitted will always be "completed"
    // Inprogress will be whatever the Group Status is (which should NEVER be "completed" until ALL targets are "completed")
    // Complete will be "pending" until the Group Status is "completed"

    // if no fox_event_this then fox has not started processing and we are "submitted"
    if (event.fields.fox_event_this === "undefined") {
      grp.status = "pending";
    }
    else {
      // if we dont have any namepaces then we are not running on fox - set to pending (unless there was an error)
      if (event.fields.fox_target_namespaces === "undefined")
        if (grp.status != "failed") {
          grp.status = "pending";

        }
        else {// we are running on fox - check for targets and set the status appropriately
          const listOfStageStatus = [];
          const listOfFoxTargets = [];
          var targets = event.fields.fox_target_namespaces.split(',')
          for (const t of targets) {
            // we only care about fox targets (igm)
            if (t.includes('igm/')) {
              //track targets to make sure we get a status for each
              var suffix = t.replace('igm/', '-');
              listOfFoxTargets.push(suffix);
              //walk the stages to get their status when they match
              for (const stage of foxEvent.groups[foxEvent.groups.length - 1].stages.reverse()) {
                // if we have a match insert the status
                if (stage.name.endsWith(suffix))
                  listOfStageStatus.push(stage.status);
              }
            }
          }//each target
          // make sure the # of status == th enumber of targets
          if (listOfFoxTargets.length != listOfStageStatus.length) {
            grp.status = "processing";

          }

          //check status - if all complete then mark as complete
          var isComplete = true;
          for (const stageStats of listOfStageStatus) {
            // if failed then set as failed
            if (stageStats === "failed") {
              grp.status = "failed";

              isComplete = false;
              break;
            }
            else if (stageStats != "completed") {
              grp.status = "processing";

              isComplete = false;
            }
          }//each stage status
          if (isComplete === true)
            grp.status = "completed"

        }
    }
    event.groups.push(grp);

    return event;
  }

  resetCounts() {
    this.allCount = 0;
    this.completedCount = 0;
    this.inProgressCount = 0;
    this.failedCount = 0;
  }

  getGroupStepStatus(index: number): String[][] {
    const messages = [];
    for (const stage of this.storedItems[index].groups[0].stages) {
      if (stage.name.split('-')[0] != 'core')
        continue
      var stagename = 'NEWSROOM'
      if (stage.name.split('-').length == 3)
        if (stage.name.split('-')[2] !== 'videohub')
          stagename = stage.name.split('-')[2]
      messages.push({ stage: stagename, status: stage.status });
    }
    return messages.reverse();
  }

  getGroupErrorMessage(index: number): string {
    for (const stage of this.storedItems[index].groups[0].stages) {
      if (stage.name.startsWith('core') || stage.name.startsWith('ingest')) {
        for (const step of stage.steps) {
          if (step.status === 'failed') {
            return 'Step: ' + step.name + ' Details: ' + step.message;
          }
        }
      }
    }
    return '';
  }

  getEndTime(index: number): number {
    let endDate = 0;
    for (const stage of this.storedItems[index].groups[0].stages) {
      if (stage.name.startsWith('core') || stage.name.startsWith('ingest')) {
        for (const step of stage.steps) {
          if (step.end !== '' && new Date(step.end).getTime() > endDate) {
            endDate = new Date(step.end).getTime();
          }
        }
      }
    }
    return endDate;
  }

  getStartTime(index: number): number {
    let startDate = 0;
    if (this.storedItems[index].fields.fox_content_class == 'enps' || this.storedItems[index].fields.fox_content_class == 'mimimr') {
      for (const stage of this.storedItems[index].groups[0].stages) {
        if (stage.name === 'upload') {
          for (const step of stage.steps) {
            if (step.name === 'metadata_event_store') {
              startDate = new Date(step.start).getTime();
              break;
            }
          }
        }
      }
    }
    if (startDate === 0) {
      for (const stage of this.storedItems[index].groups[0].stages) {
        if (stage.name.startsWith('core') || stage.name.startsWith('ingest') || stage.name === 'upload') {
          startDate = new Date(stage.start).getTime();
          break;
        }
      }
    }
    return startDate;
  }

  getTimeToMarket(index: number): string {
    let result = '';
    const endDate: number = this.getEndTime(index);
    const startDate: number = this.getStartTime(index);
    if (endDate === 0 || startDate === 0) {
      return '';
    }
    const difference: number = endDate - startDate;
    const diffHours = difference / 3600000;
    const absoluteHours = Math.floor(diffHours);
    if (absoluteHours > 0) {
      const hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
      result = hours + ' hrs ';
    }
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    if (absoluteMinutes > 0) {
      const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
      result = result + mins + ' mins';
    }
    if (absoluteHours < 1) {
      const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
      const absoluteSeconds = Math.floor(diffSeconds);
      if (absoluteSeconds > 0) {
        const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
        result = result + ' ' + secs + ' secs';
      }
    }
    if (result === '') {
      result = '0 mins 0 secs';
    }
    return result;
  }


  getDuration(duration: any): string {
    const diffHours = duration / 3600000;
    const absoluteHours = Math.floor(diffHours);
    const hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
    const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
    const absoluteSeconds = Math.floor(diffSeconds);
    const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
    return hours + ':' + mins + ':' + secs;
  }

  onFilterChange(filter: string): void {
    switch (filter) {
      case 'all':
        this.isAllActive = true;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'processing':
        this.isAllActive = false;
        this.isProcessingActive = true;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'completed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = true;
        this.isFailedActive = false;
        break;
      case 'failed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = true;
        break;
    }
    this.filterCompleted = filter;
    this.getEventItems();
  }

  APOnlyChanged() {
    this.showAPContentOnly = !this.showAPContentOnly;
    this.onFilterChange(this.filterCompleted);
  }

  heartbeatChanged() {
    this.allowHeartbeat = !this.allowHeartbeat;
    this.onFilterChange(this.filterCompleted);
  }

  audioChanged() {
    this.viewAudio = !this.viewAudio;
    this.onFilterChange(this.filterCompleted);
  }

  videoChanged() {
    this.viewVideo = !this.viewVideo;
    this.onFilterChange(this.filterCompleted);
  }

  archiveFilterChanged() {
    this.showArchiveContent = !this.showArchiveContent;
    this.onFilterChange(this.filterCompleted);
  }

  copyErrorToClipboard(index: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.getGroupErrorMessage(index);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  displayOneUp(index: number) {
    this.errorMTop=(window.scrollY+60)+"px";
    this.oneUp = JSON.stringify(this.storedItems[index].rawEvent, null, 2);
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = 'Item Details';
    options.widthPercentage = 75;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    // window.scrollTo(0, 0);
  }

  hideOneUp() {
    this.popup.hide();
  }

  submit(search: string) {
    this.storedSearchQuery = search;
    this.getEventItems();
  }

  onPagingSelectChange() {
    localStorage.setItem("EditoPaging", this.paging.toString());
    this.getEventItems();
  }
}
