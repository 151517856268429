<!-- <script src="../../../../../../../../Users/rregan/Downloads/expedat_client-win64-1.18B-P/ExpeDat/Web/EXP.js"></script> -->

<app-edit-configuration class="editPopup" #configurationPopup (confirmClick)="confirmClick()" (cancelClick)="hideOneUp()"   [ngStyle]="{'margin-top': editConfMTop}" >
 
</app-edit-configuration>

<div id="divDashboard">
  <div class="row no-gutter">
    <form class="filter-form">
      <div class="filter-block left-float">
      
        <label class="radio-inline link processing" [class.active]="activeRecord">
  
          <input (click)="activeToggleChange()" type="radio" class="form-check-input" id="activeRecord" 
          name="activeRecord"
          [checked]="activeRecord">Active
          <span *ngIf="activeCount != ''"> ({{activeCount}})</span>
  
        </label>
  
        <label class="radio-inline link failed" [class.active]="!activeRecord">
  
          <input (click)="activeToggleChange()" type="radio" class="form-check-input" id="activeRecord" 
          name="activeRecord"
          [checked]="!activeRecord">Inactive 
          <span *ngIf="inActiveCount != ''"> ({{inActiveCount}})</span>
  
        </label>
      </div>
    </form>
  </div>

  <div class="row no-gutter">
      <table class="table table-striped">
          <thead >
              <tr >
                  <th *ngIf="isAuthorizedToEdit"  style="width: 3%"><span></span></th>
                  <th style="width: 7%"><span>Partner Name</span></th>
                  <th style="width: 7%" ><p><span>Partner Id</span></p></th>
                  <th style="width: 7%" ><p><span>Last Modified</span></p></th>
                  <th style="width: 7%" ><span>Slate</span></th>
                  <th style="width: 7%" ><p><span>Slate Jpeg</span></p></th>
                  <th style="width: 7%" ><p><span>Slate Disclaimer</span></p></th>
                  <th style="width: 7%" ><p><span>Teletrax</span></p></th>
                  <th style="width: 7%" ><p><span>Origin Video</span></p></th>
                  <th style="width: 7%" ><p><span>Max Duration</span></p></th>
                  <th style="width: 7%" ><p><span>Expiration</span></p></th>
                  <th style="width: 7%" ><p><span>CR/NR</span></p></th>
                  <th style="width: 7%" ><p><span>Search Jpeg Offset</span></p></th>
                  <th style="width: 7%" ><p><span>Story# prefix</span></p></th>
                  <th style="width: 7%" ><p><span>Story# pad length</span></p></th>
                  <th style="width: 7%" ><p><span>Test Mode</span></p></th>
                  <th style="width: 7%" ><p><span>Default Category (Audience classification)</span></p></th>
                  <th style="width: 7%" ><p><span>Targets</span></p></th>
              </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of configurations; let i = index">
              <td *ngIf="isAuthorizedToEdit" >
                <i class="ico-pencil-square " (click)="editConfiguration(item)"></i>
              </td>
              <td>
                {{item.config.provider_name}}
              </td>
              <td>
                {{item.provider_id}}
              </td>
              <td>
                {{item.last_modified_by}} {{item.last_modified_by!=null? "on":""}} {{item.last_modified_dt | dateFormate}}
              </td>
              <td>
                
              </td>
              <td>
                
              </td>
              <td>
                
              </td>
              <td>
                <input onclick="return false;" type="checkbox" class="form-check-input" id="teletrax" 
                [(ngModel)]="item.config.teletrax" name="teletrax">
              </td>
              <td>
                <input onclick="return false;" type="checkbox" class="form-check-input" id="origin_format" 
                [(ngModel)]="item.config.origin_format" name="origin_format">
              </td>
              <td>
                
              </td>
              <td>
                {{item.config.content_expiration}}
              </td>
              <td>
                {{item.config.default_content_type==1?'NR':'CR'}}
              </td>
              <td>
                {{item.config.thumb_offset}}
              </td>
              <td>
                {{item.config.prefix}}
              </td>
              <td>
                {{item.config.pad_length}}
              </td>
              <td>
                {{item.config.test_mode}}
              </td>
              <td>
                
              </td>
              <td>
                {{getTargets(item.config.targets)}}
              </td>

             


            </tr>

          </tbody>
      </table>
  </div>
</div>
