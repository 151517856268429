// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  version:'1.8.2',
  production: false,
  envName: 'Dev',
  SearchQuery: 'https://fox.aptechdevlab.com/dashboard/latest/search?',
  EcrUrl: 'http://catalogapiqa.ap.org/AP.MessageRepository.APIHost/Services/MessageRepository.svc/documents/',
  resubmitFailuresUri:'https://fox.aptechdevlab.com/core/latest/management/resubmit/sfn/',
  resubmitFailuresUriUsw2:'https://fox-usw2.aptechdevlab.com/core/latest/management/resubmit/sfn/',
  WebFeedsUrl:'http://proteus-qa-uno-esclient-us-east-1.aptechlab.com:9200/appl/_search?q=itemid:itemValue&track_total_hits=true',
  AllowUploads: true,
  ExpedatUseApi: true,
  ExepdatVersion: '1190001,1190000',
  ExpedatServer: '10.16.13.209',
  ExpedatPort: '8080',
  ExpedatUser: 'writeonly',
  ExpedatPass: 'apvid',
  ExpedatHandler: 'S3',
  ExpedatHomeFolder: '/field',
  ExpedateturnUrl: 'http://data.ryan.dashboard.dev.s3-website-us-east-1.amazonaws.com/contributions',
  LoginUrl: 'https://ap-nexus-dev.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=59gsu66b4i5fifeepnjd9hvmkp&scope=openid&redirect_uri=https%3A%2F%2Fapnexus.aptechdevlab.com/',
  GetTokenUrl: 'https://ap-nexus-dev.auth.us-east-1.amazoncognito.com/oauth2/token',
  GetTokenClientId: '59gsu66b4i5fifeepnjd9hvmkp',
  GetTokenRedirectUri: 'https://apnexus.aptechdevlab.com/',
  LogoutUrl: 'https://ap-nexus-dev.auth.us-east-1.amazoncognito.com/logout?client_id=59gsu66b4i5fifeepnjd9hvmkp&logout_uri=https%3A%2F%2Fapnexus.aptechdevlab.com/',
  AuthorizedGroup: 'us-east-1_gv6twAXpk_AP-Azure-AD',
  RoleSupport: 'SupportDev',
  RoleAdmin: 'AdminDev',
  RoleMCR: 'MCRDev',
  RoleEditor: 'EditorDev',
  RoleProviderManagement:'ProviderManagementDev',
  RoleDebug: '[MCRDev, SupportDev, EditorDev]',
  debug: false,
  OperationRefUrl:'https://{region}.console.aws.amazon.com/states/home?region={region}#/v2/executions/details/arn:aws:states:{region}:222259241209:execution:',
  expedat : {
    'default' : {
      'server' : '10.16.13.209',
      'port' : '8080',
      'handler' : 'S3'
    },
    'ap-qa' : {
      'server' : '10.16.13.209',
      'port' : '8080',
      'handler' : 'S3',
      'user' : 'readonly',
      'pass' : 'apvid'
    }
  },
  googleAnalynicsId:'UA-27242214-6',
  adminUsers:'RDodge;Rregan',
  systemWideNote:'',
  expedat_url_win32: 'https://s3.amazonaws.com/data.ryan.dashboard/downloads/expedat_client-win32-1.18Fb1-T.zip',
  expedat_url_win64: 'https://s3.amazonaws.com/data.ryan.dashboard/downloads/AP_Expedat_Win64_119.zip',
  expedat_url_mac: 'https://s3.amazonaws.com/data.ryan.dashboard/downloads/AP_Expedat_OSX_119.zip',
  RestrictionServiceUrl: 'https://mos.aptechdevlab.com/restrictions',
  ingestOriginalFileName:"https://staging.mimir.tv/item-details/",
  downloadIngestFile:"expedat://54.77.15.94:8080/mimirId_fileName?a=g&u=readonly&p=apvid&l=1&h=NG",
  configurationBaseURL:"https://videoconfigmgmt.aptechdevlab.com",
  ingest_stack:"fox-ingest-dev-{region-short}-state-machine",
  core_stack:"fox-core-dev-{region-short}-state-machine",
  transcode_stack:"transcode-dev-apcapdev-{region-short}-static-stack"
};
