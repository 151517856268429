import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EditConfigurationComponent } from '../popup/configuration/edit-configuration/edit-configuration.component';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import { environment } from '../../environments/environment';
import { ProviderConfig } from './models/configuration/configuration';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  @ViewChild('configurationPopup') popupConfiguration: EditConfigurationComponent;
  @Input() httpGetUri: string;
  constructor(private http: HttpClient, private service: LoginService) { }

  public actualConfigurations: ProviderConfig[] = [];
  public configurations: ProviderConfig[] = [];
  public editConfMTop = "0px";
  public isAuthorizedToEdit = false;
  public activeRecord = true;
  public activeCount = '';
  public inActiveCount = '';

  ngOnInit(): void {
    this.getConfigurationItems();
    this.isAuthorizedToEdit = this.canProviderManagement();
  }

  activeToggleChange() {
    this.activeRecord = !this.activeRecord;
    this.assignConfiguration();
  }

  canProviderManagement(): boolean {
    return true;
    const authRulesValue = localStorage.getItem("authorizedRoles")
    if (authRulesValue === null) {
      return false;
    }
    const authRoles = JSON.parse(localStorage.getItem("authorizedRoles"));
    if (authRoles === null) {
      return false;
    }
    if (authRoles["AuthorizedRoles"].includes("ProviderManagement" + environment.envName))
      return true;
    else
      return false;
  }

  getConfigurationItems() {
    const url = environment.configurationBaseURL + "/providers";
    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.actualConfigurations = data as ProviderConfig[];
      this.activeCount = this.actualConfigurations.filter(function (element) {
        return element.config.provider_ebiz_status == 1;
      }).length.toString();

      this.inActiveCount = this.actualConfigurations.filter(function (element) {
        return element.config.provider_ebiz_status != 1;
      }).length.toString();

      this.assignConfiguration();
    });
  }

  assignConfiguration() {
    if (this.activeRecord) {
      this.configurations = this.actualConfigurations.filter(x => x.config.provider_ebiz_status == 1)
    }
    else {
      this.configurations = this.actualConfigurations.filter(x => x.config.provider_ebiz_status != 1)
    }

    // console.log("this.configurations",this.configurations);
    this.configurations = this.configurations.sort((a, b) => (a.config.provider_name.toLowerCase() > b.config.provider_name.toLowerCase()) ? 1 : ((b.config.provider_name.toLowerCase() > a.config.provider_name.toLowerCase()) ? -1 : 0))
    this.configurations.forEach((config) => {
      if (config.config.package_ready_events.includes("encodation")) {
        config.config.teletrax = true;
      }
      else {
        config.config.teletrax = false;
      }

      if (config.config.provider_ebiz_status && config.config.provider_ebiz_status == 1) {
        config.config.active = true;
      }
      else {
        config.config.active = false;
      }

    })
  }

  hideOneUp() {
    this.popupConfiguration.hide();
  }

  confirmClick() {
    this.popupConfiguration.hide();
    this.getConfigurationItems();
  }

  editConfiguration(obj) {
    this.editConfMTop = (window.scrollY) + "px";
    this.popupConfiguration.show(obj);
  }

  getTargets(target) {

    if (target && target != "") {
      if (target == "videohub_archive") {
        return "VH, Archive";
      }
      else if (target == "videohub") {
        return "VH";
      }
      else if (target == "archive") {
        return "Archive";
      }
    }

  }

}
