import { RestrictionFields } from './restriction-fields';

export class RestrictionItem {
    public FieldKeyName: string;
    public FieldKeyValue: string;
    public Description: string;
    public LastUpdated: string;
    public Fields: RestrictionFields[];
    public userId: string;
}
