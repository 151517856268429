import { FoxEventStep } from './foxevent-step';

export class FoxEventStage {
    public name: string;
    public start: string;
    public end: string;
    public status: string;
    public steps: FoxEventStep[];
    public stageLabel?: string;
}
