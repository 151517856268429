<app-popup #operationsPopup (cancelClick)="hideOneUp()" [ngStyle]="{'margin-top': errorMTop}">
  <pre class="preStyle">{{ oneUp }}</pre>
</app-popup>


<div class="row no-gutter pt-col pb-col">
  <div class="col-md-12">
    <button (click)="histogramBtn()">
      <span>{{!showHistogram ? 'Show' : 'Hide'}} Histogram</span>
    </button>
  </div>
  <div *ngIf="showHistogram" class="col-md-12">
    <div class="d-datefilter-inline justify-content-start align-items-center no-text-wrap pt-col pb-col">
      <div class="form-group mb-0">
        <label class="control-label pr-5px">Histogram date:</label>
        <input type="date" id="histogram_start" name="histogram_start" class="form-control"
          (input)="histogramStartDate = $event.target.value" [value]="histogramStartDate"
          (change)="changeHistogramData()">
      </div>

      <div class="filter-block">
        <label class="radio-inline" [class.active]="isAllActive">
          <input type="radio" name="histogramType" [value]="histogramRadio"
            (change)="histogramRadioChange('StoryCount')" checked="checked">Story count
        </label>
        <label class="radio-inline " [class.active]="isCompletedActive">
          <input type="radio" name="histogramType" [value]="histogramRadio"
            (change)="histogramRadioChange('MinutesOfVideo')">Minutes of video
        </label>
      </div>
    </div>


    <div class="" style="max-width: 1200px;">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td style="width: 10%;" class="storyCount"> Story Count: </td>
            <th style="width: 10%;" class="storyCount">{{histogramTotalStories}}</th>
          </tr>
          <tr>
            <td style="width: 10%;" class="mintCount"> Video Minutes: </td>
            <th style="width: 70%;" class="mintCount">{{histogramTotalMinutes}}</th>
          </tr>
        </tbody>
      </table>
      <!-- <div class="storyCount">
        <span></span>
        <strong></strong>
      </div>
      <div class="mintCount">
        <span> </span>
        <strong></strong>
      </div> -->
      <div>
        <echarts [initOpts]="initOpts" [options]="options" (chartClick)="onBarChartEvent($event)"></echarts>
      </div>
    </div>
  </div>
</div>

<div id="divDashboard" *ngIf="canViewPage()">
  <div class="row no-gutter">
    <form class="filter-form col-md-12">
      <div class="media-block">
        <a class="media-filter ico-audio" [class.active]="viewAudio" href="#" title="Display Audio"><input
            type="checkbox" name="audioFilter" checked="checked" (change)="audioChanged()" /></a>
        <a class="media-filter ico-video" [class.active]="viewVideo" href="#" title="Display Video"><input
            type="checkbox" name="videoFilter" checked="checked" (change)="videoChanged()" /></a>
        <a class="media-filter ico-ap" [class.active]="showAPContentOnly" href="#"
          title="Display AP Only Content"><input type="checkbox" name="apOnlyFilter" (change)="APOnlyChanged()" /></a>
        <!-- <a class="media-filter ico-eye-slash" [class.active]="showPreviewFirst" href="#" title="Show Preview First"><input type="checkbox" name="previewFirstFilter" (change)="showPreviewFirstClick()" /></a> -->
        <a class="media-filter ico-heartbeat" [class.active]="allowHeartbeat" href="#" title="Display Heartbeats"><input
            type="checkbox" name="heartbeatFilter" (change)="heartbeatChanged()" /></a>
        <!-- <a class="media-filter ico-upload" [class.active]="showMamEvents" href="#" title="Show Video Ingest"><input type="checkbox" name="uploadFilter" (change)="showMamEventsClick()" /></a>
         <a class="media-filter ico-download" [class.active]="showArchiveOrderEvents" href="#" title="Show Archive Orders"><input type="checkbox" name="archiveOrderFilter" (change)="showArchiveOrdersClick()" /></a> -->
        <a class="media-filter ico-folder-o" [class.active]="showArchiveContent" href="#"
          title="Show Bulk Archive"><input type="checkbox" name="archiveFilter" (change)="archiveFilterChanged()" /></a>
      </div>
      <div class="media-block">
        <input type="text" class="search-input" #searchquery [value]="query" (keydown.enter)="submit(searchquery.value)"
          (keydown.shift.control.x)="showResubmitButton()" placeholder="Start searching..."><button
          (click)="submit(searchquery.value)" class="search-button"><i class="ico-search"></i></button>
      </div>
      <div class="media-block">
        <div class="d-datefilter-inline">

          <div class="form-group mb-0">
            <label class="control-label pr-5px">Start:</label>
            <input class="form-control" type="date" id="date_start" name="date_start"
              (input)="startDate = $event.target.value" [value]="startDate" (change)="submit(searchquery.value)">

          </div>
          <div class="form-group mb-0 ml-10px">
            <label class="control-label pr-5px">End:</label>
            <input class="form-control" type="date" id="date_end" name="date_end"
              (input)="endDate = $event.target.value" [value]="endDate" (change)="submit(searchquery.value)">
          </div>

          <div class="form-group mb-0 ml-10px">
            <select name="regions" class="form-control" [(ngModel)]="regions" (change)="onPagingSelectChange()">
              <option value="all">all</option>
              <option value="us-east-1">us-east-1</option>
              <option value="us-west-2">us-west-2</option>
            </select>
          </div>
          <div class="form-group mb-0 ml-10px">
            <select name="paging" class="form-control" [(ngModel)]="paging" (change)="onPagingSelectChange()">
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="250">250</option>
            </select>
          </div>
        </div>
      </div>
      <div class="filter-block">
        <label class="radio-inline link" [class.active]="isAllActive">
          <input type="radio" name="filter1" [value]="all" (change)="onFilterChange('all')" checked="checked">All
          Results<span *ngIf="allCount > 0"> ({{ allCount }})</span>
        </label>
        <label class="radio-inline link completed" [class.active]="isCompletedActive">
          <input type="radio" name="filter1" [value]="completed" (change)="onFilterChange('completed')">Completed<span
            *ngIf="completedCount > 0"> ({{ completedCount }})</span>
        </label>
        <label class="radio-inline link processing" [class.active]="isProcessingActive">
          <input type="radio" name="filter1" [value]="processing"
            (change)="onFilterChange('processing')">Processing<span *ngIf="inProgressCount > 0"> ({{ inProgressCount
            }})</span>
        </label>
        <label class="radio-inline link failed" [class.active]="isFailedActive">
          <input type="radio" name="filter1" [value]="failed" (change)="onFilterChange('failed')">Failed<span
            *ngIf="failedCount > 0"> ({{ failedCount }})</span>
        </label>
      </div>
    </form>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th style="width: 5%"><span>&nbsp;</span></th>
        <th style="width: 9%; text-align: center" colspan="1">Media</th>
        <th style="width: 20%;  text-align: center"><span>Story</span></th>
        <th style="width: 15%; text-align: center"><span>Age</span></th>
        <th style="width: 20%; text-align: center"><span>Details</span></th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}"
        [ngStyle]="{'background-color': item.fields.isKilled && item.fields.isKilled.toString().toLowerCase()  === 'true' ? 'darkred' : 'nth-child(even) {background-color: #f2f2f2;}', 'color': item.fields.isKilled && item.fields.isKilled.toString().toLowerCase()  === 'true' ? 'white' : 'black'}">
        <td>
          <div class="media-block">
            <i class="ico-file" (click)="displayJSON(i)"></i>
          </div>
        </td>
        <!-- <td>

              <div *ngFor="let stage of item.groups[0].stages" class="table table-striped" border=0>
                <div *ngFor="let step of stage.steps; let istep = index; let iCount = count">
                     <div *ngIf="step.isLatestStep" >
                       <div class="media-block">
                         <span class="indicator {{step.status}}" title="{{stage.stageLabel}} - {{step.name}}"
                          (click)="copyErrorToClipboard(i)"></span>
                          <div class="ico-{{item.fields.mediainfo_sourceFormat | lowercase}}"></div>
                      </div>
                    </div>
                </div>
            </div>
          </td> -->
        <td>
          <img class="thumb" src="{{item.fields.source_originalThumbUrl}}" onerror="this.src='assets/img/pna.png';"
            *ngIf="item.fields.mediainfo_sourceFormat  !== 'Audio' && item.fields.source_thumbnailUrl == undefined" />
          <img class="thumb" src="{{item.fields.source_thumbnailUrl}}" onerror="this.src='assets/img/pna.png';"
            *ngIf="item.fields.mediainfo_sourceFormat  !== 'Audio' && item.fields.source_thumbnailUrl != undefined" />
          <img class="thumb-audio" src="../../assets/img/AudioThumb.png"
            *ngIf="item.fields.mediainfo_sourceFormat  === 'Audio'" />
        </td>
        <td>
          <div>
            <img src="../../assets/img/priority.png" alt="Priority One" class="priority"
              *ngIf="item.fields.priority == 1" />
            <span
              *ngIf="item.fields.publish_metadata_only && item.fields.publish_metadata_only.toString().toLowerCase()  === 'false'"
              class="ico-video" style="font-size: 3rem;vertical-align: middle"></span>
            <img src="../../assets/img/restore.jpg" height="20px" alt="Archive Restore"
              *ngIf="item.fields.fox_content_class === 'archiverestore' || item.fields.fox_content_class === 'digitize'" />
            <img src="../../assets/img/aspera-icon.jpg" height="20px" alt="Aspera"
              *ngIf="item.fields.fox_content_class === 'aspera'" /> <img src="../../assets/img/rss.png" height="20px"
              alt="MRSS" *ngIf="item.fields.fox_content_class === 'mrss'" /> <img src="../../assets/img/upload-tool.png"
              height="20px" alt="Upload Tool" *ngIf="item.fields.fox_content_class === 'vut'" /> <img
              src="../../assets/img/archive-order-icon.png" height="20px" alt="Archive Order"
              *ngIf="item.fields.fox_content_class === 'archive_order_fulfillment'" />
            <img src="../../assets/img/expedat-icon.png" height="20px" alt="Expedat"
                *ngIf="item.fields.ingest_deliveryType === 'expedat'" />
            <span class="id"><span *ngIf="item.fields.fox_content_class === 'vut' || item.fields.fox_content_class === 'mrss' || item.fields.ingest_deliveryType === 'expedat' || item.fields.publish_metadata_only && item.fields.publish_metadata_only.toString().toLowerCase()  === 'false'">&nbsp;</span>{{item.fields.title || '--- No Slug Title ---'}}</span>

            <br />
            <div
              *ngIf="(item.fields.publish_metadata_only && item.fields.publish_metadata_only.toString().toLowerCase()  !=='true') || (item.fields.fox_content_class != 'mimir')">
              <p *ngIf="item.fields.fox_content_class == 'mam' && item.fields.source_mam_duration">
                Duration:({{item.fields.source_mam_duration_string}})
              </p>
              <p *ngIf="item.fields.mediainfo_audioDuration">
                Duration: ({{
                getDuration(item.fields.mediainfo_audioDuration)
                }})
              </p>
              <p *ngIf="item.fields.mediainfo_videoDuration">
                Duration: ({{
                getDuration(item.fields.mediainfo_videoDuration)
                }})
              </p>
              <p *ngIf="item.fields.mediainfo_videoFramRate">
                FrameRate: {{
                item.fields.mediainfo_videoFramRate
                }}
              </p>
              <p *ngIf="item.fields.fox_content_class == 'mam' && item.fields.source_mam_framerate">
                FrameRate: {{item.fields.source_mam_framerate}}
              </p>
            </div>
            <p *ngIf="item.fields.mediainfo_videoWidth && item.fields.mediainfo_videoHeight">width x height:
              {{item.fields.mediainfo_videoWidth + " x " + item.fields.mediainfo_videoHeight}}
            </p>
            <p *ngIf="item.fields.metadataVersion">
              Version (RSN): {{item.fields.metadataVersion}}
            </p>

          </div>
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let stage of item.groups[0].stages">
                <div *ngFor="let step of stage.steps; let istep = index; let iCount = count">
                  <div *ngIf="(step.status==='failed' || step.status === 'processing') && step.isLatestStep">
                    <span class="indicator {{step.status}}" (click)="copyErrorToClipboard(i)"></span>
                    <!-- title="{{ step.message}}" -->
                    <span class="animated pulse">
                      {{ stage.stageLabel}} - {{ step.name}}
                    </span>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <p *ngIf="getStartTime(i)!=0">
            <app-timer [time]="getStartTime(i)"></app-timer>
            ago
          </p>
          <p *ngIf="item.groups[0].status === 'completed'">({{ getTimeToMarket(i) }})</p>
          <i id="start - {i}" class="sub-info-cell-d"
            *ngIf="item.groups[0].stages[0].length != 0 && item.groups[0].stages[0].steps.length != 0">{{item.groups[0].stages[0].steps[0].start
            | dateFormate}}</i>

        </td>
        <td>
          <p *ngIf="item.fields.provider_id">Provider Id: {{item.fields.provider_id | uppercase }}</p>
          <p *ngIf="item.fields.content_provider_storyNumber">
            Story Number:
            {{item.fields.content_provider_storyNumber }}
          </p>
          <p *ngIf="item.fields.content_provider_newsroom_itemId && hasTarget(i, 'newsroom')">
            Newsroom: <a href="{{ getEcrUrl(item.fields.content_provider_newsroom_itemId) }}" target="_blank"
              rel="noopener noreferrer">{{item.fields.content_provider_newsroom_itemId}}</a> - <a
              [href]="getWebFeedsUrl(item.fields.content_provider_newsroom_itemId)" target="_blank"
              rel="noopener noreferrer">Search</a>
          </p>
          <p *ngIf="item.fields.content_provider_online_itemId && hasTarget(i, 'apvideous')">
            AP Video US: <a href="{{ getEcrUrl(item.fields.content_provider_online_itemId) }}" target="_blank"
              rel="noopener noreferrer">{{item.fields.content_provider_online_itemId}}</a> - <a
              [href]="getWebFeedsUrl(item.fields.content_provider_online_itemId)" target="_blank"
              rel="noopener noreferrer">Search</a>
          </p>
          <p *ngIf="item.fields.content_provider_archive_itemId">
            APPL ECR: <a href="{{ getEcrUrl(item.fields.content_provider_archive_itemId) }}" target="_blank"
              rel="noopener noreferrer">{{item.fields.content_provider_archive_itemId}}</a>
          </p>
          <p *ngIf="item.fields.content_provider_archive_itemId">
            Elastic: <a [href]="getWebFeedsUrl(item.fields.content_provider_archive_itemId)" target="_blank"
              rel="noopener noreferrer">{{item.fields.content_provider_archive_itemId}}</a>
          </p>
          <p *ngIf="item.fields.archiveorder_itemId">
            Archive: <a href="{{ getEcrUrl(item.fields.archiveorder_itemId) }}" target="_blank"
              rel="noopener noreferrer">{{item.fields.archiveorder_itemId}}</a>
          </p>
          <p
            *ngIf="item.fields.source_originalUrl && item.fields.source_originalUrl.startsWith('http') && item.fields.fox_content_class == 'mrss'">
            Source: <a [href]="sanitizeUrl(item.fields.source_originalUrl)" target="_blank" rel="noopener noreferrer"
              routerLink="/operations">download</a>
          </p>
          <p *ngIf="item.fields.fox_content_class == 'mam'">
            Video Server: {{item.fields.source_mam_location}}
          </p>
          <p *ngIf="item.fields.mimir_id">
            <span>Mimir: </span><a [href]="getFileLink(item.fields.mimir_id)" target="_blank"
              rel="noopener noreferrer">{{item.fields.mimir_id}}</a>
          </p>
          <p *ngIf="item.fields.fox_processing_region">
            <span>AWS Region: </span>{{item.fields.fox_processing_region}}
          </p>
        </td>
        <td>
          <div>
            <ul>
              <div *ngFor="let stage of item.groups[0].stages">

                <li *ngFor="let step of stage.steps; let istep = index; let iCount = count">
                  <div *ngIf="step.isLatestStep">
                    <span class="indicator {{step.status}}" title="{{ step.message}}"
                      (click)="copyErrorToClipboard(i)"></span>&nbsp; <span
                      [className]="step.isLatestStep === true ? 'bold' : 'disabled'">{{stage.stageLabel}}</span><span
                      *ngIf="step.status==='failed'"> - {{step.name}}</span> <span
                      *ngIf="item.source != 'aspera' && step.displayResubmit === true && item.fields.fox_content_class !== 'mam' "
                      style="height:18px" (click)="resubmit(stage.name,item.fields.fox_processing_region,i)" id="resubmit-link-nonerror"
                      [className]="disableResubmit.includes(item.id) && step.status==='failed'? 'adisabled' : 'link'"
                      routerLink="/operations">
                      <img
                        *ngIf="canResubmit() && item.source != 'aspera' && step.displayResubmit === true && item.fields.fox_content_class !== 'mam'"
                        src="../../assets/img/resubmit.png" style="height:18px;cursor:pointer;"
                        [className]="item.resubmitted ? 'adisabled' : 'inactive'" alt="Resubmit"
                        id="resubmit-img-nonerror-{{i}}" />&nbsp;Resubmit</span>
                    <br />

                    <span *ngIf="checkMakeLink(stage.name)"><span><b>Core Stack:&nbsp;</b> </span><a
                        [href]="getLink(stage.name, item.fields.fox_core_stack_version, item.fields.fox_processing_region)" target="_blank"
                        rel="noopener noreferrer">{{stage.name}}</a>
                      <span
                        *ngIf="((item.fields.fox_transcodearchive === 'true' || item.fields.mimir_thumbnailUpdated === 'true' || item.fields.mediainfo_sourceFormat != undefined) && stage.name.startsWith('core-')) || (item.fields.fox_transcodearchive === 'false' && stage.name.startsWith('core-') && stage.name.endsWith('-archive') === false)"><br /><span><b>Transcode
                            Stack:&nbsp;</b> </span><a
                          [href]="getLink(stage.name.replace('core-', 'transcode-'), item.fields.fox_core_stack_version, item.fields.fox_processing_region)"
                          target="_blank" rel="noopener noreferrer">{{stage.name.replace('core-',
                          'transcode-')}}</a></span></span>
                    <span *ngIf="!checkMakeLink(stage.name)"><span><b>Reference:&nbsp;</b>
                      </span>{{stage.name}}</span>
                    <!-- <span></span>
                            {{checkMakeLink(stage.name) == true ?
                              getLink(stage.name) :
                               checkMakeLink(stage.name)}} -->
                    <br /><span><b>Start:&nbsp;</b> </span>{{step.start |
                    dateFormate}}<span>&nbsp;&nbsp;<b>End:&nbsp;</b>
                    </span>{{step.end | dateFormate}}
                    <span *ngIf="step.message"><br /><b>Details:&nbsp;</b> </span>
                    <!--                        <div class="details flex-child long-and-truncated">-->
                    <!--                          <a style="color:red;cursor: pointer" (click)="displayOneUpError(step.message)">{{step.message}}</a>-->
                    <!--                        </div>-->
                    <button *ngIf="step.message" class="accordion" style="color:red;cursor: pointer;width: 100%"
                      (click)="displayError(i)">{{getErrorIfExists(step.message)}}{{ (step.message.length>65)?
                      (step.message
                      |
                      slice:0:65)+'...':(step.message) }}</button>
                    <div *ngIf="showDetails.includes(item.id)" class="panel" id="panel_{{i}}">
                      {{(step.message.length>1200)? (step.message | slice:0:1200)+'...':step.message}}
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
