import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient } from '@angular/common/http';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { SearchResult } from '../search-result.model';
import { LoginService } from '../login/login.service';

import {GoogleAnalyticsEventsService} from "../app.google-analynics-events.service";


@Component({
  selector: 'app-upcominglist',
  templateUrl: './upcominglist.component.html',
  styleUrls: ['./upcominglist.component.css']
})

export class UpcomingListComponent implements OnInit, OnDestroy {
  @ViewChild('upcomingPopup') popup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public oneUp: string;
  public completed = '';
  public processing = '';
  public failed = '';
  public all = '';
  public groups = [
    'archive',
    'audio',
    'cctv',
    'entertainment',
    'gms',
    'gns',
    'horizons',
    'mes',
    'mid-east-extra',
    'mid-east-extra-arabic',
    'news-international',
    'news-international-arabic',
    'news-us',
    'sntv',
    'sntv-arabic',
    'spanish',
    'technology',
    'un',
    'vhub'
  ];
  public selectedVal = 'all';
  private _subscription: Subscription;

  constructor(private http: HttpClient, private service: LoginService) {
  }

  ngOnInit() {
    this.getEventItems();
    this._subscription = IntervalObservable.create(30000).subscribe(x => {
      this.getEventItems();
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getEventItems(): void {
    let url = this.httpGetUri + 'source:planning';
    if (this.selectedVal.indexOf('all') === -1) {
      url = url + ' AND fields.storyPublishingSystemGroup:(' + (this.selectedVal.toString().split(',').join(' OR ')) + ')';
    }
    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }

  processSearchResults(item: string): void {
    this.storedItems = [];
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      if (!event.fields.mediaPublishingSystemLocation) {
        event.fields.mediaPublishingSystemLocation = '-';
      }
      if (!event.fields.storyPublishingSystemLocation) {
        event.fields.storyPublishingSystemLocation = '-';
      }
      this.storedItems.push(event);
    });
  }

  displayOneUp(index: number) {
    this.oneUp = JSON.stringify(this.storedItems[index].rawEvent, null, 2);
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = 'Item Details';
    options.widthPercentage = 75;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    window.scrollTo(0, 0);
  }

  hideOneUp() {
    this.popup.hide();
  }

  onGroupSelectChange() {
    this.getEventItems();
  }

}
