import { Pipe, PipeTransform } from '@angular/core';
const moment = require('moment');
@Pipe({
  name: 'dateFormate'
})
export class DateFormatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      
      return new Date(value).toUTCString();
      //return moment(value).format("ddd, MMM D YYYY, h:mm:ss a");;
    }
    return value;
  }

}
