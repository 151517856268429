import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor() { }
  public welcomeMessage = '';
  public version = environment.version;
  public envName = environment.envName;
  public LoginButtonText = 'Sign In';

  ngOnInit() {
    // local storage will be empty when user first logs in (waiting on post call)
    // need to receive event from parent to update this info when available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser) {
        if (currentUser.username) {
          const begin = currentUser.username.toString().indexOf('_');
          const end = currentUser.username.toString().indexOf('@');
          this.welcomeMessage = 'Welcome, ' + currentUser.username.toString().slice(begin + 1, end);
        } else {
          this.welcomeMessage = ' ';
        }
        this.LoginButtonText = 'Sign Out';
      }
  }

  public LoginLogout() {
    if (this.LoginButtonText === 'Sign Out') {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('authorizedRoles');
      window.location.href = environment.LogoutUrl;
    } else {
      window.location.href = environment.LoginUrl;
    }
  }

}
