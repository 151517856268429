import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { foxReporting } from './reporting.model';
import { asperaFialureReporting } from './reporting.model';
import { foxClassReporting } from './reporting.model';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { LoginService } from '../login/login.service';
import { SearchResult } from '../search-result.model';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { ActiveTabService } from '../service/active-tab.service';
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  @ViewChild('asperaErrorPopup') popupAspera: PopupComponent;
  @Input() httpGetUri: string;
  public oneUp: string;
  public dataList: foxReporting[] = [];
  public dataList_aspera: foxReporting[] = [];
  public dataList_aspera_failures: asperaFialureReporting[] = [];
  public dataList_class_reporting: foxClassReporting[] = [];
  public startDate = "now/d";
  public endDate = "now";
  constructor(private activeTabService: ActiveTabService, private http: HttpClient, private service: LoginService) { }
  private _subscription: Subscription;
  public ingestItems = [];

  ngOnInit() {
    var d = new Date();
    var dStart = new Date(d);
    this.startDate = dStart.getFullYear() + '-' + ('0' + (dStart.getMonth() + 1)).slice(-2) + '-' + ('0' + dStart.getDate()).slice(-2);

    var dEnd = new Date();
    this.endDate = dEnd.getFullYear() + '-' + ('0' + (dEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + dEnd.getDate()).slice(-2);

    this.activeTabService.activeTab.subscribe(data => {
      if (data == "reporting") {
        this.getData().subscribe(
          resp => this.buildResponseData(resp.aggregations.count_stories_by_provider_for_today.buckets, resp.aggregations.count_stories_by_provider_aspera_failures.buckets, resp.aggregations.count_stories_by_fox_content_class.buckets));
          this.getInjestItems();
        }
    });

    this._subscription = IntervalObservable.create(10000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "reporting") {
        this.getData().subscribe(
          resp => this.buildResponseData(resp.aggregations.count_stories_by_provider_for_today.buckets, resp.aggregations.count_stories_by_provider_aspera_failures.buckets, resp.aggregations.count_stories_by_fox_content_class.buckets));
        this.getInjestItems();
      }
    });

  }

  onDateChangeStart(date) {
    console.log(date);
    this.startDate = date;
    this.getData().subscribe(
      resp => this.buildResponseData(resp.aggregations.count_stories_by_provider_for_today.buckets, resp.aggregations.count_stories_by_provider_aspera_failures.buckets, resp.aggregations.count_stories_by_fox_content_class.buckets));

    this.getInjestItems();
  }
  onDateChangeEnd(date) {
    this.endDate = date;
    this.getData().subscribe(
      resp => this.buildResponseData(resp.aggregations.count_stories_by_provider_for_today.buckets, resp.aggregations.count_stories_by_provider_aspera_failures.buckets, resp.aggregations.count_stories_by_fox_content_class.buckets));

    this.getInjestItems();
  }
  displayOneUp(provider: string) {
    this.dataList_aspera_failures.forEach(error => {
      if (error.provider === provider) {
        var stringTouse = '';
        for (var something in JSON.parse(JSON.parse(error.failures)["_body"])["hits"]["hits"]) {
          //console.log(something)
          stringTouse = stringTouse + JSON.parse(JSON.parse(error.failures)["_body"])["hits"]["hits"][something]["_source"]["fields"]["title"] + '\r\n';
        }
        //console.log(stringTouse)
        this.oneUp = stringTouse;
        const options: PopupOptions = new PopupOptions();
        options.showButtons = false;
        options.color = '#777';
        options.header = 'Error Details';
        options.widthPercentage = 75;
        options.animation = 'fadeInDown';
        this.popupAspera.show(options);
        window.scrollTo(0, 0);
      }
    });
  }

  hideOneUp() {
    this.popupAspera.hide();
  }

  private getFieldValue(data, field) {
    var fields = [];
    for (var something in data["hits"]["hits"]) {
      var value = data["hits"]["hits"][something]["_source"]["fields"][field];
      if (value !== undefined) {
        fields.push(value);
      }
    }
    return fields;
  }

  private buildResponseData(providers, aspera_errors, fox_content_class) {
    this.dataList = [];
    this.dataList_class_reporting = [];
    providers.forEach(element => {
      element.count_of_stories.buckets.forEach(provider => {
        this.dataList.push({ "provider": provider.key, "count": provider.doc_count });
      });
    });

    fox_content_class.forEach(element => {
      element.count_of_stories.buckets.forEach(fox_content_class => {
        this.dataList_class_reporting.push({ "class": fox_content_class.key, "count": fox_content_class.doc_count });
      });
    });

    this.dataList_aspera = [];
    aspera_errors.forEach(element => {
      element.count_of_stories.buckets.forEach(provider => {
        this.dataList_aspera.push({ "provider": provider.key, "count": provider.doc_count });
        //if errors exist then load them up!
        if (!provider.key.includes("u'")) {
          let searchQuery = this.httpGetUri + 'limit=2000' + '&query=';
          searchQuery = searchQuery + 'source:aspera AND fields.provider_id:' + provider.key.normalize();

          this.http.get(searchQuery).subscribe(data => {
            this.dataList_aspera_failures.push({ "provider": provider.key, "failures": JSON.stringify(data, null, 2) });
          });
        }
      });
    });
  }

  private getData(): Observable<any> {

    const content = `{
      "aggregations": {
        "count_stories_by_provider_for_today": {
          "aggregations": {
            "count_of_stories": {
              "terms": {
                "exclude": [
                  ""
                ],
                "field": "fields.provider_id.keyword",
                "size": 100
              }
            }
          },
          "filters": {
            "filters": [
              {
                "exists": {
                  "field": "fields.fox_publishing_api_aggregate_url.keyword"
                }
              }
            ]
          }
        },
        "count_stories_by_provider_aspera_failures": {
          "aggregations": {
            "count_of_stories": {
              "terms": {
                "exclude": [
                  ""
                ],
                "field": "fields.provider_id.keyword",
                "size": 100
              }
            }
          },
          "filters": {
            "filters": [
              {
                "match": {
                  "source.keyword": "aspera"
                }
              }
            ]
          }
        },
        "count_stories_by_fox_content_class": {
          "aggregations": {
            "count_of_stories": {
              "terms": {
                "exclude": [
                  ""
                ],
                "field": "fields.fox_content_class.keyword",
                "size": 100
              }
            }
          },
          "filters": {
            "filters": [
              {
                "exists": {
                  "field": "fields.fox_content_class.keyword"
                }
              }
            ]
          }
        }
      },
      "query": {
          "range": {
              "timestamp": {
                   "gte": "%dateStart%",
                    "lte": "%dateEnd%"
              }
          }
      },
      "size": 0
    }

`.replace('%dateStart%', this.startDate).replace('%dateEnd%', this.endDate);

    const postUrl = this.httpGetUri.substr(0, this.httpGetUri.indexOf('?'));
    return this.http.post(postUrl, content).pipe();
  }

  getInjestItems(): void {
    //console.log("ingest refresh");
    let url = this.httpGetUri + 'limit=2000' + '&query=' + 'source:ingest_mam';
    if (this.startDate && this.endDate) {
      url = url + '&date_start=' + this.startDate + '&date_end=' + this.endDate;
    }

    if (!url || url === '') {
      this.ingestItems = [];
      return;
    }

    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }


  processSearchResults(item: string): void {

    this.ingestItems = [];
    const results: SearchResult = JSON.parse(item);
    let data = [];
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      let obj = { framerate: "", duration: "", _id: "" };
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));

      if (event.fields.mediainfo_videoFramRate && event.fields.mediainfo_videoFramRate != ""
        && event.fields.mediainfo_videoDuration && event.fields.mediainfo_videoDuration != "") {
        obj.framerate = event.fields.mediainfo_videoFramRate;
        obj.duration = event.fields.mediainfo_videoDuration;
        obj._id = element._id;
        data.push(obj);
      }
    });


    if (data.length > 0) {
      var result = [];
      data.reduce(function (res, value) {
        if (!res[value.framerate]) {
          res[value.framerate] = { framerate: value.framerate, duration: 0, count: 0 };
          result.push(res[value.framerate])
        }
        res[value.framerate].count += 1;
        res[value.framerate].duration += parseInt(value.duration);
        return res;
      }, {});

      this.ingestItems = result;
    }
  }

  getDuration(millis) {
    if (millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + "." + seconds;
    }
  }

  getFramerate(framerate) {
    return parseFloat(framerate).toString();
  }

}
