import {Injectable} from "@angular/core";
import {integer} from "aws-sdk/clients/cloudfront";
// Declare ga function as ambient
declare let ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: integer = null) {

    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }
}
