<app-popup #eventPopup (cancelClick)="hideOneUp()" [ngStyle]="{'margin-top': errorMTop}" >
    <pre>{{ oneUp }}</pre>
</app-popup>
<div id="divDashboard">
    <div class="row no-gutter">
        <form class="filter-form">
            <div class="media-block">
                <a class="media-filter ico-audio" [class.active]="viewAudio" href="#" title="Display Audio"><input type="checkbox" name="audioFilter" checked="checked" (change)="audioChanged()" /></a>
                <a class="media-filter ico-video" [class.active]="viewVideo" href="#" title="Display Video"><input type="checkbox" name="videoFilter" checked="checked" (change)="videoChanged()" /></a>
                 <a class="media-filter ico-ap" [class.active]="showAPContentOnly" href="#" title="Display AP Only Content"><input type="checkbox" name="apOnlyFilter" (change)="APOnlyChanged()" /></a>
                <a class="media-filter ico-heartbeat" [class.active]="allowHeartbeat" href="#" title="Display Heartbeats"><input type="checkbox" name="heartbeatFilter" (change)="heartbeatChanged()" /></a>
                <a class="media-filter ico-folder-o" [class.active]="showArchiveContent" href="#" title="Show Archive Content Processing"><input type="checkbox" name="archiveFilter" (change)="archiveFilterChanged()" /></a>
            </div>
            <div class="media-block">
                <input type="text" class="search-input" #searchquery [value]="query" (keydown.enter)="submit(searchquery.value)" placeholder="Start searching..."><button (click)="submit(searchquery.value)" class="search-button"><i class="ico-search"></i></button>
            </div>
            <div class="media-block">
                <select name="paging" class="select-general" [(ngModel)]="paging" (change)="onPagingSelectChange()">
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
              </select>
            </div>
            <div class="filter-block">
                <label class="radio-inline link" [class.active]="isAllActive">
                    <input type="radio" name="filter1" [value]="all" (change)="onFilterChange('all')" checked="checked">All Results<span *ngIf="allCount > 0"> ({{ allCount }})</span>
                </label>
                <label class="radio-inline link completed" [class.active]="isCompletedActive">
                    <input type="radio" name="filter1" [value]="completed" (change)="onFilterChange('completed')">Completed<span *ngIf="completedCount > 0"> ({{ completedCount }})</span>
                </label>
                <label class="radio-inline link processing" [class.active]="isProcessingActive">
                    <input type="radio" name="filter1" [value]="processing" (change)="onFilterChange('processing')">Processing<span *ngIf="inProgressCount > 0"> ({{ inProgressCount }})</span>
                </label>
                <label class="radio-inline link failed" [class.active]="isFailedActive">
                    <input type="radio" name="filter1" [value]="failed" (change)="onFilterChange('failed')">Failed<span *ngIf="failedCount > 0"> ({{ failedCount }})</span>
                </label>
            </div>
        </form>


        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 20%"><span>Story</span></th>
                    <th style="width: 10%"><span>Details</span></th>
                    <th style="width: 25%">
                        <table  class="table" style="background-color: transparent !important;" >
                            <tr style="width: 100%;">
                                <td style="width: 25%; text-align: center;">
                                    <span>submitted</span>
                                </td>
                                <td style="width: 12%; text-align: center;"><span>----></span></td>
                                <td style="width: 25%; text-align: center;">
                                <span>In Progress</span>
                                </td>
                                <td style="width: 12%; text-align: center;"><span>----></span></td>
                                <td style="width: 25%; text-align: center;">
                                    <span>completed</span>
                                </td>
                            </tr>

                        </table>
                    </th>
                    <th style="width: 10%"><span>Age</span></th>
                    <th style="width: 15%"><span>To Market</span></th>
                    <th style="width: 25%"><span>&nbsp;</span></th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}">
                <td>
                  <div class="result-info">
                    <img src="../../assets/img/priority.png" alt="Priority One" class="priority" *ngIf="item.fields.priority == 1" />
                    <span *ngIf="item.fields.isKilled === 'true'" class="killed">K</span>
                    <span class="id"><span class="ico-{{item.fields.mediainfo_sourceFormat | lowercase}}" style="cursor: pointer;"></span><img src="../../assets/img/restore.jpg" height="20px" alt="Archive Restore" *ngIf="item.fields.fox_content_class === 'archiverestore' || item.fields.fox_content_class === 'digitize'" /> <img src="../../assets/img/aspera-icon.jpg" height="20px" alt="Aspera" *ngIf="item.fields.fox_content_class === 'aspera'" />  <img src="../../assets/img/rss.png" height="20px" alt="MRSS" *ngIf="item.fields.fox_content_class === 'mrss'" /> <img src="../../assets/img/upload-tool.png" height="20px" alt="Upload Tool" *ngIf="item.fields.fox_content_class === 'vut'" />  {{item.fields.title || '--- No Slug Title ---'}}</span>

                    <div *ngIf="showOpsDetails === true" class="sub-info-cell">Reference: {{ item.fields.fox_orchestration_id }}</div>
                  </div>
                </td>
                <td>
                  <div class="result-info">
                    <p>{{item.fields.content_provider_storyNumber}}{{(item.fields.metadataVersion?'
                        v.'+item.fields.metadataVersion:'')}} - {{item.fields.provider_id | uppercase
                        }}</p>
                        <span *ngIf="item.fields.mediainfo_audioDuration">({{ getDuration(item.fields.mediainfo_audioDuration) }})</span>
                        <span *ngIf="item.fields.mediainfo_videoDuration">({{ getDuration(item.fields.mediainfo_videoDuration) }})</span>
                  </div>
                </td>
                <td>
                    <table class="table table-striped">
                    <tr style="width: 100%;" style="background-color: transparent !important;" >
                        <td style="width: 25%; text-align: center;">
                            <span class="indicat completed"></span>
                        </td>
                        <td style="width: 12%; text-align: center;"><span></span></td>
                        <td style="width: 25%; text-align: center;">
                            <span class="indicat {{item.groups[0].status}}" title="{{getGroupErrorMessage(i)}}" (click)="copyErrorToClipboard(i)"></span>
                        </td>
                        <td style="width: 12%; text-align: center;"><span></span></td>
                        <td style="width: 25%; text-align: center;">
                            <span *ngIf="item.groups[0].status != 'completed'" class="indicat pending"></span>
                            <span *ngIf="item.groups[0].status === 'completed'" class="indicat completed"></span>
                        </td>

                    </tr>
                    </table>




               <!-- <tr *ngFor="let stage of item.groups[0].stages" class="table table-striped" border=0>
                <div *ngFor="let step of stage.steps; let istep = index; let iCount = count">
                  <div *ngIf="(stage.stageLabel.toLowerCase() !== 'ingest' || stage.status.toLowerCase() !== 'completed') && step.isLatestStep " >
                  <!--<tr *ngFor="let step of getGroupStepStatus(i)" class="table table-striped" border=0>-->
                  <!-- <th class="table table-striped" border=0><span class="indicator {{stage.status}}" title="{{getGroupErrorMessage(i)}}" (click)="copyErrorToClipboard(i)"> {{stage.stageLabel}}</span></th>
                  </div>
                </div> -->
              <!-- </tr> -->
              </td>
              <td>
                <app-timer [time]="getStartTime(i)" ></app-timer>
              </td>
              <td>
                <div *ngIf="item.groups[0].status === 'completed'">
                 {{ getTimeToMarket(i) }}
                </div>
                        <!--<div *ngIf="item.groups[0].status != 'failed'">-->
                            <!--{{ getTimeToMarket(i) }}-->
                        <!--</div>-->

                </td>
                <td>
                    <i class="ico-file" (click)="displayOneUp(i)"></i>
                </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
