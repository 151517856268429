<app-popup #upcomingPopup (cancelClick)="hideOneUp()">
  <pre>{{ oneUp }}</pre>
</app-popup>
<div id="divDashboard">
  <div class="row no-gutter">
    <form class="filter-form">
      <div class="filter-block" style="padding-top: 0">
        Group: 
        <select multiple name="selectedGroups" class="select-general" [(ngModel)]="selectedVal" (change)="onGroupSelectChange()">
          <option selected="selected" value="all">All</option>
          <option *ngFor="let group of groups" value={{group}}>{{group}}</option>
        </select>
      </div>
    </form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th style="width: 20%"><span>Story</span></th>
          <th style="width: 10%"><span>Details</span></th>
          <th style="width: 10%"><span>Age</span></th>
          <th style="width: 10%"><span>Author</span></th>
          <th style="width: 10%"><span>Group</span></th>
          <th style="width: 10%"><span>Story / Media</span></th>
          <th style="width: 20%"><span>Categories</span></th>
          <th style="width: 10%"><span>&nbsp;</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}">
          <td>
            <div class="result-info">
               <p class="id">{{ item.fields.slug || '--- No Slug Title ---' }}</p>
            </div>
          </td>
           <td>
                <div class="result-info">
                    <div *ngIf="item.fields.storyNumber">{{ item.fields.storyNumber }} - {{ item.fields.providerId | uppercase }}</div>
                </div>
          </td>
            <td>
                <app-timer [time]="item.timestamp" ></app-timer>
            </td>
          <td>
            <div class="result-info">
              <p>{{ item.fields.Author }}</p>
            </div>
          </td>
          <td>
            <div class="result-info">
              <p>{{ item.fields.storyPublishingSystemGroup }}</p>
            </div>
          </td>        
          <td>
            <div class="result-info">
              <p>{{ item.fields.storyPublishingSystemLocation }} / {{ item.fields.mediaPublishingSystemLocation }}</p>
            </div>
          </td>   
          <td>
            <div class="result-info">
              <p>{{ item.fields.Categories }}</p>
            </div>
          </td>
          <td>
            <i class="ico-file" (click)="displayOneUp(i)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>