<div class="col-sm-5 col-md-4 no-padding">
  <div class="pull-right build-version">
    <span class="login-name"> {{ welcomeMessage }} </span>
    &nbsp;
    <a id="btnLogin" class="button" (click)="LoginLogout()">{{ LoginButtonText }}</a>
  </div>
  <div class="pull-right site-title-nexus">
    <p class="ap-sans-cond-bold">AP NEXUS
      <span id="build-version">{{version}}- {{ envName }} </span>
    </p>
  </div>
</div>
