import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveTabService {

  private activeTabSubject:BehaviorSubject<string>;
  public activeTab:Observable<string>;


   constructor() {
    this.activeTabSubject=new BehaviorSubject<string>("");
    this.activeTab=this.activeTabSubject.asObservable();
   }

   public getActiveTab(): string { 
      return this.activeTabSubject.value;
   }

   public setActiveTab(value:string){
    this.activeTabSubject.next(value);
   }
}
