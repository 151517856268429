<div id="overlay" class="overlay" (click)="confirmNo()"></div>
<div id="ng2-opd-popup-main" *ngIf="visible" [ngClass]="mainClass" [ngStyle]="mainStyle">
    <div class="row no-gutter">
        <div class="col-sm-12">
            <div id="ng2-opd-popup-well" [ngStyle]="wellStyle" class="ap-sans-bold ng2-opd-popup-well ng2-opd-popup-well-sm">
                {{options.header}}
                <i class="ico-times" (click)="confirmNo()"></i>
            </div>
        </div>
    </div>
    <div class="row no-gutter">
        <div class="col-sm-12">
          <div *ngIf="options.showForm == false && options.restrictionItem == undefined" class="btnBlk">
            <div *ngFor="let item of options.fields; let i = index" class="row no-gutter">
              <div *ngIf="item.type !== 'select'">
              <p> {{item.label}}:<br> <input #popup.{{item.label}} type="{{item.type}}" name="popup.{{item.label}}" value="{{item.value}}"/></p>
            </div>
            <div *ngIf="item.type === 'select' && item.label === 'Department' ">
              {{item.label}}:<br> <select #popup.{{item.label}}  name="popup.{{item.label}}">
                <option value="n">News</option>
                <option value="e">Entertainment</option>
                <option value="hf">Horizons</option>
                <option value="m">Mideast Extra</option>
                <option value="s">SNTV</option>
            </select>
            </div>
              <div *ngIf="item.type === 'select' && item.label === 'Country Code' ">
              {{item.label}}:<br> <select id="popup.{{item.label}}"  name="popup.{{item.label}}">
                <option value="NULL"></option>
                <option value="AFG">AFGHANISTAN</option>
                <option value="ALB">ALBANIA</option>
                <option value="ALG">ALGERIA</option>
                <option value="AND">ANDORRA</option>
                <option value="ANG">ANGOLA</option>
                <option value="ANT">ANTARTICA</option>
                <option value="ANB">ANTIGUA AND BARBUDA</option>
                <option value="ARG">ARGENTINA</option>
                <option value="ARM">ARMENIA</option>
                <option value="AUS">AUSTRALIA</option>
                <option value="AUT">AUSTRIA</option>
                <option value="AZE">AZERBAIJAN</option>
                <option value="BHM">BAHAMAS</option>
                <option value="BAH">BAHRAIN</option>
                <option value="BLK">BALKANS</option>
                <option value="BAN">BANGLADESH</option>
                <option value="BAR">BARBADOS</option>
                <option value="BLS">BELARUS</option>
                <option value="BEL">BELGIUM</option>
                <option value="BZE">BELIZE</option>
                <option value="BEN">BENIN</option>
                <option value="BHU">BHUTAN</option>
                <option value="BOL">BOLIVIA</option>
                <option value="BSH"> BOSNIA AND HERCE</option> GOVINA
                <option value="BOT">BOTSWANA</option>
                <option value="BRA">BRAZIL</option>
                <option value="BRU">BRUNEI </option>
                <option value="BUL">BULGARIA</option>
                <option value="BUF">BURKINA FASO</option>
                <option value="BUR">BURUNDI</option>
                <option value="CAM">CAMBODIA</option>
                <option value="CMN">CAMEROON</option>
                <option value="CAN">CANADA</option>
                <option value="CAP">CAPE VERDE</option>
                <option value="CAR">CENTRAL AFRICAN REPU</option> BLIC
                <option value="CHA">CHAD</option>
                <option value="CHI">CHILE</option>
                <option value="CHN">CHINA</option>
                <option value="COL">COLOMBIA</option>
                <option value="COM">COMOROS</option>
                <option value="CSR">COSTA RICA</option>
                <option value="CRM">CRIMEA</option>
                <option value="CRO">CROATIA</option>
                <option value="CUB">CUBA</option>
                <option value="CYP">CYPRUS</option>
                <option value="CZE">CZECH REPUBLIC</option>
                <option value="DRC">DEMOCRATIC REPUBLIC OF C</option> ONGO
                <option value="DEN">DENMARK</option>
                <option value="DJI">DJIBOUTI</option>
                <option value="DOM">DOMINICA</option>
                <option value="DMR">DOMINICAN REPUBLIC</option>
                <option value="EAS">EAST TIMOR</option>
                <option value="ECU">ECUADOR</option>
                <option value="EGY">EGYPT</option>
                <option value="ELS">EL SALVADOR</option>
                <option value="EQG">EQUATORIAL GUINEA</option>
                <option value="ERI">ERITREA</option>
                <option value="EST">ESTONIA</option>
                <option value="ESW">ESWATINI (former </option> Swaziland)
                <option value="ETH">ETHIOPIA</option>
                <option value="EU ">UROPEAN UNION</option>
                <option value="EUR">EUROPE</option>
                <option value="FIJ">FIJI</option>
                <option value="FIN">FINLAND</option>
                <option value="FRA">FRANCE</option>
                <option value="FRG">FRENCH GIUANA</option>
                <option value="GAB">GABON</option>
                <option value="GAM">GAMBIA</option>
                <option value="GAZ">GAZA STRIP</option>
                <option value="GEO">GEORGIA</option>
                <option value="GER">GERMANY</option>
                <option value="GHA">GHANA</option>
                <option value="GRE">GREECE</option>
                <option value="GRN">GRENADA</option>
                <option value="GUA">GUATEMALA</option>
                <option value="GUI">GUINEA</option>
                <option value="GUB">GUINEA-BISSAU</option>
                <option value="GUY">GUYANA </option>
                <option value="HAI">HAITI </option>
                <option value="HON">HONDURAS</option>
                <option value="HKG">HONG KONG</option>
                <option value="HUN">HUNGARY</option>
                <option value="ICE">ICELAND</option>
                <option value="IND">INDIA</option>
                <option value="INS">INDONESIA</option>
                <option value="IRA">IRAN</option>
                <option value="IRQ">IRAQ</option>
                <option value="IRE">IRELAND</option>
                <option value="ISR">ISRAEL </option>
                <option value="ITA">ITALY</option>
                <option value="IVC">IVORY COAST</option>
                <option value="JAM">JAMAICA</option>
                <option value="JPN">JAPAN</option>
                <option value="JOR">JORDAN</option>
                <option value="KAZ">KAZAKHSTAN</option>
                <option value="KEN">KENYA</option>
                <option value="KIR">KIRIBATI</option>
                <option value="KOS">KOSOVO</option>
                <option value="KUW">KUWAIT</option>
                <option value="KYR">KYRGYZSTAN</option>
                <option value="LAO">LAOS</option>
                <option value="LAT">LATVIA</option>
                <option value="LEB">LEBANON</option>
                <option value="LES">LESOTHO</option>
                <option value="LIB">LIBERIA</option>
                <option value="LBY">LIBYA</option>
                <option value="LIE">LIECHTENSTEIN</option>
                <option value="LIT">LITHUANIA</option>
                <option value="LUX">LUXEMBOURG</option>
                <option value="MAD">MADAGASCAR</option>
                <option value="MLW">MALAWI</option>
                <option value="MAL">MALAYSIA</option>
                <option value="MLV">MALDIVES</option>
                <option value="MLI">MALI</option>
                <option value="MLT">MALTA</option>
                <option value="MAR">MARSHALL ISLAND</option> S
                <option value="MTA">MAURITANIA</option>
                <option value="MAU">MAURITIUS</option>
                <option value="MEX">MEXICO</option>
                <option value="MIC">MICRONESIA</option>
                <option value="MEA">MIDEAST</option>
                <option value="MOL">MOLDOVA</option>
                <option value="MON">MONACO</option>
                <option value="MNG">MONGOLIA</option>
                <option value="MTG">MONTENEGRO</option>
                <option value="MOR">MOROCCO</option>
                <option value="MOZ">MOZAMBIQUE</option>
                <option value="MYN">MYANMAR</option>
                <option value="NAM">NAMIBIA</option>
                <option value="NAU">NAURU</option>
                <option value="NEP">NEPAL</option>
                <option value="NMA">NORTH MACEDONIA</option>
                <option value="NTH">NETHERLANDS</option>
                <option value="NWZ">NEW ZEALAND</option>
                <option value="NIC">NICARAGUA</option>
                <option value="NIG">NIGER</option>
                <option value="NGA">NIGERIA</option>
                <option value="NKO">NORTH KOREA</option>
                <option value="NOR">NORWAY</option>
                <option value="OCE">OCEANIA</option>
                <option value="OMA">OMAN</option>
                <option value="PAK">PAKISTAN</option>
                <option value="PLU">PALAU</option>
                <option value="PAN">PANAMA</option>
                <option value="PNG">PAPUA NEW GUINEA</option>
                <option value="PAR">PARAGUAY</option>
                <option value="PER">PERU</option>
                <option value="PHL">PHILIPPINES</option>
                <option value="POL">POLAND</option>
                <option value="POR">PORTUGAL</option>
                <option value="PUR">PUERTO RICO</option>
                <option value="QAT">QATAR</option>
                <option value="ROM">ROMANIA</option>
                <option value="RUS">RUSSIA</option>
                <option value="RWA">RWANDA</option>
                <option value="SKN">ST KITTS AND NEV</option> IS
                <option value="SLU">ST LUCIA</option>
                <option value="SVG">ST VINCENT AND GRE</option> NADA
                <option value="SAM">SAMOA</option>
                <option value="SAN">SAN MARINO</option>
                <option value="SAU">SAUDI ARABIA</option>
                <option value="SEN">SENEGAL</option>
                <option value="SER">SERBIA</option>
                <option value="SEY">SEYCHELLES</option>
                <option value="SIL">SIERRA LEONE</option>
                <option value="SIN">SINGAPORE</option>
                <option value="SLK">SLOVAKIA</option>
                <option value="SLV">SLOVENIA</option>
                <option value="SLI">SOLOMON ISLANDS</option>
                <option value="SOM">SOMALIA</option>
                <option value="SAF">SOUTH AFRICA</option>
                <option value="SKO">SOUTH KOREA</option>
                <option value="SSU">SOUTH SUDAN</option>
                <option value="SPA">SPAIN</option>
                <option value="SRI">SRI LANKA</option>
                <option value="SUD">SUDAN</option>
                <option value="SUR">SURINAME</option>
                <option value="SWZ">SWAZILAND</option>
                <option value="SWE">SWEDEN</option>
                <option value="SWI">SWITZERLAND</option>
                <option value="SYR">SYRIA</option>
                <option value="TAI">TAIWAN</option>
                <option value="TAJ">TAJIKISTAN</option>
                <option value="TAN">TANZANIA</option>
                <option value="THA">THAILAND</option>
                <option value="TOG">TOGO</option>
                <option value="TNT">TRINIDAD AND T</option> OBAGO
                <option value="TUN">TUNISIA</option>
                <option value="TUR">TURKEY</option>
                <option value="TKM">TURKMENISTAN</option>
                <option value="TUV">TUVALU</option>
                <option value="UGA">UGANDA</option>
                <option value="UKR">UKRAINE</option>
                <option value="UAE">UNITED ARAB EMIRA</option> TES
                <option value="UK">UNITED KINGDOM</option>
                <option value="UN">UNITED NATIONS</option>
                <option value="URA">URUGUAY</option>
                <option value="US">UNITED STATES</option>
                <option value="UZB">UZBEKISTAN</option>
                <option value="VAN">VANUATU</option>
                <option value="VAT">VATICAN </option>
                <option value="VEN">VENEZUELA</option>
                <option value="VIE">VIETNAM</option>
                <option value="WBK">WEST BANK</option>
                <option value="YEM">YEMEN</option>
                <option value="ZAM">ZAMBIA</option>
                <option value="ZIM">ZIMBABWE</option>
                <option value="AL">Alabama</option>
                <!--STATES-->
                <option value="AK">Alaska</option>
                <option value="AS">American Samoa</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="GU">Guam</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MH">Marshall Islands</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="FM">Micronesia</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="MP">Northern Marianas</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PW">Palau</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="VI">Virgin Islands</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
            </select>
            </div>
            </div><p>
            <input type="checkbox" name="popup.sensitive" value="false" >&nbsp;Sensitive Material<br/></p>
             <ng-content></ng-content>

            <p></p><p>
            <br>
            <button  id="uploadBtn" [ngClass]="confirmBtnStyle" type="reset" (click)="confirmFields()">{{options.confirmBtnContent}}</button>
            <button  id="cancelUploadBtn" [ngClass]="cancelBtnStyle" type="reset" (click)="confirmNo()">Cancel</button></p>

          </div>
          <div *ngIf="options.showForm == false && options.restrictionItem != undefined"  style="left: auto">
             <!-- restriction values -->
            <div *ngIf="options.addnew == false">
                      <!-- lookup field name/value -->
                      <p> Lookup Field Name: &nbsp;&nbsp;<input id="retriction.FieldKeyName" #retriction.FieldKeyName type="text" name="retriction.FieldKeyName" value="{{options.restrictionItem.FieldKeyName}}" disabled /></p>

                      <!-- lookup field value -->
                      <p> Lookup Field Value: &nbsp;&nbsp; <input id="retriction.FieldKeyValue" #retriction.FieldKeyValue type="text" name="retriction.FieldKeyValue" value="{{options.restrictionItem.FieldKeyValue}}" disabled /></p>
          </div>
          <div *ngIf="options.addnew == true" >
                      <!-- lookup field name/value -->
                      <p> Lookup Field Name: &nbsp;&nbsp;<input id="retriction.FieldKeyName" #retriction.FieldKeyName type="text" name="retriction.FieldKeyName" value="{{options.restrictionItem.FieldKeyName}}"  /></p>

                      <!-- lookup field value -->
                      <p> Lookup Field Value: &nbsp;&nbsp; <input id="retriction.FieldKeyValue" #retriction.FieldKeyValue type="text" name="retriction.FieldKeyValue" value="{{options.restrictionItem.FieldKeyValue}}"  /></p>
          </div>
            <p></p><br><b>Fields to Assign</b>

                       <!-- lookup fields -->
                      <div *ngFor="let fld of options.restrictionItem.Fields; let i = index" class="row no-gutter">
                        &nbsp;&nbsp; {{fld.field_name}}: &nbsp;&nbsp;<p><textarea id="retriction.{{fld.field_name}}" #retriction.{{fld.field_name}} style="width: 95%" type="text" name="retriction.{{fld.field_name}}" value="{{fld.field_value}}" rows="2"></textarea></p>
                        <br>

                      </div>
            <br/>
                <button id="cancelRestBtn" [ngClass]="cancelBtnStyle" type="reset" (click)="confirmNo()">{{options.cancelBtnContent}}</button>
                <button id="confirmRestBtn" [ngClass]="confirmBtnStyle" (click)="confirmRestrictionFields()">{{options.confirmBtnContent}}</button>
            <br/>
               <br/>

          </div>
          <div *ngIf="options.showForm == true" class="col-sm-12">
            <ng-content *ngIf="options.showForm == true"></ng-content>
            <div *ngIf="options.showButtons" class="btnBlk">
                <button id="cancelBtn" [ngClass]="cancelBtnStyle" type="reset" (click)="confirmNo()">{{options.cancelBtnContent}}</button>
                <button id="confirmBtn" [ngClass]="confirmBtnStyle" (click)="confirmYes()">{{options.confirmBtnContent}}</button>
            </div>
          </div>
        </div>
    </div>
</div>
