<script src="../../../../../../../../Users/rregan/Downloads/expedat_client-win64-1.18B-P/ExpeDat/Web/EXP.js"></script>

<app-popup #restrictionsPopup (cancelClick)="hideOneUp()" (confirmClick)="updateRestriction()" (deleteClick)="deleteRestriction(null)" [ngStyle]="{'margin-top': errorMTop}" >
  <pre></pre>
</app-popup>
<div id="divDashboard">
  <div class="row no-gutter">
      <form class="filter-form">
          <div class="media-block">
                  <input type="text" class="search-input" #restrictionsearchquery [value]="query" (keydown.enter)="submit(restrictionsearchquery.value)" placeholder="Start searching..." style="margin-left: -1.3rem"><button (click)="submit(restrictionsearchquery.value)" class="search-button"><i class="ico-search"></i></button>
          </div>

<!--          <div class="media-block" *ngIf="isAuthorizedToEdit()">-->
<!--                  <button (click)="updateRestriction()" class="upload-button">Upload Video</button>-->

<!--          </div>-->

          <div class="filter-block">
            <span *ngIf="allCount > 0"> ({{ allCount }})</span>
          </div>
      </form>

      <table class="table table-striped">
          <thead>
              <tr>
                  <th *ngIf="isAuthorizedToEdit" style="width: 10%"><span>Lookup Field Name</span></th>
                  <th style="width: 15%" ><p><span>Lookup Field Value</span></p></th>
                  <th style="width: 55%" ><span>Fields to Assign</span></th>
                  <th style="width: 10%" ><p><span>User</span></p></th>
                  <th style="width: 10%" ><p><span>Last Updated</span></p></th>
              </tr>
          </thead>
          <tbody>
          <tr>
                <td>
                        <div class="media-block">
                             <span *ngIf="disableTable == false && isAuthorizedToEdit"><a href="#" (click)="displayEmptyOneUp()" routerLink="/restrictions" disabled="true"> Add New </a> </span>
                             <span *ngIf="disableTable == true && isAuthorizedToEdit" style="cursor: not-allowed">  Add New  </span>
    <!--                        <span class="indicator {{item.groups[0].status}}" title="{{getGroupErrorMessage(i)}}"-->
    <!--                              (click)="copyErrorToClipboard(i)"></span>-->
    <!--                      <i class="ico-file" (click)="displayOneUp(i)"></i>-->
                        </div>
                    </td>
            <td></td>
            <td></td>
            <td></td>

              </tr>
              <tr *ngFor="let item of restrictionItems; let i = index" class="tr_{{item.FieldKeyValue}}">
                <!-- Lookup Field Name -->
                <td >
                    <div class="media-block">
                      <span *ngIf="disableTable == false">
                        <a   href="#"  (click)="displayOneUp(i)" routerLink="/restrictions">Edit</a> 
                        &nbsp;&nbsp; 
                        <img style="cursor: pointer" atl="Delete" src="../../assets/img/delete-16.png" (click)="delete(i)"/></span>
                      <span *ngIf="disableTable == true" style="cursor: not-allowed">{{ item.FieldKeyName }}</span>
<!--                        <span class="indicator {{item.groups[0].status}}" title="{{getGroupErrorMessage(i)}}"-->
<!--                              (click)="copyErrorToClipboard(i)"></span>-->
<!--                      <i class="ico-file" (click)="displayOneUp(i)"></i>-->
                    </div>
                </td>
                <!-- Lookup Field Value -->
                  <td>
                      <div class="result-info download">
                         <span *ngIf="item.FieldKeyValue.length > 0"> {{ item.FieldKeyValue }}</span>
                      </div>
                  </td>
                <!-- Fields to Assign -->
                <td>
                    <div class="result-info download" *ngFor="let fld of item.Fields; let s = index">

                        <span *ngIf="fld.field_name"><b>{{ fld.field_name }} </b></span>
                        <span *ngIf="fld.field_value">:  {{ fld.field_value }} <br /></span>

                    </div>
                  </td>
                <!-- user -->
                  <td align="center">
                      <div class="result-info">
                          <span *ngIf="item.userId"> {{ item.userId }}</span>
                      </div>
                  </td>
                <!-- last updated -->
                  <td align="center">
                      <div class="result-info">
                          <span *ngIf="item.LastUpdated"> {{ item.LastUpdated  | dateFormate }}</span>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
