import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/login.service';
import { GoogleAnalyticsEventsService } from "../app.google-analynics-events.service";

import { FoxEventFields } from "../foxeventobj/foxevent-fields";
import { ActiveTabService } from '../service/active-tab.service';

@Component({
  selector: 'app-archiveorderfulfillment',
  templateUrl: './archiveorderfulfillment.component.html',
  styleUrls: ['./archiveorderfulfillment.component.css']
})
export class ArchiveorderfulfillmentComponent implements OnInit, OnDestroy {
  @ViewChild('archiveorderfulfillmentPopup') popup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public filterCompleted = 'all';
  public oneUp: string;
  public isAllActive = true;
  public isProcessingActive = false;
  public isCompletedActive = false;
  public isFailedActive = false;
  public allowHeartbeat = false;
  public viewAudio = true;
  public viewVideo = true;
  public allCount = 0;
  public completedCount = 0;
  public inProgressCount = 0;
  public failedCount = 0;
  public query = '';
  public completed = '';
  public processing = '';
  public failed = '';
  public all = '';
  public showDetails = [];
  private d = new Date();
  public startDate = '';
  public endDate = '';
  public errorMTop="0px";
  private storedSearchQuery = '';
  private _subscription: Subscription;
  paging = 50;
  regions = 'all';
  public source = ['vid-1080i-physicalmain-50-slate', 'vid-1080i-physicalmain-60-slate', 'vid-720i-physicalmain-50-slate', 'vid-720i-physicalmain-60-slate'];
  public output = ['vid-1080i-main-60-slate', 'vid-1080i-main-50-slate', 'vid-1080i-physicalmain-60-slate', 'vid-1080i-physicalmain-50-slate','vid-720i-main-60-slate', 'vid-720i-main-50-slate'];
  public sourceOutputCombination = [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]];
  constructor(private activeTabService:ActiveTabService,private http: HttpClient, private sanitizer: DomSanitizer, private service: LoginService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {

    let localStoragePaging = localStorage.getItem("ArchivePaging");
    if (!localStoragePaging || localStoragePaging == undefined) {
      localStorage.setItem("ArchivePaging", this.paging.toString());
    }
    else {
      this.paging = parseInt(localStoragePaging);
    }

    let localStorageRegion = localStorage.getItem("OpsRegion");
    if (!localStorageRegion || localStorageRegion == undefined) {
      localStorage.setItem("OpsRegion", this.regions.toString());
    }
    else {
      this.regions = localStorageRegion;
    }

    var d = new Date();
    var dStart = new Date(d);
    dStart.setDate(d.getDate() - 3);
    this.startDate = dStart.getFullYear() + '-' + ('0' + (dStart.getMonth() + 1)).slice(-2) + '-' + ('0' + dStart.getDate()).slice(-2);

    var dEnd = new Date();
    dEnd.setDate(dEnd.getDate() + 1);
    this.endDate = dEnd.getFullYear() + '-' + ('0' + (dEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + dEnd.getDate()).slice(-2);


    this.activeTabService.activeTab.subscribe(data => {
      if (data == "archiveorderfulfillment") {
        if(this.storedItems.length==0){
          this.getEventItems();
          this.getEventsForFindCombination();
        }
      }
    });

    this._subscription = IntervalObservable.create(5000).subscribe(x => {

      if (this.activeTabService.getActiveTab() == "archiveorderfulfillment") {
        this.getEventItems();
        this.getEventsForFindCombination();
      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getEventItems(): void {
    // console.log("ingest refresh");
    const url = this.createSearchRequest();
    if (!url || url === '') {
      this.storedItems = [];
      return;
    }

    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }

  createSearchRequest(): string {
    // let searchQuery = this.httpGetUri;
    let searchQuery = this.httpGetUri + 'limit=' + this.paging + '&query=';
    searchQuery = searchQuery + 'fields.fox_content_class:archive_order_fulfillment';
    if (this.isProcessingActive === true) {
      searchQuery = searchQuery + ' AND groups.status:processing';
    } else if (this.isCompletedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:completed';
    } else if (this.isFailedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:failed';
    }
    if(this.regions && this.regions !== 'all')
    {
      searchQuery = searchQuery + ' AND fields.fox_processing_region:' + this.regions;
    }
    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery + '*';
    }

    if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery;
    }

    // console.log(this.startDate);
    if (this.startDate && this.endDate) {
      searchQuery = searchQuery + '&date_start=' + this.startDate + '&date_end=' + this.endDate;
    }
    console.log(searchQuery);
    return searchQuery;
  }

  processSearchResults(item: string): void {
    this.storedItems = [];
    this.resetCounts();
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      switch (event.groups[0].status) {
        case 'completed':
          this.completedCount += 1;
          break;
        case 'processing':
          this.inProgressCount += 1;
          break;
        case 'failed':
          this.failedCount += 1;
          break;
      }
      this.allCount += 1;

      this.storedItems.push(event);
    });
  }

  resetCounts() {
    this.allCount = 0;
    this.completedCount = 0;
    this.inProgressCount = 0;
    this.failedCount = 0;
  }

  getGroupErrorMessage(index: number): String[][] {
    const messages = [];
    for (const stage of this.storedItems[index].groups[0].stages) {
      for (const step of stage.steps) {
        if (step.status === 'failed') {
          messages.push({ name: step.name, message: step.message });
        }
      }
    }
    return messages;
  }

  displayError(index: number) {
    // console.log("ingest_panel_" + index);
    // console.log(this.storedItems[index].id);
    // if (this.showDetails.includes(this.storedItems[index].id)) {
    //   for (var i = 0; i < this.showDetails.length; i++) {
    //     if (this.showDetails[i] === this.storedItems[index].id) {
    //       this.showDetails.splice(i, 1);
    //       i--;
    //     }
    //   }
    // }
    // else {
    //   this.showDetails.push(this.storedItems[index].id);
    // }

    this.displayErrorUp(index);

    this.copyErrorToClipboard(index);

  }

  displayErrorUp(index: number) {
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}] \n`;
          i++;
        }
      }
    }

    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id: ${item_url}
Error:${error_message}`;

    this.displayOneUpWithMessage(copy_text, 'Error Details', 50);
  }

  displayOneUpWithMessage(content, header, widthPercent) {
    this.errorMTop=(window.scrollY+50)+"px";
    this.oneUp = content;
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = header;
    options.widthPercentage = widthPercent;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    // window.scrollTo(0, 0);
  }

  onFilterChange(filter: string): void {
    switch (filter) {
      case 'all':
        this.isAllActive = true;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'processing':
        this.isAllActive = false;
        this.isProcessingActive = true;
        this.isCompletedActive = false;
        this.isFailedActive = false;
        break;
      case 'completed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = true;
        this.isFailedActive = false;
        break;
      case 'failed':
        this.isAllActive = false;
        this.isProcessingActive = false;
        this.isCompletedActive = false;
        this.isFailedActive = true;
        break;
    }
    this.filterCompleted = filter;
    this.getEventItems();
  }

  copyErrorToClipboard(index: number) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    let error_message = '';
    const error_object = this.getGroupErrorMessage(index);
    if (error_object !== null) {
      let i = 0;
      for (const e of error_object) {
        if (e !== null) {
          error_message += `[${e['name']}][${e['message']}]\n`;
          i++;
        }
      }
    }
    const item_url = this.getEcrUrl(this.storedItems[index].fields.content_provider_archive_itemId);
    const copy_text = `
Reference Id:${this.storedItems[index].fields.fox_orchestration_id}
Title:${this.storedItems[index].fields.title}
Provider Id:${this.storedItems[index].fields.provider_id}
Story Number:${this.storedItems[index].fields.content_provider_storyNumber}
Item Id:${item_url}
Error:${error_message}`;
    selBox.value = copy_text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }



  getEndTime(index: number): number {
    let endDate = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {

      endDate = new Date(stage.end).getTime();
      break;

    }
    return endDate;
  }

  getStartTime(index: number): number {
    let startDate = 0;
    for (const stage of this.storedItems[index].groups[this.storedItems[index].groups.length - 1].stages) {

      startDate = new Date(stage.start).getTime();
      break;

    }
    return startDate;
  }

  submit(search: string) {
    this.storedSearchQuery = search;
    this.getEventItems();
    this.getEventsForFindCombination();
  }


  //
  // todo: log analynics
  logAnalynics(index: number) {
    try {
      const item = this.storedItems[index];
      this.googleAnalyticsEventsService.emitEvent("archiveorderfulfillment", "archiveorderfulfillment", (environment.envName.toLowerCase() === 'dev' ? 'dev_' : '') + this.getCurrentUser() + " | " + item.fields.User + " | " + item.fields.Path, 1);
      console.log(item.fields.Path);
    } catch (errGA) {
      console.log("error processing Google Analynics: " + errGA.toString());
    }

  }

  getCurrentUser(): string {
    var localuser = '';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      if (currentUser.username) {
        const begin = currentUser.username.toString().indexOf('_');
        const end = currentUser.username.toString().indexOf('@');
        localuser = currentUser.username.toString().slice(begin + 1, end);
      } else
        console.log('user not found');
    }
    return localuser
  }

  getEcrUrl(item_id): String {
    return environment.EcrUrl + item_id;
  }

  sanitizeUrl(url: string) {
    if (!url || url === '') {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  displayOneUp(index: number) {
    this.errorMTop=(window.scrollY+50)+"px";
    this.oneUp = JSON.stringify(this.storedItems[index].rawEvent, null, 2);
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = 'Item Details';
    options.widthPercentage = 75;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    // window.scrollTo(0, 0);
  }

  hideOneUp() {
    this.popup.hide();
  }

  getTime(time: string): number {
    return new Date(time).getTime();
  }

  getTimeToMarket(startTime: string, endTime: string): string {
    let result = '';
    const startDate = this.getTime(startTime)
    const endDate = this.getTime(endTime)
    if (endDate === 0 || startDate === 0) {
      return '';
    }
    const difference: number = endDate - startDate;
    const diffHours = difference / 3600000;
    const absoluteHours = Math.floor(diffHours);
    if (absoluteHours > 0) {
      const hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
      result = hours + ' hrs ';
    }
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    if (absoluteMinutes > 0) {
      const mins = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' + absoluteMinutes.toString();
      result = result + mins + ' mins';
    }
    if (absoluteHours < 1) {
      const diffSeconds = (diffMinutes - absoluteMinutes) * 60;
      const absoluteSeconds = Math.floor(diffSeconds);
      if (absoluteSeconds > 0) {
        const secs = absoluteSeconds > 9 ? absoluteSeconds.toString() : '0' + absoluteSeconds.toString();
        result = result + ' ' + secs + ' secs';
      }
    }
    if (result === '') {
      result = '0 mins 0 secs';
    }
    return result;
  }


  onPagingSelectChange() {
    localStorage.setItem("ArchivePaging", this.paging.toString());
    localStorage.setItem("OpsRegion", this.regions.toString());
    this.getEventItems();
  }


  getEventsForFindCombination(): void {
    // console.log("ingest refresh");
    const url = this.createSearchRequestForFindCombination();
    if (!url || url === '') {
      return;
    }

    this.http.get(url, { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResultsForFindCombination(JSON.stringify(data));

    });
  }

  createSearchRequestForFindCombination(): string {
    // let searchQuery = this.httpGetUri;
    let searchQuery = this.httpGetUri + 'limit=500&query=';
    searchQuery = searchQuery + 'fields.fox_content_class:archive_order_fulfillment';

    searchQuery = searchQuery + ' AND groups.status:completed';

    if(this.regions && this.regions !== 'all')
    {
      searchQuery = searchQuery + ' AND fields.fox_processing_region:' + this.regions;
    }
    // console.log(this.startDate);
    if (this.startDate && this.endDate) {
      searchQuery = searchQuery + '&date_start=' + this.startDate + '&date_end=' + this.endDate;
    }
    console.log(searchQuery);
    return searchQuery;
  }

  processSearchResultsForFindCombination(item: string): void {
    let items = [];
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;


      items.push(event);
    });
    this.findCombination(items);
  }

  findCombination(items) {
    this.sourceOutputCombination = [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]];
    items.forEach(item => {
      if (item.fields.archiveorder_duid_output != "" && item.fields.archiveorder_duid_source != "") {
        let sourceIndex = this.source.indexOf(item.fields.archiveorder_duid_source.trim().toLowerCase());
        let outputIndex = this.output.indexOf(item.fields.archiveorder_duid_output.trim().toLowerCase());
        if (sourceIndex > -1 && outputIndex > -1) {
          this.sourceOutputCombination[sourceIndex][outputIndex]++;
        }
      }
    });
  }


}
