import { Component, Input, OnInit } from '@angular/core';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
  @Input() time: string;

  private days = '00';
  private hours = '00';
  private minutes = '00';
  private passedDate: Date;
  private _subscription: Subscription;
  public age = '';

  constructor() {
  }

  ngOnInit() {
    this.passedDate = new Date(this.time);
    this.GetDifference();
    this._subscription = IntervalObservable.create(60000).subscribe(x => {
      this.GetDifference();
     });
  }

  GetDifference(): void {
    let ageBuilder = '';
    const difference: number = new Date().valueOf() - this.passedDate.valueOf();
    const diffDays = difference / (86400000);
    const absoluteDays = Math.floor(diffDays);
    this.days = absoluteDays > 9 ? absoluteDays.toString() : '0' + absoluteDays.toString();
    const diffHours = (diffDays - absoluteDays) * 24;
    const absoluteHours = Math.floor(diffHours);
    this.hours = absoluteHours > 9 ? absoluteHours.toString() : '0' + absoluteHours.toString();
    const diffMinutes = (diffHours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(diffMinutes);
    this.minutes = absoluteMinutes > 9 ? absoluteMinutes.toString() : '0' +  absoluteMinutes.toString();
    if (absoluteDays > 0) {
      ageBuilder = this.days + ' days ';
    }
    if (absoluteHours > 0) {
      ageBuilder = ageBuilder + this.hours + ' hrs ';
    }
    if (absoluteMinutes > 0 && absoluteDays < 1) {
      ageBuilder = ageBuilder + this.minutes + ' mins';
    }
    this.age = ageBuilder.trim();
  }

}
