import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FoxEvent } from '../foxeventobj/foxevent.model';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../search-result.model';
import { IntervalObservable } from '../../../node_modules/rxjs/observable/IntervalObservable';
import { Subscription } from '../../../node_modules/rxjs/Subscription';
import { PopupComponent } from '../popup/popup.component';
import { PopupOptions } from '../popup/popup-options';
import { LoginService } from '../login/login.service';

import {GoogleAnalyticsEventsService} from "../app.google-analynics-events.service";
import {FoxEventGroup} from "../foxeventobj/foxevent-group";
import { ActiveTabService } from '../service/active-tab.service';
// Declare ga function as ambient
declare let ga: Function;

@Component({
  selector: 'app-youtubeeventlist',
  templateUrl: './youtubeeventlist.component.html',
  styleUrls: ['./youtubeeventlist.component.css']
})
export class YoutubeeventlistComponent implements OnInit, OnDestroy {
  @ViewChild('youtubePopup') popup: PopupComponent;
  @Input() httpGetUri: string;
  public storedItems: FoxEvent[] = [];
  public filterCompleted = 'all';
  public oneUp: string;
  public isAllActive = true;
  public isKilledActive = false;
  public isCompletedActive = false;
  public isFailedActive = false;
  public allCount = 0;
  public completedCount = 0;
  public isKilledCount = 0;
  public failedCount = 0;
  public query = '';
  public completed = '';
  public processing = '';
  public failed = '';
  public all = '';
  public killed = '';
  private storedSearchQuery = '';
  private _subscription: Subscription;
  paging = 50;
  constructor(private activeTabService:ActiveTabService, private http: HttpClient, private service: LoginService, public googleAnalyticsEventsService: GoogleAnalyticsEventsService) {
  }

  ngOnInit() {
    let localStoragePaging = localStorage.getItem("YoutubePaging");
    if (!localStoragePaging || localStoragePaging == undefined) {
      localStorage.setItem("YoutubePaging", this.paging.toString());
    }
    else {
      this.paging = parseInt(localStoragePaging);
    }

    this.activeTabService.activeTab.subscribe(data => {
      if (data == "youtube") {
        this.getEventItems();
      }
    });

    this._subscription = IntervalObservable.create(30000).subscribe(x => {
      if (this.activeTabService.getActiveTab() == "youtube") {
        this.getEventItems();
      }
    });
    
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getEventItems(): void {
    this.http.get(this.createSearchRequest(), { headers: this.service.addAccessToken(null) }).subscribe(data => {
      this.processSearchResults(JSON.stringify(data));
    });
  }
  submit(search: string) {
    this.storedSearchQuery = search;
    this.getEventItems();
  }
  processSearchResults(item: string): void {
    this.storedItems = [];
    this.resetCounts();
    const results: SearchResult = JSON.parse(item);
    results.hits.hits.forEach(element => {
      // has to be a better way to get the already parsed string into a FoxEvent!
      const event: FoxEvent = JSON.parse(JSON.stringify(element._source));
      event.rawEvent = element._source;
      if (event.fields.ytVideoGoLiveDate && event.fields.ytVideoGoLiveDate !== '') {
        event.fields.ytVideoStatus = this.getYTVideoStatus(event.fields.ytVideoGoLiveDate);
      }
      switch (event.groups[0].status) {
        case 'completed':
          this.completedCount += 1;
          break;
        // case 'processing':
        //   this.isKilledCount += 1;
        //   break;
        case 'failed':
          this.failedCount += 1;
          break;
      }
      // Calculate kills by walking the groups of each event and looking or the name set to delete
      for (const group of event.groups) {
        if (this.itemIsKilled(group)){
          this.isKilledCount += 1;
        }
    }
      this.allCount += 1;
      this.storedItems.push(event);
    });
  }

  resetCounts() {
    this.allCount = 0;
    this.completedCount = 0;
    this.isKilledCount = 0;
    this.failedCount = 0;
  }

  createSearchRequest(): string {
    let searchQuery = this.httpGetUri + 'limit=' + this.paging + '&query='+ 'source:youtube';

    if (this.isKilledActive === true) {
      searchQuery = searchQuery + ' AND groups.stages.steps.name:delete';
    } else if (this.isCompletedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:completed';
    } else if (this.isFailedActive === true) {
      searchQuery = searchQuery + ' AND groups.status:failed';
    }
  if (this.storedSearchQuery !== '') {
      searchQuery = searchQuery + ' AND ' + this.storedSearchQuery;
    }
    // console.log(searchQuery);
    // if sort by end:
    searchQuery = searchQuery +  '&sort=groups.stages.end:desc';
    return searchQuery;
  }

  getYTVideoStatus(goLiveDate: string): string {
    if (new Date().getTime() > new Date(goLiveDate).getTime()) {
      return 'public';
    }
    return 'private';
  }

  getGroupErrorMessage(index: number): string {
    for (const stage of this.storedItems[index].groups[0].stages) {
      for (const step of stage.steps) {
        if (step.status === 'failed') {
          return step.name + ' : ' + step.message;
        }
      }
    }
    return '';
  }
// Calculate kills by walking the groups of each event and looking or the name set to delete
  getIsKilled(index: number): boolean {
    for (const gp of this.storedItems[index].groups) {
        if(gp.name === 'youtube') {
          return this.itemIsKilled(gp);
        }
    }
    return false;
  }

  itemIsKilled(gp: FoxEventGroup): boolean {
    var returnValue = false;
    for (const stg of gp.stages) {
      for (const stp of stg.steps) {
        if (stp.name === 'delete') {
          returnValue = true;
          break;
        }
      }
    }
    return returnValue;
  }
  getGoLiveDate(index: number): string {
    if (this.storedItems[index].fields.ytVideoStatus === 'private' && this.storedItems[index].fields.ytVideoGoLiveDate) {
      return 'Public Date: ' + this.storedItems[index].fields.ytVideoGoLiveDate;
    }
    return '';
  }

  onFilterChange(filter: string): void {
      switch (filter) {
        case 'all':
          this.isAllActive = true;
          this.isKilledActive = false;
          this.isCompletedActive = false;
          this.isFailedActive = false;
          break;
        case 'processing':
          this.isAllActive = false;
          this.isKilledActive = true;
          this.isCompletedActive = false;
          this.isFailedActive = false;
          break;
        case 'completed':
          this.isAllActive = false;
          this.isKilledActive = false;
          this.isCompletedActive = true;
          this.isFailedActive = false;
          break;
        case 'failed':
          this.isAllActive = false;
          this.isKilledActive = false;
          this.isCompletedActive = false;
          this.isFailedActive = true;
          break;
      }
      this.filterCompleted = filter;
      this.getEventItems();
  }

  copyGoLiveToClipboard(index: number) {
    if (this.storedItems[index].fields.ytVideoStatus === 'private' && this.storedItems[index].fields.ytVideoGoLiveDate) {
      this.copyToClipboard('Public Date: ' + this.storedItems[index].fields.ytVideoGoLiveDate);
    }
  }

  copyErrorToClipboard(index: number) {
    this.copyToClipboard(this.getGroupErrorMessage(index));
  }

  copyToClipboard(clipboardText: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = clipboardText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  displayOneUp(index: number) {
    this.oneUp = JSON.stringify(this.storedItems[index].rawEvent, null, 2);
    const options: PopupOptions = new PopupOptions();
    options.showButtons = false;
    options.color = '#777';
    options.header = 'Item Details';
    options.widthPercentage = 75;
    options.animation = 'fadeInDown';
    this.popup.show(options);
    window.scrollTo(0, 0);
  }

  hideOneUp() {
    this.popup.hide();
  }

  onPagingSelectChange() {
    localStorage.setItem("YoutubePaging", this.paging.toString());
    this.getEventItems();
  }

}
