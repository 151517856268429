<app-popup #youtubePopup (cancelClick)="hideOneUp()">
    <pre>{{ oneUp }}</pre>
</app-popup>
<div id="divDashboard">
  <div class="row no-gutter">
      <form class="filter-form">
          <div class="media-block">
                <input type="text" class="search-input" #searchquery [value]="query" (keydown.enter)="submit(searchquery.value)"  placeholder="Start searching..."><button (click)="submit(searchquery.value)" class="search-button"><i class="ico-search"></i></button>
         </div> 
         <div class="media-block">
            <select name="paging" class="select-general" [(ngModel)]="paging" (change)="onPagingSelectChange()">
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="250">250</option>
          </select>
        </div>
          <div class="filter-block">
              <label class="radio-inline link" [class.active]="isAllActive">
                  <input type="radio" name="filter2" [value]="all" (change)="onFilterChange('all')" checked="checked">All Results<span *ngIf="allCount > 0"> ({{ allCount }})</span>
              </label>
              <label class="radio-inline link completed" [class.active]="isCompletedActive">
                  <input type="radio" name="filter2" [value]="completed" (change)="onFilterChange('completed')">Completed<span *ngIf="completedCount > 0"> ({{ completedCount }})</span>
              </label>
              <label class="radio-inline link processing" [class.active]="isKilledActive">
                  <input type="radio" name="filter2" [value]="killed" (change)="onFilterChange('processing')">Killed<span *ngIf="isKilledCount > 0"> ({{ isKilledCount }})</span>
              </label>
              <label class="radio-inline link failed" [class.active]="isFailedActive">
                  <input type="radio" name="filter2" [value]="failed" (change)="onFilterChange('failed')">Failed<span *ngIf="failedCount > 0"> ({{ failedCount }})</span>
              </label>
          </div>
      </form>
      <table class="table table-striped">
          <thead>
              <tr>
                  <th style="width: 20%"><span>Story</span></th>
                  <th style="width: 10%"><span>Details</span></th> 
                  <th style="width: 10%"><span>YouTube</span></th>
                  <th style="width: 10%"><span>Age</span></th>
                  <th style="width: 15%"><span>Channel</span></th>
                  <th style="width: 15%"><span>Video Status</span></th>
                  <th style="width: 10%"><span>Video Link</span></th>
                  <th style="width: 10%"><span>&nbsp;</span></th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}"
                  [ngStyle]="{'background-color': getIsKilled(i) ? 'darkred' : 'nth-child(even) {background-color: #f2f2f2;}', 'color': getIsKilled(i) ? 'white' : 'black'}" >
                  <td>
                    <div class="result-info">
                        <span class="id">{{item.fields.title || '--- No Slug Title ---'}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="result-info">
                        <p>{{item.fields.content_provider_storyNumber}} - {{item.fields.applsource}}</p>
                    </div>
                  </td>
                  <td>
                    <div *ngIf="getIsKilled(i)" >
                      <div class="indicator failed" title="{{getGroupErrorMessage(i)}}" (click)="copyErrorToClipboard(i)">
                        <span style="color: white">killed</span>
                      </div>
                    </div>
                    <div *ngIf="getIsKilled(i)===false" >
                      <div class="indicator {{item.groups[item.groups.length-1].status}}" title="{{getGroupErrorMessage(i)}}" (click)="copyErrorToClipboard(i)">
                        <span *ngIf="item.groups[item.groups.length-1].status=='failed'" class="animated pulse" >
                          <span>{{item.groups[item.groups.length-1].status}}</span>
                        </span>
                        <span *ngIf="item.groups[item.groups.length-1].status!='failed'" >
                          <span>{{item.groups[item.groups.length-1].status}}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <app-timer [time]="item.timestamp" ></app-timer>
                  </td>
                  <td>
                    {{item.fields.ytChannelName}}
                  </td>
                  <td>
                    <div title="{{getGoLiveDate(i)}}" (click)="copyGoLiveToClipboard(i)">{{item.fields.ytVideoStatus}}</div>
                  </td>
                  <td>
                    <div *ngIf="item.fields.ytVideoUrl">
                      <a href="{{item.fields.ytVideoUrl}}" target="_blank" class="you-tube-link"></a>
                    </div>
                  </td>
                  <td>
                    <i class="ico-file" (click)="displayOneUp(i)"></i>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>

