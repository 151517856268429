import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ClockComponent } from './clock/clock.component';
import { FoxeventlistComponent } from './foxeventlist/foxeventlist.component';
import { TimerComponent } from './timer/timer.component';
import { ContentTabsetComponent } from './content-tabset/content-tabset.component';
import { UpcomingListComponent } from './upcominglist/upcominglist.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { FormsModule } from '@angular/forms';
import { PopupComponent } from './popup/popup.component';
import { YoutubeeventlistComponent } from './youtubeeventlist/youtubeeventlist.component';
import { OperationslistComponent } from './operationslist/operationslist.component';
import { ContributionslistComponent } from './contributionslist/contributionslist.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ArchiveorderfulfillmentComponent } from './archiveorderfulfillment/archiveorderfulfillment.component';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {GoogleAnalyticsEventsService} from "./app.google-analynics-events.service";
import { DateFormatePipe } from './pipe/date-formate.pipe';

import { NgxEchartsModule } from 'ngx-echarts';
import { ConfigurationComponent } from './configuration/configuration.component';
import { EditConfigurationComponent } from './popup/configuration/edit-configuration/edit-configuration.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { NgToggleModule } from 'ng-toggle-button';
@NgModule({
  declarations: [
    AppComponent,
    ClockComponent,
    FoxeventlistComponent,
    TimerComponent,
    ContentTabsetComponent,
    UpcomingListComponent,
    LoginComponent,
    PopupComponent,
    YoutubeeventlistComponent,
    OperationslistComponent,
    ContributionslistComponent,
    ReportingComponent,
    RestrictionsComponent,
    ArchiveorderfulfillmentComponent,
    DateFormatePipe,
    ConfigurationComponent,
    EditConfigurationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    RouterModule.forRoot([
    { path: 'editorial', component: FoxeventlistComponent },
    { path: 'operations', component: OperationslistComponent },
    { path: 'youtube', component: YoutubeeventlistComponent },
    { path: 'contributions', component: ContributionslistComponent },
    { path: 'ingest', component: ContributionslistComponent },
    { path: 'reporting', component: ReportingComponent },
    { path: 'upcoming', component: UpcomingListComponent },
    { path: 'restrictions', component: RestrictionsComponent },
    { path: 'configuration', component: ConfigurationComponent },
    { path: 'archiveorderfulfillment', component: ArchiveorderfulfillmentComponent },
    { path: '**', redirectTo: '/editorial' }
], {}),
// NgToggleModule
  ],
  providers: [LoginService,GoogleAnalyticsEventsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
