<app-popup #asperaErrorPopup (cancelClick)="hideOneUp()">
  <pre>{{ oneUp }}</pre>
</app-popup>

<div class="media-block" style="padding-bottom: 7px">
  <p>
    Today's Story Count by Provider (Rolling 24 hours UTC)
    <span> &nbsp;&nbsp;&nbsp;&nbsp; <b>Start:</b> <input type="date" id="date_start" name="date_start"
        (input)="startDate = $event.target.value" [value]="startDate" (change)="onDateChangeStart($event.target.value)">
      <b>End:</b> <input type="date" id="date_end" name="date_end" (input)="endDate = $event.target.value"
        [value]="endDate" (change)="onDateChangeEnd($event.target.value)"></span>
  </p>
</div>
<table class="table table-striped">
  <thead>
    <tr>
      <th width="25%"><span>Provider</span></th>
      <th><span>Count</span></th>
      <th style="text-align: left" *ngIf="dataList_aspera && dataList_aspera.length > 0"><span>Aspera Failures</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of dataList; let i = index">
      <td>
        <div class="result-info">
          <p class="id">{{ item.provider || '--- No Slug Title ---' }}</p>
        </div>
      </td>

      <td>
        <div class="result-info">
          <div>{{ item.count}}</div>
        </div>
      </td>

      <td *ngIf="dataList_aspera && dataList_aspera.length > 0">
    <tr *ngFor="let item_fail of dataList_aspera; let iFail = index">

      <div class="result-info">
        <div *ngIf="item.provider == item_fail.provider" style="color: red;align: right"><a href="#"
            (click)="displayOneUp(item_fail.provider)" routerLink="/reporting">{{ item_fail.count}}</a></div>
      </div>
    </tr>
    </td>
    </tr>




  </tbody>
</table>
<table class="table table-striped">
  <thead>
    <tr>
      <th width="25%"><span>Publishing Source</span></th>
      <th><span>Count</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of dataList_class_reporting; let i = index">
      <td>
        <div class="result-info">
          <p class="id">{{ item.class || '--- No Title ---' }}</p>
        </div>
      </td>

      <td>
        <div class="result-info">
          <div>{{ item.count}}</div>
        </div>
      </td>


    </tr>
  </tbody>
</table>

<table class="table table-striped">
  <thead>
    <tr>
      <th width="25%"><span>Ingest Source</span></th>
      <th width="25%"><span>count</span></th>
      <th style="text-align: left;"><span>Duration (minutes)</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of ingestItems; let i = index">
      <td>
        <div class="result-info">
          <p class="id">AP Transfer - {{ getFramerate(item.framerate)}} fps </p>
        </div>
      </td>

      <td>
        <div class="result-info">
          <div>{{ item.count}}</div>
        </div>
      </td>
      <td>
        <div class="result-info">
          <div>{{ getDuration(item.duration)}}</div>
        </div>
      </td>

    </tr>
  </tbody>
</table>
<br />
<!--<span *ngFor="let item of dataList; let i = index">-->
<!--<div class="result-info download">-->
<!--{{item}}-->
<!--</div>-->
<!--</span>-->