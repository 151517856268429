<app-popup #archiveorderfulfillmentPopup (cancelClick)="hideOneUp()"  [ngStyle]="{'margin-top': errorMTop}" >
  <pre  class="preStyle">{{ oneUp }}</pre>
</app-popup><div id="divDashboard">
  <div class="row no-gutter">
    <table class="table table-striped">
      <thead>
          <tr>
            <th style="width: 10%"><span>&nbsp;</span></th>
              <th *ngFor="let item of output; let i = index" style="width: 10%"><span>{{item}}</span></th>


          </tr>
      </thead>
      <tbody>


          <tr *ngFor="let item of sourceOutputCombination; let i = index">
            <td >
              <div class="media-block">
                <b>{{source[i]}}</b>
              </div>
          </td>
            <!-- oneup -->
            <td *ngFor="let data of item; let a = index">
                <div *ngIf="data!=0" class="media-block">
                  {{data}}
                </div>
            </td>
          </tr>
      </tbody>
  </table>
  </div>
  <br>
  <br>
  <div class="row no-gutter">
      <form class="filter-form">
          <div class="media-block">
                  <input type="text" class="search-input" #aofsearchquery [value]="query" (keydown.enter)="submit(aofsearchquery.value)" placeholder="Start searching..." style="margin-left: -1.3rem"><button (click)="submit(aofsearchquery.value)" class="search-button"><i class="ico-search"></i></button>
          </div>
          <div class="media-block">
                  <span>Ordered: <input type="date" id="date_start" name="date_start" (input)="startDate = $event.target.value" [value]="startDate" (change)="submit(aofsearchquery.value)"> Completed: <input type="date" id="date_end" name="date_end" (input)="endDate = $event.target.value"  [value]="endDate" (change)="submit(aofsearchquery.value)"></span>
                </div>

          <div class="form-group mb-0 ml-10px">
            <select name="regions" class="form-control" [(ngModel)]="regions" (change)="onPagingSelectChange()">
              <option value="all">all</option>
              <option value="us-east-1">us-east-1</option>
              <option value="us-west-2">us-west-2</option>
            </select>
          </div>
         <div class="media-block">
              <select name="paging" class="select-general" [(ngModel)]="paging" (change)="onPagingSelectChange()">
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
                <option value="250">250</option>
            </select>
          </div>
          <div class="filter-block">
              <label class="radio-inline link" [class.active]="isAllActive">
                  <input type="radio" name="filter1" [value]="all" (change)="onFilterChange('all')" checked="checked">All Results<span *ngIf="allCount > 0"> ({{ allCount }})</span>
              </label>
              <label class="radio-inline link completed" [class.active]="isCompletedActive">
                  <input type="radio" name="filter1" [value]="completed" (change)="onFilterChange('completed')">Completed<span *ngIf="completedCount > 0"> ({{ completedCount }})</span>
              </label>
              <label class="radio-inline link processing" [class.active]="isProcessingActive">
                  <input type="radio" name="filter1" [value]="processing" (change)="onFilterChange('processing')">Processing<span *ngIf="inProgressCount > 0"> ({{ inProgressCount }})</span>
              </label>
              <label class="radio-inline link failed" [class.active]="isFailedActive">
                  <input type="radio" name="filter1" [value]="failed" (change)="onFilterChange('failed')">Failed<span *ngIf="failedCount > 0"> ({{ failedCount }})</span>
              </label>
          </div>
      </form>

      <table class="table table-striped">
          <thead>
              <tr>
                  <th style="width: 3%"><span>&nbsp;</span></th>
                  <th style="width: 15%"><span>OrderId</span></th>
                  <th style="width: 15%" ><p><span>Story Slug</span></p></th>
                  <th style="width: 15%;" ><span>Customer</span></th>
                  <th style="width: 30%; text-align: center" ><span>Details</span></th>

                <th colspan="2"><span>&nbsp;</span></th>

              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of storedItems; let i = index" class="tr_{{item.id}}">
                <!-- oneup -->
                <td>
                    <div class="media-block">
<!--                        <span class="indicator {{item.groups[0].status}}" title="{{getGroupErrorMessage(i)}}"-->
<!--                              (click)="copyErrorToClipboard(i)"></span>-->
                      <i class="ico-file" (click)="displayOneUp(i)"></i>
                    </div>
                </td>

                <!-- orderid -->
                  <td align="center">
                      <div class="result-info">
                          <p *ngIf="item.fields.archiveorder_orderId"><b>{{item.fields.archiveorder_orderId }}</b></p>
                      </div>
                  </td>
                <!-- Story Slug -->
                  <td>
                      <div class="result-info">
                          <p *ngIf="item.fields.title">{{item.fields.title }}</p>
                      </div>
                  </td>

                <!-- customer -->
                  <td >
                       <div class="result-info">
                          <p *ngIf="item.fields.archiveorder_customer">{{item.fields.archiveorder_customer }}</p>
                         <p *ngIf="item.fields.archiveorder_orgName"><span>[{{item.fields.archiveorder_orgName }}]</span></p>

                         <p *ngIf="item.fields.archiveorder_orgId"><span>(OrgId: {{item.fields.archiveorder_orgId }})</span></p>
                      </div>
                </td>

                <!-- request details -->
                 <td >
                   <p *ngIf="item.fields.content_provider_storyNumber">
                    <b> Story Number:</b>
                     {{item.fields.content_provider_storyNumber }}
                   </p>
                   <p *ngIf="item.fields.archiveorder_itemId">
                   <b>  Archive:</b> <a href="{{ getEcrUrl(item.fields.archiveorder_itemId) }}" target="_new">{{item.fields.archiveorder_itemId}}</a>
                   </p>
                       <div class="result-info">
                          <p *ngIf="item.fields.archiveorder_duid_source"><b>Source Duid:</b> {{item.fields.archiveorder_duid_source }}</p>
                      </div>
                   <div class="result-info">
                     <p *ngIf="item.fields.archiveorder_duid_output"><b>Output Duid:</b> {{item.fields.archiveorder_duid_output }}</p>

                   </div>
                  <div *ngIf="item.fields.subclip_in" class="result-info">
                          <p *ngIf="item.fields.subclip_in != ''"><b>Sub Clip In:</b> {{item.fields.subclip_in}}</p>
                      </div>
                   <div *ngIf="item.fields.subclip_out" class="result-info">
                     <p *ngIf="item.fields.subclip_out != ''" ><b>Sub Clip Out:</b> {{item.fields.subclip_out}}</p>
                   </div>
                   <p *ngIf="item.fields.fox_processing_region">
                    <span><b>AWS Region: </b></span>{{item.fields.fox_processing_region}}
                  </p>
                </td>
                <!-- output -->
                  <td >

                </td>

                <!-- processing details -->
                <td>
                    <div *ngIf="item.groups[0].status !== 'failed'">
                      <div *ngFor="let stg of item.groups[0].stages">
                        <span *ngIf="stg.start && stg.end && stg.status == 'completed'"><b>Total Time:  {{ getTimeToMarket(stg.start, stg.end) }} </b><br /></span>
                        <span>Ordered:&ensp;&ensp;</span>{{stg.start | dateFormate}} <br/><span *ngIf="stg.start && stg.end">Completed: </span><span  *ngIf="stg.start && stg.end && stg.status == 'completed'">{{stg.end | dateFormate}}<p *ngIf="item.fields.archiveorder_output_url != 'notset' && item.groups[0].status == 'completed'">
                        <a [href]="sanitizeUrl(item.fields.archiveorder_output_url)"target="_new" routerLink="/archiveorderfulfillment">download</a></p></span>
                            <div *ngFor="let step of stg.steps; let istep = index; let iCount = count">
                              <span *ngIf="step.status != 'completed'" ><span class="indicator {{stg.status}}"></span><span class="animated pulse" > {{step.name}}</span></span>
                            </div>
                      </div>
                     </div>
                        <div *ngIf="item.groups[0].status === 'failed'">
                          <span>Ordered:&ensp;&ensp;</span>{{item.groups[0].stages[0].start}} <br/><span *ngIf="item.groups[0].stages[0].end">Completed: </span><span  *ngIf="item.groups[0].stages[0].end">{{item.groups[0].stages[0].end}}</span>
                            <div *ngFor="let step of getGroupErrorMessage(i)">
                              <span><b>Step:</b> </span>{{step['name']}}
<!--                              <span><b>Details:</b> </span>{{step['message']}}-->
<!--                              <span *ngIf="step.message"><br/><b>Details:</b> </span>-->
                              <button *ngIf="step['message'] && step['message'].length > 65" class="ingest_accordion" style="color:red;cursor: pointer" (click)="displayError(i)">{{ (step['message'].length>65)? (step['message'] | slice:0:65)+'..':(step['message']) }}</button>
                              <div *ngIf="step['message'] && (showDetails.includes(item.id) || step['message'].length<65)" class="ingest_panel" id="ingest_panel_{{i}}" >

                                {{(step["message"].length>350)? (step["message"] | slice:0:350)+'...':step["message"]}}
                              </div>
                            </div>
                        </div>

                </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
